import * as React from 'react';
import { GlobalState } from '../globalState';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CardDates } from '../entities/cardDates';

interface ILabelledDatePickerProps {
    globalState: GlobalState;
    label: string;
    value?: Date;
    onChange?: (text?: Date) => void;
    className?: string;
}

export class LabelledDatePicker extends React.Component<ILabelledDatePickerProps, { value?: Date }> {
    private _ref: React.RefObject<HTMLInputElement>;
    constructor(props: ILabelledDatePickerProps) {
        super(props);

        this.state = { value: this.props.value };
        this._ref = React.createRef();
    }

    reset() {
        this.setState({ value: undefined });
        this._ref.current!.value = '';

        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    formatDate(date: Date) {
        if (!(date instanceof Date && !isNaN(date.getTime()))) {
            return '';
        }
        const year =  String(date.getFullYear()).padStart(4, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    componentDidUpdate(): void {
        if (this.props.value === undefined) {
            this._ref.current!.value = '';
        }
    }

    render() {
        return (
            <div className={'date-picker-element ' + this.props.className}>
                <div className="date-picker-element-label">{this.props.label}</div>
                <input
                    type="date"
                    ref={this._ref}
                    className="date-picker"
                    defaultValue={this.props.value ? this.formatDate(this.props.value) : ''}
                    onChange={(evt) => {
                        this.setState({ value: CardDates.StringToDate(evt.target.value) });
                        if (this.props.onChange) {
                            this.props.onChange(CardDates.StringToDate(evt.target.value));
                        }
                    }}
                />
                <div className="date-picker-element-clear" onClick={() => this.reset()}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </div>
        );
    }
}
