import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Card } from '../entities/card';
import { CardCondition } from '../entities/cardCondition';
import { CardGrading } from '../entities/cardGrading';
import { CardLanguage } from '../entities/cardLanguage';
import { Collection } from '../entities/collection';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { CardDates } from '../entities/cardDates';
interface ICardDataDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class CardDataDialog extends React.Component<
    ICardDataDialogProps,
    { isVisible: boolean; card?: Card, count: number }
    > {
    private _cardConditions: number[];
    private _cardLanguages: number[];
    private _cardGrading: number[];
    private _cardDates: string[];
    private _conditionOptions = CardCondition.CardConditions.map((c) => c.name);
    private _conditionValues = CardCondition.CardConditions.map((c) => c.index);
    private _languageOptions = CardLanguage.CardLanguages.map((c) => c);
    private _languageValues = CardLanguage.CardLanguages.map((c, i) => i);
    private _gradingOptions = CardGrading.CardGradings.map((c) => c);
    private _gradingValues = CardGrading.CardGradings.map((c, i) => i);


    constructor(props: ICardDataDialogProps) {
        super(props);

        this.state = { isVisible: false, count: 0 };
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onCardDataRequired.add((value) => {

            this._cardConditions = CardCondition.Decoder(value.condition);
            this._cardLanguages = CardLanguage.Decoder(value.languages);
            this._cardGrading = CardGrading.Decoder(value.grading);
            this._cardDates = value.dates.map(d => this.formatDate(d));

            this.setState({ isVisible: true, card: value, count: value.count });

            this.props.globalState.onBlurRequired.notifyObservers(true);
        });

        document.addEventListener('keydown', (evt) => {
            if (evt.keyCode === 27 && this.state.isVisible) {
                this.onConfirmation(false);
            }
        });
    }

    onConfirmation(value: boolean) {
        const card = this.state.card;
        if (value && card) {
            while (this.state.count !== card.count) {
                card.count = card.count - 1;
            }

            card.condition = CardCondition.Encoder(this._cardConditions);
            card.grading = CardGrading.Encoder(this._cardGrading);
            card.languages = CardLanguage.Encoder(this._cardLanguages);
            card.dates = CardDates.ToDates(this._cardDates);
            Collection.RegisterSave();
        }

        this.props.globalState.onCardDataUpdated.notifyObservers();

        this.props.globalState.onBlurRequired.notifyObservers(false);
        this.setState({ isVisible: false });
    }

    formatDate(date: Date) {
        if (!(date instanceof Date && !isNaN(date.getTime()))) {
            return '';
        }
        const year =  String(date.getFullYear()).padStart(4, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    renderOneLine(index: number, title: string) {
        return (
            <div className='card-data-dialog-line' key={'item-index' + index}>
                <div className='card-data-dialog-line-id'>
                    {title}
                </div>
                <div className='card-data-dialog-line-language'>
                    <select
                        tabIndex={1}
                        className="query-dialog-list"
                        value={this._cardLanguages[index]}
                        onChange={(evt) => {
                            this._cardLanguages[index] = parseInt(evt.target.value);
                            this.forceUpdate();
                        }}
                    >
                        {this._languageOptions.map((o, i) => {
                            return (
                                <option key={i} value={this._languageValues[i]}>
                                    {o}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className='card-data-dialog-line-condition'>
                    <select
                        tabIndex={1}
                        className="query-dialog-list"
                        value={this._cardConditions[index]}
                        onChange={(evt) => {
                            this._cardConditions[index] = parseInt(evt.target.value);
                            this.forceUpdate();
                        }}
                    >
                        {this._conditionOptions.map((o, i) => {
                            return (
                                <option key={i} value={this._conditionValues[i]}>
                                    {o}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className='card-data-dialog-line-grading'>
                    <select
                        tabIndex={1}
                        className="query-dialog-list"
                        value={this._cardGrading[index]}
                        onChange={(evt) => {
                            this._cardGrading[index] = parseInt(evt.target.value);
                            this.forceUpdate();
                        }}
                    >
                        {this._gradingOptions.map((o, i) => {
                            return (
                                <option key={i} value={this._gradingValues[i]}>
                                    {o}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className='card-data-dialog-line-added'>
                    <input
                        type="date"
                        tabIndex={1}
                        id={`date${index}`}
                        key={`date${index}`}
                        className="query-dialog-list"
                        defaultValue={this._cardDates[index]}
                        onChange={(evt) => {
                            this._cardDates[index] = evt.target.value;
                        }}
                    />
                </div>
                <div className='card-data-dialog-line-delete'>
                    <Button
                        className={'dialog-button delete' + (this.props.darkMode ? '' : ' light')}
                        globalState={this.props.globalState}
                        onClicked={() => {
                            this._cardLanguages.splice(index, 1);
                            this._cardConditions.splice(index, 1);
                            this._cardGrading.splice(index, 1);
                            this._cardDates.splice(index, 1);
                            this.setState({ count: this.state.count - 1 });
                        }}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const card = this.state.card;
        if (!this.state.isVisible || !card) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        const maxHeight = Math.min(140 + (this.state.count + 1) * 40, 500);
        const lines: JSX.Element[] = [];

        for (let index = 0; index < this.state.count; index++) {
            lines.push(this.renderOneLine(index, '#' + index));
        }

        return (
            <div className="card-data-dialog-container">
                <div className="card-data-dialog-background"></div>
                <div className={'card-data-dialog' + (this.props.darkMode ? ' dark' : '')} style={{
                    height: maxHeight + 'px',
                    top: `calc(50% - ${maxHeight / 2}px)`
                }}>
                    <div className="card-data-dialog-title">{translate('Characteristics')}</div>
                    <div className="card-data-dialog-data" style={{
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        alignContent: 'start',
                        marginTop: '5px'
                    }}>
                        <div className='card-data-dialog-line' style={{ marginBottom: '10px'}}>
                            <div className='card-data-dialog-line-id'>
                                {translate('CardID')}
                            </div>
                            <div className='card-data-dialog-line-language' style={{ justifyContent: 'center'}}>
                                {translate('Language')}
                            </div>
                            <div className='card-data-dialog-line-condition' style={{ justifyContent: 'center'}}>
                                {translate('Condition')}
                            </div>
                            <div className='card-data-dialog-line-grading' style={{ justifyContent: 'center'}}>
                                {translate('Grading')}
                            </div>
                            <div className='card-data-dialog-line-added' style={{ justifyContent: 'center'}}>
                                {translate('Added')}
                            </div>
                        </div>
                        {
                            lines
                        }
                    </div>
                    <div className="card-data-dialog-accept">
                        <Button
                            tabIndex={2}
                            className="card-data-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="card-data-dialog-cancel">
                        <Button
                            tabIndex={3}
                            className="card-data-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
