import { Card } from './card';
import { Collection } from './collection';
import { Set } from './set';
import { Nullable } from '../tools/nullable';
import { CardSize } from './cardSize';
import { Ruling } from './ruling';
import { ICard } from './ICard';
import { GlobalState } from '../globalState';

export class DeckCard implements ICard {
    public parentCard: Card;
    public entryCount: number;
    public isDeckCommander: boolean;
    public isDeckProxy: boolean;

    public get wasAlreadyLoadedOnce() {
        return this.parentCard.wasAlreadyLoadedOnce;
    }

    public set wasAlreadyLoadedOnce(value: boolean) {
        this.parentCard.wasAlreadyLoadedOnce = value;
    }

    public get set(): Set {
        return this.parentCard.set;
    }

    public get setName() {
        return this.set.name;
    }

    public get rarity() {
        return this.parentCard.rarity;
    }

    public get color() {
        return this.parentCard.color;
    }

    public get id(): number {
        return this.parentCard.id;
    }

    public get number(): number {
        return this.parentCard.number;
    }

    public get cropUrl(): string {
        return this.parentCard.cropUrl;
    }

    public get tcgPlayerId(): number {
        return this.parentCard.tcgPlayerId;
    }

    public get cardMarketId(): number {
        return this.parentCard.cardMarketId;
    }

    public get scryfallId(): string {
        return this.parentCard.scryfallId;
    }

    public get canTransform(): boolean {
        return this.parentCard.canTransform;
    }

    public get numberComplement(): string {
        return this.parentCard.numberComplement;
    }
    public get nameEn(): string {
        return this.parentCard.nameEn;
    }
    public get shortNameEn(): string {
        return this.parentCard.shortNameEn;
    }
    public get nameFr(): string {
        return this.parentCard.nameFr;
    }
    public get author(): string {
        return this.parentCard.author;
    }
    public get colorId(): number {
        return this.parentCard.colorId;
    }
    public get rarityId(): number {
        return this.parentCard.rarityId;
    }
    public get isBackFace(): boolean {
        return this.parentCard.isBackFace;
    }
    public get force(): Nullable<number> {
        return this.parentCard.force;
    }
    public get defense(): Nullable<number> {
        return this.parentCard.defense;
    }
    public get power(): string {
        return this.parentCard.power;
    }
    public get picturePath(): string {
        return this.parentCard.picturePath;
    }
    public get textEn(): string {
        return this.parentCard.textEn;
    }
    public get textFr(): string {
        return this.parentCard.textFr;
    }
    public get flavorEn(): string {
        return this.parentCard.flavorEn;
    }
    public get flavorFr(): string {
        return this.parentCard.flavorFr;
    }
    public get typeEn(): string {
        return this.parentCard.typeEn;
    }
    public get typeFr(): string {
        return this.parentCard.typeFr;
    }
    public get multiverseIdEn(): number {
        return this.parentCard.multiverseIdEn;
    }
    public get multiverseIdFr(): number {
        return this.parentCard.multiverseIdFr;
    }
    public get manaCost(): string {
        return this.parentCard.manaCost;
    }
    public get convertedManaCost(): number {
        return this.parentCard.convertedManaCost;
    }
    public get tag(): string {
        return this.parentCard.tag;
    }
    public get size(): CardSize {
        return this.parentCard.size;
    }
    public get rulings(): Ruling[] {
        return this.parentCard.rulings;
    }

    public get isStorySpotlight() {
        return this.parentCard.isStorySpotlight;
    }

    public getDeckString(state: GlobalState): string {
        return this.parentCard.getDeckString(state);
    }

    public get forceStdQuality(): boolean {
        return this.parentCard.forceStdQuality;
    }

    public set forceStdQuality(value: boolean) {
        this.parentCard.forceStdQuality = value;
    }

    public isSelected = false;

    public get otherCard(): Card {
        return this.parentCard.otherCard;
    }

    public get otherCards(): Card[] {
        return this.parentCard.otherCards;
    }

    public get multiverseId() {
        return this.parentCard.multiverseId;
    }

    public get name() {
        return this.parentCard.name;
    }

    public get type() {
        return this.parentCard.type;
    }

    public get text() {
        return this.parentCard.text;
    }

    public get flavor() {
        return this.parentCard.flavor;
    }

    public get nameForSearch() {
        return this.parentCard.nameForSearch;
    }

    public get typeForSearch() {
        return this.parentCard.typeForSearch;
    }

    public get textForSearch() {
        return this.parentCard.textForSearch;
    }

    public get flavorForSearch() {
        return this.parentCard.flavorForSearch;
    }

    public get isCreature(): boolean {
        return this.parentCard.isCreature;
    }

    public get isLegendary(): boolean {
        return this.parentCard.isLegendary;
    }

    public get isCommander(): boolean {
        return this.parentCard.isCommander;
    }

    public get isArtifact(): boolean {
        return this.parentCard.isArtifact;
    }

    public get isEldrazi(): boolean {
        return this.parentCard.isEldrazi;
    }

    public get isToken(): boolean {
        return this.parentCard.isToken;
    }

    public get isDungeon(): boolean {
        return this.parentCard.isDungeon;
    }

    public get isEmblem(): boolean {
        return this.parentCard.isEmblem;
    }

    public get isInstant(): boolean {
        return this.parentCard.isInstant;
    }

    public get isEnchantment(): boolean {
        return this.parentCard.isEnchantment;
    }

    public get isSorcery(): boolean {
        return this.parentCard.isSorcery;
    }

    public get isSpell(): boolean {
        return this.parentCard.isSpell;
    }

    public get isPlaneswalker(): boolean {
        return this.parentCard.isPlaneswalker;
    }

    public get isPhenomenon(): boolean {
        return this.parentCard.isPhenomenon;
    }

    public get isConspiracy(): boolean {
        return this.parentCard.isConspiracy;
    }

    public get isPlane(): boolean {
        return this.parentCard.isPlane;
    }

    public get isScheme(): boolean {
        return this.parentCard.isScheme;
    }

    public get isLand(): boolean {
        return this.parentCard.isLand;
    }

    public get isPermanent(): boolean {
        return this.parentCard.isPermanent;
    }

    public get isOrdered(): boolean {
        return this.parentCard.isOrdered;
    }

    public set isOrdered(value: boolean) {
        this.parentCard.isOrdered = value;
    }

    public get isProxy(): boolean {
        return this.parentCard.isProxy;
    }

    public set isProxy(value: boolean) {
        this.parentCard.isProxy = value;
    }

    public get icon(): number {
        return this.parentCard.icon;
    }

    public set icon(value: number) {
        this.parentCard.icon = value;
    }

    public get count(): number {
        return this.parentCard.count;
    }

    public set count(value: number) {
        this.parentCard.count = value;
    }

    public get foilCount(): number {
        return this.parentCard.foilCount;
    }

    public set foilCount(value: number) {
        this.parentCard.foilCount = value;
    }

    public get specialFoilCount(): number {
        return this.parentCard.specialFoilCount;
    }

    public set specialFoilCount(value: number) {
        this.parentCard.specialFoilCount = value;
    }

    public get price(): number {
        return this.parentCard.price;
    }

    public get foilPrice(): number {
        return this.parentCard.foilPrice;
    }

    public get collectionValue(): number {
        return this.parentCard.collectionValue;
    }

    public get reprints() {
        return this.parentCard.reprints;
    }

    public get totalEntryCount() {
        return this.parentCard.totalEntryCount;
    }

    public get condition() {
        return this.parentCard.condition;
    }

    public get grading() {
        return this.parentCard.grading;
    }

    public get languages() {
        return this.parentCard.languages;
    }

    public get canBeFoil() {
        return this.parentCard.canBeFoil;
    }

    public get canBeStandard() {
        return this.parentCard.canBeStandard;
    }

    public get canBeSpecialFoil() {
        return this.parentCard.canBeSpecialFoil;
    }

    public get isPromo() {
        return this.parentCard.isPromo;
    }

    public get scryfallNumber() {
        return this.parentCard.scryfallNumber;
    }

    public get comments() {
        return this.parentCard.comments;
    }

    public get tags() {
        return this.parentCard.tags;
    }

    public set tags(value: string[]) {
        this.parentCard.tags = value;
    }

    public updateTagsInStorage() {
        this.parentCard.updateTagsInStorage();
    }

    public get toTrade() {
        return this.parentCard.toTrade;
    }

    public get wanted() {
        return this.parentCard.wanted;
    }

    public get validForList() {
        return this.parentCard.validForList;
    }

    public constructor(cardId: number, count: number, isDeckCommander: boolean, isDeckProxy: boolean) {
        this.parentCard = Collection.CardsIndex[cardId];

        this.entryCount = count;
        this.isDeckCommander = isDeckCommander;
        this.isDeckProxy = isDeckProxy;
    }

    public prepareCardNameForTCGPlayer(replaceSpace: boolean): string {
        return this.parentCard.prepareCardNameForTCGPlayer(replaceSpace);
    }
}
