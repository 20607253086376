import { GlobalState } from '../globalState';

export class CardCondition {
    public static CardConditions: CardCondition[] = [];
    public static ConditionKeys: string[] = [];
    public static DecodingOffset = 3;
    public static DecodingMask = 7;

    public constructor(public name: string, public index: number) {}

    public static CreateList(gs: GlobalState) {
        const translate = gs.translate.bind(gs);

        CardCondition.ConditionKeys.push('Undefined');
        CardCondition.ConditionKeys.push('Mint');
        CardCondition.ConditionKeys.push('Near mint');
        CardCondition.ConditionKeys.push('Excellent');
        CardCondition.ConditionKeys.push('Good');
        CardCondition.ConditionKeys.push('LightPlayed');
        CardCondition.ConditionKeys.push('Played');
        CardCondition.ConditionKeys.push('Poor');

        CardCondition.CardConditions.push(new CardCondition(translate('Undefined'), 0));
        CardCondition.CardConditions.push(new CardCondition(translate('Mint'), 1));
        CardCondition.CardConditions.push(new CardCondition(translate('Near mint'), 2));
        CardCondition.CardConditions.push(new CardCondition(translate('Excellent'), 6));
        CardCondition.CardConditions.push(new CardCondition(translate('Good'), 3));
        CardCondition.CardConditions.push(new CardCondition(translate('LightPlayed'), 7));
        CardCondition.CardConditions.push(new CardCondition(translate('Played'), 4));
        CardCondition.CardConditions.push(new CardCondition(translate('Poor'), 5));
    }

    public static GetConditionByIndex(index: number) {
        if (!index) {
            return CardCondition.CardConditions[0];
        }
        return CardCondition.CardConditions.filter((c) => c.index === index)[0];
    }

    public static Decode(encodedConditions: number, limitTo: number): string {
        const conditions = [];
        while (encodedConditions) {
            const index = encodedConditions & CardCondition.DecodingMask;
            if (index !== 0) {
                conditions.push(CardCondition.GetConditionByIndex(index));
            }

            encodedConditions = encodedConditions >> CardCondition.DecodingOffset;
            limitTo--;

            if (limitTo === 0) {
                break;
            }
        }

        const final = [];

        for (const condition of conditions) {
            final.push(`${1}x${condition.name}`);
        }

        return final.join(', ');
    }

    public static Deserialize(storedValue: string) {
        const indexes = this.Decoder(storedValue);

        return this.Encoder(indexes);
    }

    public static Decoder(encodedConditions: string): number[] {
        const conditions: number[] = [];

        if (encodedConditions === '') {
            return conditions;
        }

        const parts = encodedConditions.split(',');

        for (const part of parts) {
            const splits = part.split('x');
            const count = parseInt(splits[0]);
            const condition = part.replace(splits[0] + 'x', '');

            const conditionCandidates = CardCondition.CardConditions.filter((c) => c.name === condition);
            let conditionIndex: number;
            if (conditionCandidates.length === 0) {
                conditionIndex = CardCondition.ConditionKeys.indexOf(condition);
                if (conditionIndex === -1) {
                    return [];
                }
            } else {
                conditionIndex = conditionCandidates[0].index;
            }
            for (let index = 0; index < count; index++) {
                conditions.push(conditionIndex);
            }
        }
        return conditions;
    }

    public static Encoder(conditions: number[]) {
        const final = [];

        if (conditions.length > 1) {
            const first = conditions[0];
            if (conditions.every((x) => x === first)) {
                return `${conditions.length}x${CardCondition.GetConditionByIndex(first).name}`;
            }
        }

        for (const condition of conditions) {
            final.push(`${1}x${CardCondition.GetConditionByIndex(condition).name}`);
        }
        return final.join(', ');
    }
}
