export class StringHelpers {
    public static Distance(a: string, b: string) {
        const v0 = [];
        const v1 = [];

        if (a === b) {
            return 0;
        }

        if (!a.length || !b.length) {
            return Math.max(a.length, b.length);
        }

        for (let i = 0; i < b.length + 1; i++) {
            v0[i] = i;
        }

        for (let i = 0; i < a.length; i++) {
            v1[0] = i + 1;

            for (let j = 0; j < b.length; j++) {
                const cost = Number(a[i] !== b[j]);
                // Cost for the substring is the minimum of adding one character, removing
                // one character, or a swap.
                v1[j + 1] = Math.min(v1[j] + 1, v0[j + 1] + 1, v0[j] + cost);
            }

            for (let j = 0; j < v0.length; j++) {
                v0[j] = v1[j];
            }
        }

        return v1[b.length];
    }

    public static AlmostEquals(target: string, source: string) {
        const min = Math.min(target.length, source.length);
        const minDistance = (min * 0.2) | 0;
        const distance = this.Distance(target, source);

        return distance <= minDistance;
    }

    public static EndsWith(value: string, end: string) {
        const endLength = end.length;
        return value.substr(value.length - endLength, endLength) === end;
    }

    public static StartsWith(value: string, start: string) {
        const startLength = start.length;
        return value.substr(0, startLength) === start;
    }

    public static ReplaceAll(target: string, search: string, replacement: string) {
        return target.replace(new RegExp(search, 'g'), replacement);
    }

    public static ReplaceAllIgnoreCase(target: string, search: string, replacement: string): string {
        return target.replace(new RegExp(search, 'gi'), replacement);
    }

    public static NoAccentVersion(value: string) {
        return value.normalize('NFD').replace(/\p{Diacritic}/gu, '');
    }

    public static RemoveAccents(accentedStr: string): string {
        let returnValue = accentedStr;

        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'À', 'A');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Á', 'A');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Â', 'A');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Ã', 'A');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Ä', 'A');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Å', 'A');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Æ', 'A');

        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Ç', 'C');

        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'È', 'E');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'É', 'E');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Ê', 'E');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Ë', 'E');

        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Ì', 'I');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Í', 'I');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Î', 'I');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Ï', 'I');

        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Ñ', 'N');

        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Ò', 'O');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Ó', 'O');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Ô', 'O');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Õ', 'O');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Ö', 'O');

        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Ù', 'U');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Ú', 'U');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Û', 'U');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Ü', 'U');

        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Ý', 'Y');

        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Æ', 'AE');
        returnValue = this.ReplaceAllIgnoreCase(returnValue, 'Œ', 'OE');

        return returnValue;
    }
}
