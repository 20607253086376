import * as React from 'react';
import { GlobalState } from '../globalState';
import { Button } from './button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faRandom } from '@fortawesome/free-solid-svg-icons';
import { CustomSortDefinition } from '../tools/sortManager';
import { SecureJSON } from '../tools/secureJSON';

require('../scss/controls/customSortDialog.scss');

interface ICustomSortDialogProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class CustomSortDialog extends React.Component<
    ICustomSortDialogProps,
    { isVisible: boolean; sorts: CustomSortDefinition[] }
> {
    constructor(props: ICustomSortDialogProps) {
        super(props);

        this.state = { isVisible: false, sorts: this.getSorts() };
    }

    getSorts() {
        let sorts: CustomSortDefinition[] = [];
        if (GlobalState.DoesSettingsExist('CustomSort')) {
            const result = SecureJSON.Parse<CustomSortDefinition[]>(GlobalState.LoadSettings('CustomSort'));
            if (result) {
                sorts = result;
                if (sorts.length < 12) {
                    sorts.push({
                        sortIndex: 11,
                        asc: true
                    });
                }
            }
        }

        if (sorts.length === 0) {
            for (let index = 0; index < 12; index++) {
                sorts.push({
                    sortIndex: index,
                    asc: true
                });
            }
        }

        return sorts;
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onCustomSortDialogRequired.add(() => {
            this.setState({ isVisible: true, sorts: this.getSorts() });
            this.props.globalState.onBlurRequired.notifyObservers(true);
        });
    }

    onConfirmation(value: boolean) {
        if (value) {
            this.props.globalState.storeSettings('CustomSort', JSON.stringify(this.state.sorts));
        }

        this.setState({ isVisible: false });

        this.props.globalState.onBlurRequired.notifyObservers(false);
    }

    up(i: number) {
        if (i === 0) {
            return;
        }

        const sorts = this.state.sorts;
        const sort = sorts[i];
        const previous = sorts[i - 1];
        sorts[i] = previous;
        sorts[i - 1] = sort;

        this.setState({ sorts: sorts });
    }

    down(i: number) {
        const sorts = this.state.sorts;
        if (i === sorts.length - 1) {
            return;
        }

        const sort = sorts[i];
        const next = sorts[i + 1];
        sorts[i] = next;
        sorts[i + 1] = sort;

        this.setState({ sorts: sorts });
    }

    switch(i: number) {
        const sorts = this.state.sorts;

        sorts[i].asc = !sorts[i].asc;

        this.forceUpdate();
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        const sortList = [
            translate('ByName'),
            translate('ByNumber'),
            translate('ByManaCost'),
            translate('ByColor'),
            translate('ByType'),
            translate('BySuperType'),
            translate('ByPrice'),
            translate('ByEdition'),
            translate('ByYear'),
            translate('ByCollectionValue'),
            translate('ByCollectionCount'),
            translate('ByRarity')
        ];

        const asc = translate('Asc');
        const desc = translate('Desc');

        return (
            <div className="custom-filter-dialog-container">
                <div className="custom-filter-dialog-background"></div>
                <div className={'custom-filter-dialog' + (this.props.darkMode ? ' dark' : '')}>
                    <div className="custom-filter-dialog-title">{translate('CustomFilters')}</div>
                    <div className="custom-filter-dialog-container">
                        <div className="custom-filter-dialog-desc">{translate('CustomFiltersDesc')}</div>
                        {this.state.sorts.map((s, i) => {
                            return (
                                <div className="sortLine" key={i}>
                                    <div className="sort-label">
                                        {sortList[s.sortIndex] + ` (${s.asc ? asc : desc})`}
                                    </div>
                                    <div
                                        className="sort-switch button"
                                        title={translate('ChangeSortDirection')}
                                        onClick={() => this.switch(i)}
                                    >
                                        <FontAwesomeIcon icon={faRandom} />
                                    </div>
                                    <div className="sort-up button" onClick={() => this.up(i)}>
                                        <FontAwesomeIcon icon={faArrowUp} />
                                    </div>
                                    <div className="sort-down button" onClick={() => this.down(i)}>
                                        <FontAwesomeIcon icon={faArrowDown} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="custom-filter-dialog-accept">
                        <Button
                            tabIndex={2}
                            className="custom-filter-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(true)}
                        >
                            {translate('OK')}
                        </Button>
                    </div>
                    <div className="custom-filter-dialog-cancel">
                        <Button
                            tabIndex={3}
                            className="custom-filter-dialog-button"
                            globalState={this.props.globalState}
                            onClicked={() => this.onConfirmation(false)}
                        >
                            {translate('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
