import * as React from 'react';
import { GlobalState } from '../globalState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown, faCheck, faStop } from '@fortawesome/free-solid-svg-icons';
import { PriceTools } from '../tools/priceTools';
import { VirtualizedList } from './virtualizedList';
import { Observer } from '../tools/observable';
import { Nullable } from '../tools/nullable';
import { ICard } from '../entities/ICard';
import { LinkTools } from '../tools/linkTool';
import { Card } from '../entities/card';
import { MagicCardMarket } from '../tools/prices/magicCardMarket';

export enum DataGridHeaderTypes {
    string,
    float,
    price,
    int,
    percentage,
    boolean,
    date
}

export interface IColumn {
    key: string;
    sortKey?: string;
    label: string;
    type: DataGridHeaderTypes;
    formatter?: (row: any, value: any) => string;
    onClick?: (row: any) => void;
    customRender?: (row: any, width: string, addClass: string) => JSX.Element;
    sizeOffset?: number;
    measure?: (value: string, width: number) => number;
    maxWidth?: number;
    minWidth?: number;
}

interface IDataGridProps {
    globalState: GlobalState;
    columns: IColumn[];
    orderStoreKey: string;
    rows: any[];
    className?: string;
    topParentClassName?: string;
    onDoubleClick?: (row: any) => void;
    onContextMenu?: (evt: React.MouseEvent<HTMLDivElement>, row: any) => void;
    readOnly?: boolean;
    onCollection?: (cards: ICard[]) => void;
    onAddToCollection?: (cards: ICard[]) => void;
    onRemoveFromCollection?: (cards: ICard[]) => void;
    onFoil?: (cards: ICard[]) => void;
    onSpecialFoil?: (cards: ICard[]) => void;
    onDeck?: (cards: ICard[]) => void;
    onRemoveFromDeck?: (cards: ICard[]) => void;
    onMoveInsideDeck?: (cards: ICard[]) => void;
    onEDHRec?: (card: ICard) => void;
    onSwapReprint?: (card: ICard) => void;
    onChangeIcon?: (cards: ICard[]) => void;
    onSpecifics?: (card: ICard) => void;
    onChangeProxyState?: (cards: ICard[], state: boolean) => void;
    onChangeDeckProxyState?: (cards: ICard[], state: boolean) => void;
    onCommander?: (card: ICard) => void;
    onOrdered?: (cards: ICard[]) => void;
    onTags?: (cards: ICard[]) => void;
    hideTooltip?: boolean;
}

export class DataGrid extends React.Component<IDataGridProps, { orderByIndex: number; asc: boolean }> {
    private widths?: number[];
    private isDragging = false;
    private startPoint: number;
    private currentDraggingColumnIndex: number;
    private currentColumnHeader: HTMLElement;
    private onMouseUpHandler: () => void;
    private onMouseMoveHandler: () => void;
    private dataRows: any[];
    private dragClass: string;
    private selectedRows: any[] = [];
    private latestIndex: number = -1;
    private onContextMenuCommandObserver: Nullable<Observer<{ card: ICard; command: string }>>;
    private contextRow: any;
    private columns: IColumn[];

    private get fontSize(): number {
        if (window.innerWidth < 768) {
            return 12;
        }
        return 16;
    }

    private get headerFontSize(): number {
        if (window.innerWidth < 768) {
            return 10;
        }
        return 14;
    }

    constructor(props: IDataGridProps) {
        super(props);

        this.onMouseUpHandler = this.stopDragging.bind(this);
        this.onMouseMoveHandler = this.drag.bind(this);

        this.state = {
            orderByIndex: GlobalState.LoadIntSettings(this.props.orderStoreKey, 0),
            asc: GlobalState.LoadBoolSettings(this.props.orderStoreKey + 'Order', true)
        };
        this.autosizeColumns(this.props);
    }

    private testDiv: HTMLDivElement;
    private sizeCache: { [key: number]: { [key: string]: number } } = {};

    private measureText(text: string, isHeader: boolean): number {
        if (!this.testDiv) {
            this.testDiv = document.getElementById('Test') as HTMLDivElement;
        }
        const fontSize = isHeader ? this.headerFontSize : this.fontSize;

        if (!this.sizeCache[fontSize]) {
            this.sizeCache[fontSize] = {};
        }

        if (!this.sizeCache[fontSize][text]) {
            this.testDiv.style.fontSize = fontSize + 'px';
            this.testDiv.innerHTML = text;
            this.sizeCache[fontSize][text] = this.testDiv.clientWidth + (isHeader ? 20 : 15);
        }

        return this.sizeCache[fontSize][text];
    }

    autosizeColumns(props: IDataGridProps) {
        if (!this.widths) {
            this.widths = [];
            this.columns = props.columns.slice(0);
            for (let index = 0; index < this.columns.length; index++) {
                this.autoSize(props, index, false);
            }
        }
    }

    startDragging(evt: React.PointerEvent<HTMLDivElement>, i: number, dragClass: string) {
        this.isDragging = true;
        this.startPoint = evt.pageX;
        this.currentDraggingColumnIndex = i;
        this.currentColumnHeader = evt.currentTarget.parentElement! as HTMLElement;
        this.dragClass = dragClass;

        document.addEventListener('mouseup', this.onMouseUpHandler);
        document.addEventListener('mousemove', this.onMouseMoveHandler);
    }

    stopDragging(_evt: React.PointerEvent<HTMLDivElement>) {
        if (!this.isDragging) {
            return;
        }
        this.isDragging = false;
        this.forceUpdate();

        document.removeEventListener('mouseup', this.onMouseUpHandler);
        document.removeEventListener('mousemove', this.onMouseMoveHandler);
    }

    drag(evt: React.PointerEvent<HTMLDivElement>) {
        if (!this.isDragging || !this.widths) {
            return;
        }
        this.widths[this.currentDraggingColumnIndex] += evt.pageX - this.startPoint;

        this.widths[this.currentDraggingColumnIndex] = Math.max(40, this.widths[this.currentDraggingColumnIndex]);

        this.startPoint = evt.pageX;
        const width = this.widths[this.currentDraggingColumnIndex] + 'px';
        this.currentColumnHeader.style.width = this.widths[this.currentDraggingColumnIndex] - 4 + 'px';

        const rows = document.querySelectorAll('.' + this.dragClass);
        rows.forEach((row: HTMLDivElement) => {
            row.style.width = width;
        });
    }

    autoSize(props: IDataGridProps, i: number, update = true) {
        const column = this.columns[i];
        const key = column.key;

        let longerText = column.label;
        let len = longerText.length;

        if (column.type !== DataGridHeaderTypes.boolean) {
            props.rows.forEach((row) => {
                let value = row[key];

                if (value) {
                    if (column.formatter) {
                        value = column.formatter(row, value);
                    } else if (column.type === DataGridHeaderTypes.percentage) {
                        value = value.toFixed(0) + '%';
                    } else if (column.type === DataGridHeaderTypes.float) {
                        value = value.toFixed(2);
                    } else if (column.type === DataGridHeaderTypes.price) {
                        value = PriceTools.Format(value, props.globalState);
                    } else if (column.type === DataGridHeaderTypes.int) {
                        value = value.toString();
                    }
                } else {
                    value = '';
                }

                value = value.trim();
                const size = value.length;

                if (size > len) {
                    len = size;
                    longerText = value;
                }
            });
        }

        let width = this.measureText(longerText, false);
        if (column.measure) {
            width = column.measure(longerText, width);
        }
        this.widths![i] = Math.max(column.minWidth || 0, Math.min(column.maxWidth || 300, width + (column.sizeOffset || 0)));

        if (update) {
            this.forceUpdate();
        }
    }

    sort() {
        const columnToSort = this.columns[this.state.orderByIndex];

        this.dataRows = this.props.rows
            .sort((a, b) => {
                const valueA = a[columnToSort.sortKey ?? columnToSort.key];
                const valueB = b[columnToSort.sortKey ?? columnToSort.key];

                switch (columnToSort.type) {
                    case DataGridHeaderTypes.float:
                    case DataGridHeaderTypes.int:
                    case DataGridHeaderTypes.price:
                    case DataGridHeaderTypes.percentage:
                    case DataGridHeaderTypes.boolean:
                        if (valueA === valueB) {
                            return 0;
                        }

                        if (valueA > valueB) {
                            return this.state.asc ? 1 : -1;
                        }

                        return this.state.asc ? -1 : 1;
                    case DataGridHeaderTypes.string:
                        if (valueA === undefined && valueB === undefined) {
                            return 0;
                        }

                        if (valueA === undefined) {
                            return this.state.asc ? -1 : 1;
                        }

                        if (valueB === undefined) {
                            return this.state.asc ? 1 : -1;
                        }

                        if (this.state.asc) {
                            return valueA.localeCompare(valueB);
                        }
                        return valueB.localeCompare(valueA);
                    case DataGridHeaderTypes.date:
                        const splitA = valueA.split('/');
                        const splitB = valueB.split('/');
                        const dateA = new Date(splitA[1], splitA[0]);
                        const dateB = new Date(splitB[1], splitB[0]);

                        let mult = 1;
                        if (!this.state.asc) {
                            mult = -1;
                        }
                        if (dateA < dateB) {
                            return -mult;
                        }

                        if (dateA > dateB) {
                            return mult;
                        }

                        return 0;
                }
            })
            .slice(0);
    }

    shouldComponentUpdate(newProps: IDataGridProps, newState: { orderByIndex: number; asc: boolean }) {
        if (newProps.rows !== this.props.rows) {
            this.widths = undefined;
            this.autosizeColumns(newProps);
            newState.orderByIndex = GlobalState.LoadIntSettings(newProps.orderStoreKey, 0);
            newState.asc = GlobalState.LoadBoolSettings(newProps.orderStoreKey + 'Order', true);
        }
        return true;
    }

    componentDidMount() {
        setTimeout(() => {
            const customData = this.props.globalState.pageCustomData;
            const scrollPosition = customData && customData.scroll ? customData.scroll : 0;

            const scroller = document.getElementById('scrollElement')!;
            scroller.scrollTop = scrollPosition;
        }, 15);
    }

    renderLabel(column: IColumn, row: any, i: number, addClass: string, key: string) {
        const width = this.widths![i] + 'px';
        if (!column.customRender) {
            const value = row[column.key];
            const needRightAlign = column.type === DataGridHeaderTypes.int;
            return (
                <div
                    className={
                        'datagrid-cell ' +
                        addClass +
                        (column.onClick ? ' hoverable' : '') +
                        (needRightAlign ? ' rightAlign' : '')
                    }
                    key={key}
                    onClick={() => {
                        if (column.onClick) {
                            column.onClick(row);
                        }
                    }}
                    style={{
                        width: width
                    }}
                >
                    {column.formatter ? (
                        column.formatter(row, value)
                    ) : column.type === DataGridHeaderTypes.boolean ? (
                        <FontAwesomeIcon icon={value ? faCheck : faStop} />
                    ) : column.type === DataGridHeaderTypes.percentage ? (
                        value.toFixed(0) + '%'
                    ) : column.type === DataGridHeaderTypes.float ? (
                        value.toFixed(2)
                    ) : column.type === DataGridHeaderTypes.price ? (
                        PriceTools.Format(value, this.props.globalState)
                    ) : (
                        value
                    )}
                </div>
            );
        }

        return column.customRender(row, width, addClass);
    }

    rowClick(evt: React.MouseEvent<HTMLDivElement>, row: any, index: number) {
        if (this.props.readOnly === true) {
            return;
        }

        let newState = !row.isSelected;

        if (evt.shiftKey) {
            this.selectedRows.forEach((row) => {
                row.isSelected = false;
            });

            this.selectedRows = [];

            if (this.latestIndex === -1) {
                this.latestIndex = index;
            }

            const from = Math.min(index, this.latestIndex);
            const to = Math.max(index, this.latestIndex);
            for (let i = from; i <= to; i++) {
                this.dataRows[i].isSelected = true;
                this.selectedRows.push(this.dataRows[i]);
            }
            this.forceUpdate();

            return;
        } else if (!evt.ctrlKey) {
            if (this.selectedRows.length > 1) {
                newState = true;
            }

            this.selectedRows.forEach((row) => {
                row.isSelected = false;
            });

            this.selectedRows = [];
        } else if (row.isSelected) {
            const index = this.selectedRows.indexOf(row);
            this.selectedRows.splice(index, 1);
        }

        if (newState) {
            this.selectedRows.push(row);
        }

        row.isSelected = newState;
        this.latestIndex = index;

        this.forceUpdate();
    }

    selectAll() {
        this.selectedRows = [];
        this.dataRows.forEach((row) => {
            row.isSelected = true;

            this.selectedRows.push(row);
        });

        this.forceUpdate();
    }

    unselect(update = true) {
        this.selectedRows.forEach((row) => {
            row.isSelected = false;
        });

        this.selectedRows = [];

        if (update) {
            this.forceUpdate();
        }
    }

    UNSAFE_componentWillMount() {
        this.onContextMenuCommandObserver = this.props.globalState.onContextMenuCommand.add(async (command) => {
            const card = this.contextRow as Card;

            switch (command.command) {
                case 'selectAll':
                    this.selectAll();
                    break;
                case 'unselect':
                    this.unselect();
                    break;
                case 'collection':
                    if (this.props.onCollection) {
                        this.props.onCollection(this.selectedRows as ICard[]);
                    }
                    break;
                case 'addToCollection':
                    if (this.props.onAddToCollection) {
                        this.props.onAddToCollection(this.selectedRows as ICard[]);
                    }
                    break;
                case 'removeFromCollection':
                    if (this.props.onRemoveFromCollection) {
                        this.props.onRemoveFromCollection(this.selectedRows as ICard[]);
                    }
                    break;
                case 'foil':
                    if (this.props.onFoil) {
                        this.props.onFoil(this.selectedRows as ICard[]);
                    }
                    break;
                case 'specialFoil':
                    if (this.props.onSpecialFoil) {
                        this.props.onSpecialFoil(this.selectedRows as ICard[]);
                    }
                    break;
                case 'deck':
                    if (this.props.onDeck) {
                        this.props.onDeck(this.selectedRows as ICard[]);
                    }
                    break;
                case 'quickRemoveFromDeck':
                    if (this.props.onRemoveFromDeck) {
                        this.props.onRemoveFromDeck(this.selectedRows as ICard[]);
                    }
                    break;
                case 'moveInsideDeck':
                    if (this.props.onMoveInsideDeck) {
                        this.props.onMoveInsideDeck(this.selectedRows as ICard[]);
                    }
                    break;
                case 'edhrec':
                    if (this.props.onEDHRec) {
                        this.props.onEDHRec(this.selectedRows[0] as ICard);
                    }
                    break;
                case 'swapReprint':
                    if (this.props.onSwapReprint) {
                        this.props.onSwapReprint((this.selectedRows as ICard[])[0]);
                    }
                    break;
                case 'changeDeckProxyState':
                    if (this.props.onChangeDeckProxyState) {
                        this.props.onChangeDeckProxyState(this.selectedRows as ICard[], !!command.option);
                    }
                    break;
                case 'changeProxyState':
                    if (this.props.onChangeProxyState) {
                        this.props.onChangeProxyState(this.selectedRows as ICard[], !!command.option);
                    }
                    break;
                case 'changeIcon':
                    if (this.props.onChangeIcon) {
                        this.props.onChangeIcon(this.selectedRows as ICard[]);
                    }
                    break;
                case 'specifics':
                    if (this.props.onSpecifics) {
                        this.props.onSpecifics(this.selectedRows[0] as ICard);
                    }
                    break;
                case 'commander':
                    if (this.props.onCommander) {
                        this.props.onCommander((this.selectedRows as ICard[])[0]);
                    }
                    break;
                case 'ebay': {
                    let query = card.name + '+' + card.set.name;

                    query = query.replace(/\s/g, '+');

                    LinkTools.OpenLink(`https://www.ebay.com/sch/i.html?_nkw=${query}&_sop=15`);
                    break;
                }
                case 'mcm': {
                    this.props.globalState.onWaitRingRequired.notifyObservers(true);
                    const link = await MagicCardMarket.GetLinkAsync(card);
                    LinkTools.OpenLink(link);
                    this.props.globalState.onWaitRingRequired.notifyObservers(false);
                    break;
                }
                case 'tcg': {
                    const setName = card.set.prepareSetNameForTCGPlayer(card);
                    const cardName = card.prepareCardNameForTCGPlayer(true);

                    LinkTools.OpenLink(
                        // eslint-disable-next-line max-len
                        `http://shop.tcgplayer.com/magic/${setName}/${cardName}?partner=URZGTHR&utm_campaign=affiliate&utm_medium=URZGTHR&utm_source=URZGTHR`
                    );
                    break;
                }
                case 'ordered':
                    if (this.props.onOrdered) {
                        this.props.onOrdered(this.selectedRows as ICard[]);
                    }
                    break;
                case 'tags':
                    if (this.props.onTags) {
                        this.props.onTags(this.selectedRows as ICard[]);
                    }
                    break;
            }
        });
    }

    componentWillUnmount() {
        this.props.globalState.onContextMenuCommand.remove(this.onContextMenuCommandObserver);
    }

    onContextMenu(evt: React.MouseEvent<HTMLDivElement>, row: any) {
        evt.preventDefault();

        if (!this.props.onContextMenu) {
            return;
        }

        if (!row.isSelected) {
            this.selectedRows.forEach((row) => {
                row.isSelected = false;
            });

            this.selectedRows = [];
            row.isSelected = true;
            this.selectedRows.push(row);

            this.forceUpdate();
        }

        this.contextRow = row;
        this.props.onContextMenu(evt, row);
    }

    renderElement(row: any, i: number): React.ReactElement<any> {
        return (
            <div
                className={'datagrid-row' + (i % 2 ? ' alternate' : ((GlobalState.IsThereABackground ? ' glass' : ''))) + (row.isSelected ? ' selected' : '')}
                onClick={(evt) => this.rowClick(evt, row, i)}
                onContextMenu={(evt) => this.onContextMenu(evt, row)}
                onDoubleClick={(evt) => {
                    if (this.props.onDoubleClick && !((evt.nativeEvent as any).srcElement!.classList.contains('text')) && !((evt.nativeEvent as any).srcElement!.classList.contains('ui-btn'))) {
                        this.props.onDoubleClick(row);
                    }
                }}
                key={`row${i}`}
            >
                {this.columns.map((column, columnIndex) => {
                    return this.renderLabel(column, row, columnIndex, `row${column.key}`, `row${i}${column.key}`);
                })}
            </div>
        );
    }

    private datagridHeaders: HTMLDivElement;
    scrollHeaders(left: number) {
        if (!this.datagridHeaders) {
            this.datagridHeaders = document.getElementById('datagrid-headers') as HTMLDivElement;
        }

        this.datagridHeaders.style.left = -left + 'px';
    }

    render() {
        this.sort();
        return (
            <div
                className={'datagrid' + (this.props.className ? ' ' + this.props.className : '')}
                onPointerUp={(evt) => this.stopDragging(evt)}
            >
                <div className="datagrid-headers" id="datagrid-headers">
                    {this.columns.map((column, i) => {
                        return (
                            <div
                                className="datagrid-header"
                                title={column.label}
                                style={{
                                    width: this.widths![i] - 4 + 'px'
                                }}
                                key={column.key}
                            >
                                <div
                                    className="datagrid-header-label-container"
                                    onClick={() => {
                                        if (this.state.orderByIndex === i) {
                                            this.props.globalState.storeBoolSettings(
                                                this.props.orderStoreKey + 'Order',
                                                !this.state.asc
                                            );
                                            this.setState({ asc: !this.state.asc });
                                        } else {
                                            this.props.globalState.storeNumberSettings(this.props.orderStoreKey, i);
                                            this.setState({ orderByIndex: i });
                                        }
                                    }}
                                >
                                    <div className="datagrid-header-label">{column.label}</div>
                                    {this.state.orderByIndex === i && (
                                        <div className="datagrid-header-sort">
                                            {this.state.asc && <FontAwesomeIcon icon={faCaretUp} />}
                                            {!this.state.asc && <FontAwesomeIcon icon={faCaretDown} />}
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="datagrid-header-splitter"
                                    onDoubleClick={() => this.autoSize(this.props, i)}
                                    onPointerDown={(evt) => this.startDragging(evt, i, `row${column.key}`)}
                                >
                                    <div className="datagrid-header-split" />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="datagrid-rows">
                    <VirtualizedList
                        diff={60}
                        horizontalScrollerClass="datagrid"
                        offset={this.props.topParentClassName ? 30 : 1}
                        className="datagrid-rows-list"
                        directContainer={!this.props.topParentClassName}
                        topParentClassName={this.props.topParentClassName || 'datagrid-rows'}
                        cellHeight={34}
                        tooltipOnProperty={this.props.hideTooltip ? '' : 'picturePath'}
                        globalState={this.props.globalState}
                        dataSource={this.dataRows}
                        renderElement={this.renderElement.bind(this)}
                        onHorizontalScroll={(left) => this.scrollHeaders(left)}
                    />
                </div>
            </div>
        );
    }
}
