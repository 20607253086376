import * as React from 'react';
import { GlobalState, SearchData, SelectionData } from '../globalState';
import { Set } from '../entities/set';
import { CardCard } from '../controls/cards/cardCard';
import { PriceTools } from '../tools/priceTools';
import { ICard } from '../entities/ICard';
import { VirtualizedList } from '../controls/virtualizedList';
import { Nullable } from '../tools/nullable';
import { Observer } from '../tools/observable';
import { CardColor } from '../entities/cardColor';
import { CardRarity } from '../entities/cardRarity';
import { CardFormat } from '../entities/cardFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus,
    faMinus,
    faImages,
    faPlusSquare,
    faFileImage,
    faFileExport,
    faCheckDouble,
    faShoppingBasket,
    faSun,
    faThLarge
} from '@fortawesome/free-solid-svg-icons';
import { Collection } from '../entities/collection';
import { Deck } from '../entities/deck';
import { RangeSlider } from '../controls/rangeSlider';
import { CSVExport } from '../tools/csvTools';
import { CountTools } from '../tools/countTools';
import { LinkTools } from '../tools/linkTool';
import { DataGrid, DataGridHeaderTypes, IColumn } from '../controls/dataGrid';
import { ManaTools } from '../tools/manaTools';
import { DeckCard } from '../entities/deckCard';
import { TCGPlayer } from '../tools/prices/tcgPlayer';
import {
    faExchange,
    faHelmetBattle,
    faDollarSign,
    faTreasureChest,
    faArrowRight,
    faTags,
    faBookSpells,
    faShoppingCart,
    faGem,
    faUnicorn
} from '@fortawesome/pro-solid-svg-icons';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { CardSuperType } from '../entities/cardSuperType';
import { DeckEntry } from '../entities/deckEntry';
import { MagicCardMarket } from '../tools/prices/magicCardMarket';
import { SortManager } from '../tools/sortManager';
import { ReservedList } from '../entities/reservedList';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import { Scryfall } from '../tools/prices/scryfall';
import { MobileTools } from '../tools/mobileTools';
import { GroupData, GroupedList } from '../controls/groupedList';
import { Button } from '../controls/button';
import { Card } from '../entities/card';
import { StringHelpers } from '../tools/stringHelpers';

require('../scss/cardsPage.scss');

interface ICardsPageProps {
    globalState: GlobalState;
    parentExpansion?: Set;
    searchData?: SearchData;
    deck?: Deck;
}

interface ICardsPageState {
    imageWidth: number;
    filter: string;
    sortIndex: number;
    colorIndex: number;
    author: string;
    rarityIndex: number;
    type: string;
    format: string;
    stateIndex: number;
    isFilterExpanded: boolean;
    expansionIndex: number;
    deckIndex: string;
    tagIndex: number;
    price: number[];
    manacost: number[];
    power: number[];
    toughness: number[];
    fullText: string;
    datagridMode: boolean;
}

const menuSeparator = '--------';
let authorsDictionary: { [key: string]: number } = {};
let typesDictionary: { [key: string]: number } = {};
let rarityDictionary: { [key: number]: number } = {};
let colorDictionary: { [key: number]: number } = {};

export class CardsPage extends React.Component<ICardsPageProps, ICardsPageState> {
    private onImageWidthChangedObserver: Nullable<Observer<number>>;
    private onSelectModeObserver: Nullable<Observer<boolean>>;
    private onCollectionModeObserver: Nullable<Observer<boolean>>;
    private onCollectionCommandActivatedObserver: Nullable<Observer<void>>;
    private onSelectedCardChangedObserver: Nullable<Observer<ICard>>;
    private onDeckCommandActivatedObserver: Nullable<Observer<void>>;
    private onStatisticsObserver: Nullable<Observer<void>>;
    private onSimulatorObserver: Nullable<Observer<void>>;
    private onForcePageRefreshObserver: Nullable<Observer<void>>;
    private onContextMenuCommandObserver: Nullable<Observer<{ card: ICard; command: string }>>;
    private onSelectedMenuIndexChangedObserver: Nullable<Observer<SelectionData>>;
    private selectionMode = false;
    private collectionMode = false;
    private cards: ICard[];
    private initialCardList: ICard[];
    private colorList: CardColor[];
    private rarityList: CardRarity[];
    private authors: string[];
    private types: string[];
    private decks: Deck[];
    private sets: Set[];
    private tags: string[];
    private tagCount: { [key: string]: number } = {};
    private localChange = false;
    private sliderChange = false;
    private minPrice: number = Number.MAX_VALUE;
    private maxPrice: number = 0;
    private minManacost: number = Number.MAX_VALUE;
    private maxManacost: number = 0;
    private minPower: number = Number.MAX_VALUE;
    private maxPower: number = 0;
    private minToughness: number = Number.MAX_VALUE;
    private maxToughness: number = 0;
    private selectedCards: ICard[] = [];
    private deckPartIndex = 0;
    private collectionPage = false;
    private datagridModeEnv:
        | 'DataGridInsteadOfList'
        | 'DataGridInsteadOfListForCollection'
        | 'DataGridInsteadOfListForDecks';
    private dataGridRender: React.RefObject<DataGrid>;
    private listener: any;
    private cardTooltipImage: Nullable<HTMLImageElement>;
    private timeoutId: number = -1;

    constructor(props: ICardsPageProps) {
        super(props);

        this.dataGridRender = React.createRef();

        this.state = this.prepareDefaultState(props);

        const customData = this.props.globalState.pageCustomData;
        this.deckPartIndex = customData && customData.tabIndex ? customData.tabIndex : 0;

        this.props.globalState.onWaitRingRequired.notifyObservers(true);
        setTimeout(() => {
            this.prepareLists(this.props, this.state);

            this.collectionMode = GlobalState.LoadBoolSettings('CollectionMode', false);

            this.buildCustomActions();

            this.listener = this.eventListener.bind(this);

            window.addEventListener('message', this.listener);

            this.forceUpdate();
            this.props.globalState.onWaitRingRequired.notifyObservers(false);
        }, 10);
    }

    eventListener(event: any) {
        // We only accept messages from ourselves
        if (event.source !== window) {
            return;
        }

        if (event.data.type && (event.data.type === 'GET_CARD_LIST')) {
            // Update extension
            const data = { type: 'CARD_LIST', text: this.cards.map(c => c.tcgPlayerId).join(',') };
            window.postMessage(data, '*');
        }
    }

    buildCustomActions() {
        const customActions = [];

        if (this.props.deck || this.props.parentExpansion) {
            customActions.push({
                label: this.props.globalState.translate('UpdatePricesDesc'),
                action: () => {
                    if (this.props.parentExpansion) {
                        this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
                            index: -5,
                            uniqueString: 'S',
                            uniqueID: this.props.parentExpansion.id
                        });
                    } else if (this.props.deck) {
                        this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
                            index: -5,
                            uniqueString: this.props.deck.UniqueID
                        });
                    }
                },
                icon: faDollarSign as Icon
            });
            customActions.push({
                label: ''
            });
        }

        customActions.push({
            label: this.props.globalState.translate('LoreExplorer'),
            action: () => {
                this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
                    index: 14,
                    cardIds: this.cards.filter(c => c.flavor || c.isStorySpotlight).map((c) => c.id),
                    uniqueID: 0
                });
            },
            icon: faBookSpells
        });

        if (this.props.parentExpansion) {
            customActions.push({
                label: this.props.globalState.translate('AlbumView'),
                action: () => {
                    this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
                        index: 7,
                        uniqueID: this.props.parentExpansion!.id
                    });
                },
                icon: faImages
            });
            customActions.push({
                label: this.props.globalState.translate('OpenBlock'),
                action: () => {
                    this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
                        index: -1,
                        uniqueID: this.props.parentExpansion!.block.id
                    });
                },
                icon: faThLarge
            });
        }
        if (this.props.searchData) {
            customActions.push({
                label: this.props.globalState.translate('AlbumView'),
                action: () => {
                    this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
                        index: 7,
                        searchData: this.props.searchData
                    });
                },
                icon: faImages
            });
            customActions.push({
                label: this.props.globalState.translate('OpenBlock'),
                action: () => {
                    this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
                        index: -1,
                        uniqueID: this.props.parentExpansion!.block.id
                    });
                },
                icon: faThLarge
            });
        }
        customActions.push({
            label: ''
        });

        if (this.props.deck && this.selectedCards && this.selectedCards.length) {
            customActions.push({
                label: this.props.globalState.translate('Move'),
                action: () => {
                    this.onMoveInsideDeck(this.selectedCards);
                },
                icon: faArrowRight as Icon
            });

            if (this.selectedCards.length === 1) {
                const card = this.selectedCards[0];

                if (
                    card.isCommander &&
                    this.props.deck &&
                    (!this.props.deck.format ||
                        this.props.deck.format === CardFormat.CommanderIndex ||
                        this.props.deck.format === CardFormat.DuelCommanderIndex ||
                        this.props.deck.format === CardFormat.BrawlIndex)
                ) {
                    customActions.push({
                        label: !card.isDeckCommander
                            ? this.props.globalState.translate('SetAsCommander')
                            : this.props.globalState.translate('RemoveCommander'),
                        action: () => {
                            this.swapCommanderState(card);
                        },
                        icon: faHelmetBattle as Icon
                    });
                }
                customActions.push({
                    label: this.props.globalState.translate('SwapReprints'),
                    action: () => {
                        this.swapReprints(this.selectedCards[0], this.deckPartIndex);
                    },
                    icon: faExchange as Icon
                });
            }

            customActions.push({
                label: ''
            });
        }

        if (!this.collectionMode) {
            customActions.push({
                label: this.props.globalState.translate('CollectionMode'),
                action: () => {
                    this.collectionMode = !this.collectionMode;
                    this.props.globalState.storeBoolSettings('CollectionMode', this.collectionMode);
                    this.props.globalState.onCollectionMode.notifyObservers(this.collectionMode);
                    this.buildCustomActions();
                },
                icon: faPlusSquare
            });
        } else {
            customActions.push({
                label: this.props.globalState.translate('NormalMode'),
                action: () => {
                    this.collectionMode = !this.collectionMode;
                    this.props.globalState.storeBoolSettings('CollectionMode', this.collectionMode);
                    this.props.globalState.onCollectionMode.notifyObservers(this.collectionMode);
                    this.buildCustomActions();
                },
                icon: faFileImage
            });
        }

        if (!this.state.datagridMode) {
            customActions.push({
                label: this.props.globalState.translate('ListView'),
                action: () => {
                    this.props.globalState.storeBoolSettings(this.datagridModeEnv, !this.state.datagridMode);
                    this.props.globalState.onDataGridModeChanged.notifyObservers(true);
                    this.setState({ datagridMode: !this.state.datagridMode });
                    setTimeout(() => {
                        this.buildCustomActions();
                        this.props.globalState.showSelectCommand.notifyObservers(false);
                    }, 0);
                },
                icon: faListAlt
            });
        } else {
            customActions.push({
                label: this.props.globalState.translate('GridView'),
                action: () => {
                    this.props.globalState.storeBoolSettings(this.datagridModeEnv, !this.state.datagridMode);
                    this.props.globalState.onDataGridModeChanged.notifyObservers(false);
                    this.setState({ datagridMode: !this.state.datagridMode });
                    setTimeout(() => {
                        this.buildCustomActions();
                        this.props.globalState.showSelectCommand.notifyObservers(true);
                    }, 0);
                },
                icon: faImages
            });
        }

        customActions.push({
            label: ''
        });

        if (!this.props.deck) {
            customActions.push(
                {
                    label: this.props.globalState.translate('ExportToCSV'),
                    action: () => {
                        CSVExport.ExportAsync(this.cards, this.props.globalState);
                    },
                    icon: faFileExport
                });
            if (!this.state.datagridMode) {
                customActions.push({
                    label: ''
                });
            }
        }

        if (!this.state.datagridMode) {
            if (!this.props.deck || !this.props.deck.Locked) {
                customActions.push({
                    label: this.props.globalState.translate('SelectAll'),
                    action: () => {
                        this.selectAll();
                    },
                    icon: faCheckDouble
                });
            }
        }

        if (this.selectedCards && this.selectedCards.length > 0) {
            customActions.push({
                label: 'CardMarket',
                action: async () => {
                    if (this.selectedCards.length > 1) {
                        this.props.globalState.onShoppingAssistantRequired.notifyObservers({ cards: this.selectedCards, urlGenerator: MagicCardMarket.GetLinkAsync });
                    } else {
                        this.props.globalState.onWaitRingRequired.notifyObservers(true);
                        const link = await MagicCardMarket.GetLinkAsync(this.selectedCards[0]);
                        LinkTools.OpenLink(link);
                        this.props.globalState.onWaitRingRequired.notifyObservers(false);
                    }
                },
                icon: faShoppingCart
            });
            customActions.push({
                label: 'TCGPlayer',
                action: () => {
                    if (this.selectedCards.length > 1) {
                        this.props.globalState.onShoppingAssistantRequired.notifyObservers({ cards: this.selectedCards, urlGenerator: card => Promise.resolve(TCGPlayer.GetCardLink(card)) });
                    } else {
                        LinkTools.OpenLink(TCGPlayer.GetCardLink(this.selectedCards[0]));
                    }
                },
                icon: faShoppingCart
            });
            customActions.push({
                label: this.props.globalState.translate('SwitchOrderedFlag'),
                action: () => {
                    this.onOrdered(this.selectedCards);
                },
                icon: faShoppingBasket
            });

            customActions.push({
                label: ''
            });

            if (this.props.deck) {
                customActions.push({
                    label: this.props.globalState.translate('Collection_'),
                    action: () => {
                        this.onCollection(this.selectedCards);
                    },
                    icon: faTreasureChest as Icon
                });
            }

            customActions.push({
                label: this.props.globalState.translate('AddToCollection'),
                action: () => {
                    this.selectedCards.forEach((c) => {
                        c.count = Math.min(GlobalState.MaxCardCount, c.count + 1);
                    });
                    this.onAddToCollection(this.selectedCards);
                },
                icon: faPlus
            });

            customActions.push({
                label: this.props.globalState.translate('RemoveFromCollection'),
                action: () => {
                    this.selectedCards.forEach((c) => {
                        c.count = Math.max(0, c.count - 1);
                    });
                    this.onRemoveFromCollection(this.selectedCards);
                },
                icon: faMinus
            });

            if (this.selectedCards.every((c) => c.canBeFoil)) {
                customActions.push({
                    label: this.props.globalState.translate('Foils'),
                    action: () => {
                        this.onFoil(this.selectedCards);
                    },
                    icon: faSun
                });
            }

            if (this.selectedCards.every((c) => c.canBeSpecialFoil)) {
                customActions.push({
                    label: this.props.globalState.translate('SpecialFoils'),
                    action: () => {
                        this.onSpecialFoil(this.selectedCards);
                    },
                    icon: faGem
                });
            }

            customActions.push({
                label: ''
            });

            customActions.push({
                label: this.props.globalState.translate('Tags'),
                action: () => {
                    this.onTags(this.selectedCards);
                },
                icon: faTags as Icon
            });
        }

        if (customActions.length) {
            this.props.globalState.onCustomMenuRequired.notifyObservers(customActions);
        }
    }

    selectAll() {
        this.selectedCards = [];
        this.selectionMode = true;

        this.cards.forEach((card) => {
            card.isSelected = true;
            this.props.globalState.onSelectedCardChanged.notifyObservers(card);
        });
        this.props.globalState.onSelectModeActivationRequired.notifyObservers();
        this.props.globalState.onCollectionCommandRequired.notifyObservers(this.selectedCards.length > 0);
        this.props.globalState.onDeckCommandRequired.notifyObservers(this.selectedCards.length > 0);

        this.buildCustomActions();
    }

    resetState() {
        this.props.globalState.pageCustomData = {};
        this.forceUpdate();
    }

    prepareDefaultState(props: ICardsPageProps, state?: any) {
        const defaultSortIndex = props.parentExpansion
            ? GlobalState.LoadIntSettings('ExpansionDefaultSortIndex', 0)
            : GlobalState.LoadIntSettings('DefaultSortIndex', 1);
        const filter = props.globalState.pageCustomData ? props.globalState.pageCustomData.filter || '' : '';
        const sortIndex = props.globalState.pageCustomData
            ? props.globalState.pageCustomData.sortIndex || defaultSortIndex
            : defaultSortIndex;
        const colorIndex = props.globalState.pageCustomData ? props.globalState.pageCustomData.colorIndex || 0 : 0;
        const rarityIndex = props.globalState.pageCustomData ? props.globalState.pageCustomData.rarityIndex || 0 : 0;
        const author = props.globalState.pageCustomData ? props.globalState.pageCustomData.author || '' : '';
        const type = props.globalState.pageCustomData ? props.globalState.pageCustomData.type || '' : '';
        const format = props.globalState.pageCustomData ? props.globalState.pageCustomData.format || '' : '';
        const stateIndex = props.globalState.pageCustomData ? props.globalState.pageCustomData.stateIndex || 0 : 0;
        const isFilterExpanded = props.globalState.pageCustomData
            ? props.globalState.pageCustomData.isFilterExpanded || false
            : false;
        const expansionIndex = props.globalState.pageCustomData
            ? props.globalState.pageCustomData.expansionIndex || 0
            : 0;
        const deckIndex = props.globalState.pageCustomData ? props.globalState.pageCustomData.deckIndex || 0 : 0;
        const tagIndex = props.globalState.pageCustomData ? props.globalState.pageCustomData.tagIndex || 0 : 0;
        const price = props.globalState.pageCustomData ? props.globalState.pageCustomData.price || [] : [];
        const manacost = props.globalState.pageCustomData ? props.globalState.pageCustomData.manacost || [] : [];
        const power = props.globalState.pageCustomData ? props.globalState.pageCustomData.power || [] : [];
        const toughness = props.globalState.pageCustomData ? props.globalState.pageCustomData.toughness || [] : [];
        const fullText = props.globalState.pageCustomData ? props.globalState.pageCustomData.fullText || [] : [];

        if (!state) {
            state = {};
        }

        state.imageWidth = props.globalState.imageWidth;
        state.filter = filter;
        state.sortIndex = sortIndex;
        state.colorIndex = colorIndex;
        state.author = author;
        state.rarityIndex = rarityIndex;
        state.type = type;
        state.format = format;
        state.stateIndex = stateIndex;
        state.isFilterExpanded = isFilterExpanded;
        state.expansionIndex = expansionIndex;
        state.deckIndex = deckIndex;
        state.tagIndex = tagIndex;
        state.price = price;
        state.manacost = manacost;
        state.power = power;
        state.toughness = toughness;
        state.fullText = fullText;

        return state as ICardsPageState;
    }

    prepareLists(props: ICardsPageProps, nextState: ICardsPageState) {
        this.datagridModeEnv = 'DataGridInsteadOfList';

        this.collectionPage = false;
        if (props.deck) {
            this.datagridModeEnv = 'DataGridInsteadOfListForDecks';
            switch (this.deckPartIndex) {
                case 0:
                    this.initialCardList = Deck.Cards(props.deck);
                    break;
                case 1:
                    this.initialCardList = Deck.Sideboard(props.deck);
                    break;
                case 2:
                    this.initialCardList = Deck.Reserve(props.deck);
                    break;
            }
        } else if (props.parentExpansion) {
            this.initialCardList = props.parentExpansion.cards;
        } else if (props.searchData) {
            this.initialCardList = Collection.Search(props.searchData);
        } else {
            this.initialCardList = Collection.GetCollection();
            this.collectionPage = true;

            this.datagridModeEnv = 'DataGridInsteadOfListForCollection';
        }

        // Some filters
        if (this.props.globalState.hideSplitCard || this.props.globalState.hideTransformCard) {
            const temp = this.initialCardList.slice(0);

            for (const card of this.initialCardList) {
                if (card.isBackFace) {
                    if (
                        (this.props.globalState.hideSplitCard && !card.canTransform) ||
                        (this.props.globalState.hideTransformCard && card.canTransform)
                    ) {
                        let cardsToCheck = [card.otherCard];

                        if (card.otherCards) {
                            cardsToCheck = card.otherCards;
                        }

                        for (const other of cardsToCheck) {
                            if (temp.indexOf(other) !== -1) {
                                const index = temp.indexOf(card);

                                if (index > -1) {
                                    temp.splice(index, 1);
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            this.initialCardList = temp;
        }

        // Colors
        this.colorList = [CardColor.Colors[0]];
        colorDictionary = {};

        // Rarities
        this.rarityList = [CardRarity.Rarities[0]];
        rarityDictionary = {};

        // Sets
        this.sets = [];
        const setDictionary: { [key: string]: boolean } = {};

        // Decks
        this.decks = (Collection.Decks.filter(d => !d.disabled && Deck.AllCards(d).some(c => this.initialCardList.indexOf(Collection.CardsIndex[c.CardID]) !== -1)) || []).sort((a, b) => a.Name.localeCompare(b.Name));

        // Types & authors
        this.types = [];
        typesDictionary = {};

        this.authors = [];
        authorsDictionary = {};

        const excludedTypes = ['Artifact', 'Eldrazi', 'Instant', 'Land', 'Sorcery', 'Enchantment', 'Token'];

        const superTypes: CardSuperType[] = [];

        this.tags = [];

        this.tagCount = {};

        if (this.initialCardList.length) {
            for (const card of this.initialCardList) {
                card.isSelected = false;

                if (!rarityDictionary[card.rarityId]) {
                    this.rarityList.push(CardRarity.Rarities[card.rarityId]);
                    rarityDictionary[card.rarityId] = 1;
                } else {
                    rarityDictionary[card.rarityId] = rarityDictionary[card.rarityId] + 1;
                }

                if (!colorDictionary[card.colorId]) {
                    this.colorList.push(CardColor.GetColorById(card.colorId));
                    colorDictionary[card.colorId] = 1;
                } else {
                    colorDictionary[card.colorId] = colorDictionary[card.colorId] + 1;
                }

                if (!setDictionary[card.set.name]) {
                    this.sets.push(card.set);
                    setDictionary[card.set.name] = true;
                }

                const type = card.type.toLowerCase();
                if (!typesDictionary[type]) {
                    if (excludedTypes.indexOf(card.typeEn) === -1) {
                        this.types.push(card.type);
                    }
                    typesDictionary[type] = 1;

                    for (const superType of CardSuperType.SuperTypes) {
                        if (superTypes.indexOf(superType) === -1 && superType.predicate(card)) {
                            superTypes.push(superType);
                        }
                    }
                } else {
                    typesDictionary[type] = typesDictionary[type] + 1;
                }

                if (!authorsDictionary[card.author]) {
                    this.authors.push(card.author);
                    authorsDictionary[card.author] = 1;
                } else {
                    authorsDictionary[card.author] = authorsDictionary[card.author] + 1;
                }

                if (card.price < this.minPrice) {
                    this.minPrice = card.price;
                }

                if (card.price > this.maxPrice) {
                    this.maxPrice = card.price;
                }

                if (card.convertedManaCost > this.maxManacost && card.convertedManaCost < 1000) {
                    this.maxManacost = card.convertedManaCost;
                }

                if (card.convertedManaCost < this.minManacost) {
                    this.minManacost = card.convertedManaCost;
                }

                if (card.force !== null) {
                    if (card.force < this.minPower) {
                        this.minPower = card.force;
                    }

                    if (card.force > this.maxPower) {
                        this.maxPower = card.force;
                    }
                }

                if (card.defense !== null) {
                    if (card.defense < this.minToughness) {
                        this.minToughness = card.defense;
                    }

                    if (card.defense > this.maxToughness) {
                        this.maxToughness = card.defense;
                    }
                }

                for (const tag of card.tags) {
                    if (this.tags.indexOf(tag) === -1) {
                        this.tags.push(tag);
                    }

                    if (!this.tagCount[tag]) {
                        this.tagCount[tag] = 1;
                    } else {
                        this.tagCount[tag]++;
                    }
                }
            }
        } else {
            this.minPrice = 0;
            this.maxPrice = 0;
            this.minManacost = 0;
            this.maxManacost = 0;
            this.minPower = 0;
            this.maxPower = 0;
            this.minToughness = 0;
            this.maxToughness = 0;
        }

        if (nextState.price.length === 0) {
            nextState.price.push(this.minPrice);
            nextState.price.push(this.maxPrice);
        }

        if (nextState.manacost.length === 0) {
            nextState.manacost.push(this.minManacost);
            nextState.manacost.push(this.maxManacost);
        }

        if (nextState.power.length === 0) {
            nextState.power.push(this.minPower);
            nextState.power.push(this.maxPower);
        }

        if (nextState.toughness.length === 0) {
            nextState.toughness.push(this.minToughness);
            nextState.toughness.push(this.maxToughness);
        }

        nextState.datagridMode = GlobalState.LoadBoolSettings(this.datagridModeEnv, false);

        this.types.sort((a, b) => a.localeCompare(b));
        this.sets.sort((a, b) => a.name.localeCompare(b.name));
        this.tags.sort();

        if (this.rarityList.length === 2) {
            this.rarityList.splice(0, 1);
        }

        this.rarityList.sort((a, b) => {
            if (a.id === b.id) {
                return 0;
            }

            if (a.id > b.id) {
                return 1;
            }

            return -1;
        });


        if (this.colorList.length === 2) {
            this.colorList.splice(0, 1);
        }

        this.colorList.sort((a, b) => {
            if (a.sortOrder === b.sortOrder) {
                return 0;
            }

            if (a.sortOrder > b.sortOrder) {
                return 1;
            }

            return -1;
        });

        if (superTypes.length) {
            this.types.splice(0, 0, menuSeparator);

            superTypes.sort((a, b) => {
                return -a.name.localeCompare(b.name);
            });

            for (const superType of superTypes) {
                this.types.splice(0, 0, superType.name);
                // eslint-disable-next-line no-loop-func
                this.initialCardList.forEach(card => {
                    if (superType.predicate(card)) {
                        const lower = superType.name.toLowerCase();
                        if (!typesDictionary[lower]) {
                            typesDictionary[lower] = 1;
                            return;
                        }
                        typesDictionary[lower] = typesDictionary[lower] + 1;
                    }
                });
            }
        }

        if ((this.types[0] && this.types.length > 1) || this.types.length === 0) {
            this.types.splice(0, 0, '');
        }

        this.authors = this.authors.sort((a, b) => a.localeCompare(b));

        if ((this.authors[0] && this.authors.length > 1) || this.authors.length === 0) {
            this.authors.splice(0, 0, '');
        }
    }

    UNSAFE_componentWillUpdate(nextProps: ICardsPageProps, nextState: ICardsPageState) {
        if (!this.localChange && !this.sliderChange) {
            this.localChange = true;
            this.prepareDefaultState(nextProps, nextState);
            this.prepareLists(nextProps, nextState);
        }

        return true;
    }

    onCollectionDataUpdated() {
        if (!this.collectionPage) {
            if (this.props.parentExpansion) {
                this.props.globalState.onHighlightRequired.notifyObservers(this.props.parentExpansion.isComplete);
            }
        }

        this.updateHeaderText();

        if (this.collectionPage || this.state.stateIndex > 0) {
            this.prepareLists(this.props, this.state);
            this.forceUpdate();
            this.localChange = true;
        }

        if (this.dataGridRender.current) {
            this.dataGridRender.current.forceUpdate();
        }
    }

    updateDependentCards(c: ICard) {
        this.props.globalState.onCardUpdated.notifyObservers(c);

        if (c.otherCard) {
            this.props.globalState.onCardUpdated.notifyObservers(c.otherCard);
        }

        if (this.props.globalState.showReprintCount) {
            c.reprints.forEach((r) => {
                this.props.globalState.onCardUpdated.notifyObservers(r);
            });
        }
    }

    onAddToCollection(cards: ICard[]) {
        this.sliderChange = true;

        cards.forEach((c) => {
            c.count = Math.max(1, c.count);
            this.updateDependentCards(c);
        });

        this.onCollectionDataUpdated();
        Collection.RegisterSave();
    }

    onAddToActiveDeck(cards: ICard[]) {
        this.sliderChange = true;
        const decks = Collection.Decks.filter((d) => !d.Locked);

        const activeDeck = GlobalState.LoadSettings('ActiveDeck');
        const deckIndex = decks.map(d => d.UniqueID).indexOf(activeDeck);

        if (deckIndex === -1) {
            return;
        }

        const deck = decks[deckIndex];
        const targetIndex = GlobalState.LoadIntSettings('ActiveDeckTarget', 0);

        cards.forEach((c) => {
            const newValue = Deck.GetCurrentCount(deck, targetIndex, c.id) + 1;
            Deck.Update(deck, targetIndex, c.id, newValue);

            if (this.props.deck === deck) {
                c.entryCount = newValue;
            }
            this.props.globalState.onCardUpdated.notifyObservers(c);
            if (c.otherCard) {
                this.props.globalState.onCardUpdated.notifyObservers(c.otherCard);
            }
        });

        Collection.RegisterSave();
    }

    onRemoveOneFoilFinalFromCollection(cards: ICard[]) {
        cards.forEach((c) => {
            this.props.globalState.removeOneFoil(c);
        });

        this.sliderChange = true;
        this.onCollectionDataUpdated();
        Collection.RegisterSave();
    }

    onRemoveOneFoilFromCollection(cards: ICard[]) {
        const needConfirmation = cards.some((c) => c.count === 1);
        if (needConfirmation && GlobalState.LoadBoolSettings('WhenRemovingFromCollection', false)) {
            this.props.globalState.showConfirmDialog(this.props.globalState.translate('RemoveFromCollectionConfirm' + (cards.length > 1 ? 'Plural' : '')), this.props.globalState.translate('Yes'), this.props.globalState.translate('No')).then(value => {
                if (!value) {
                    return;
                }
                this.onRemoveOneFoilFinalFromCollection(cards);
            });
            return;
        }

        this.onRemoveOneFoilFinalFromCollection(cards);
    }

    onRemoveOneSpecialFoilFromCollection(cards: ICard[]) {
        const needConfirmation = cards.some((c) => c.count === 1);
        if (needConfirmation && GlobalState.LoadBoolSettings('WhenRemovingFromCollection', false)) {
            this.props.globalState.showConfirmDialog(this.props.globalState.translate('RemoveFromCollectionConfirm' + (cards.length > 1 ? 'Plural' : '')), this.props.globalState.translate('Yes'), this.props.globalState.translate('No')).then(value => {
                if (!value) {
                    return;
                }
                this.onRemoveOneSpecialFoilFinalFromCollection(cards);
            });
            return;
        }

        this.onRemoveOneSpecialFoilFinalFromCollection(cards);
    }

    onRemoveOneSpecialFoilFinalFromCollection(cards: ICard[]) {
        cards.forEach((c) => {
            this.props.globalState.removeOneSpecialFoil(c);
        });

        this.sliderChange = true;
        this.onCollectionDataUpdated();
        Collection.RegisterSave();
    }

    onRemoveOneFinalFromCollection(cards: ICard[]) {
        this.sliderChange = true;

        cards.forEach((c) => {
            c.count = Math.max(0, c.count - 1);

            this.updateDependentCards(c);
        });

        this.onCollectionDataUpdated();
        Collection.RegisterSave();
    }

    onRemoveOneFromCollection(cards: ICard[]) {
        const needConfirmation = cards.some((c) => c.count === 1);
        if (needConfirmation && GlobalState.LoadBoolSettings('WhenRemovingFromCollection', false)) {
            this.props.globalState.showConfirmDialog(this.props.globalState.translate('RemoveFromCollectionConfirm' + (cards.length > 1 ? 'Plural' : '')), 'Yes', 'No').then(value => {
                if (!value) {
                    return;
                }
                this.onRemoveOneFinalFromCollection(cards);
            });
            return;
        }

        this.onRemoveOneFinalFromCollection(cards);
    }

    onRemoveFromCollectionFinal(cards: ICard[]) {
        this.sliderChange = true;

        cards.forEach((c) => {
            c.count = 0;

            this.updateDependentCards(c);
        });

        this.onCollectionDataUpdated();
        Collection.RegisterSave();
    }

    onRemoveFromCollection(cards: ICard[]) {
        if (GlobalState.LoadBoolSettings('WhenRemovingFromCollection', false)) {
            this.props.globalState.showConfirmDialog(this.props.globalState.translate('RemoveFromCollectionConfirm' + (cards.length > 1 ? 'Plural' : '')), this.props.globalState.translate('Yes'), this.props.globalState.translate('No')).then(value => {
                if (!value) {
                    return;
                }
                this.onRemoveFromCollectionFinal(cards);
            });
            return;
        }

        this.onRemoveFromCollectionFinal(cards);
    }

    changeIcon(cards: ICard[]) {
        this.props.globalState.showCardIconPicker(cards[0].icon).then((value) => {
            if (value.icon !== undefined) {
                for (const card of cards) {
                    card.icon = value.icon;
                    this.props.globalState.onSelectedCardChanged.notifyObservers(card);
                    if (card.otherCard) {
                        this.props.globalState.onSelectedCardChanged.notifyObservers(card.otherCard);
                    }
                }

                // Refresh
                if (this.dataGridRender.current) {
                    this.dataGridRender.current.forceUpdate();
                }

                Collection.RegisterSave();
            }
        });
    }

    specifics(card: ICard) {
        if (!card.count) {
            this.props.globalState.onError.notifyObservers(this.props.globalState.translate('NeedToAddCardToCollectionFirst'));
            return;
        }
        this.props.globalState.onCardDataRequired.notifyObservers(card as Card);
    }

    changeProxyState(cards: ICard[], state: boolean) {
        for (const card of cards) {
            card.isProxy = state;
        }

        // Refresh
        this.prepareLists(this.props, this.state);
        this.forceUpdate();

        if (this.dataGridRender.current) {
            this.dataGridRender.current.forceUpdate();
        }

        Collection.RegisterSave();
    }

    changeDeckProxyState(cards: ICard[], state: boolean) {
        const deck = this.props.deck!;

        let entries: DeckEntry[] = [];

        switch (this.deckPartIndex) {
            case 0:
                entries = deck.Cards.filter((e) => cards.some((c) => c.id === e.CardID));
                break;
            case 1:
                entries = deck.Sideboard.filter((e) => cards.some((c) => c.id === e.CardID));
                break;
            case 2:
                entries = deck.Reserve.filter((e) => cards.some((c) => c.id === e.CardID));
                break;
        }

        for (const entry of entries) {
            entry.isProxy = state;
        }

        for (const card of cards) {
            (card as DeckCard).isDeckProxy = state;
        }

        // Refresh
        this.prepareLists(this.props, this.state);
        this.forceUpdate();

        if (this.dataGridRender.current) {
            this.dataGridRender.current.forceUpdate();
        }

        Collection.RegisterSave();
    }

    swapReprints(card: ICard, deckPartIndex: number) {
        this.props.globalState.showCardImagePicker(card.reprints, false)
            .then((response) => {
                if (!response || !response.card) {
                    return;
                }
                const deck = this.props.deck!;
                const newCard = response.card;

                let cards: DeckEntry[];

                switch (deckPartIndex) {
                    case 1:
                        cards = deck.Sideboard;
                        break;
                    case 2:
                        cards = deck.Reserve;
                        break;
                    case 0:
                    default:
                        cards = deck.Cards;
                        break;
                }

                const deckEntry = cards.filter((de) => de.CardID === card.id)[0];

                deckEntry.CardID = newCard.id;

                // Refresh
                this.prepareLists(this.props, this.state);
                this.forceUpdate();

                if (this.dataGridRender.current) {
                    this.dataGridRender.current.forceUpdate();
                }

                Collection.RegisterSave();
            });
    }

    onCollection(cards: ICard[]) {
        this.sliderChange = true;

        let maxCount = 0;

        cards.forEach((c) => {
            maxCount = Math.max(maxCount, c.count);
        });

        this.props.globalState
            .showCountDialog(this.props.globalState.translate('Collection_'), maxCount)
            .then((value) => {
                cards.forEach((c) => {
                    c.count = value;

                    this.updateDependentCards(c);
                });

                this.onCollectionDataUpdated();
                Collection.RegisterSave();
            });
    }

    onFoil(cards: ICard[]) {
        this.sliderChange = true;
        let maxCount = 0;

        const currentFoilCounts: number[] = [];
        cards.forEach((c) => {
            currentFoilCounts.push(c.foilCount);
            maxCount = Math.max(maxCount, c.foilCount);
        });

        this.props.globalState
            .showCountDialog(this.props.globalState.translate('NumberOfFoils'), maxCount)
            .then((value) => {
                cards.forEach((c, i) => {
                    c.foilCount = value;
                    c.count += value - currentFoilCounts[i];

                    this.updateDependentCards(c);
                });

                this.onCollectionDataUpdated();

                Collection.RegisterSave();
            });
    }

    onSpecialFoil(cards: ICard[]) {
        this.sliderChange = true;
        let maxCount = 0;

        const currentSpecialFoilCounts: number[] = [];
        cards.forEach((c) => {
            currentSpecialFoilCounts.push(c.specialFoilCount);
            maxCount = Math.max(maxCount, c.specialFoilCount);
        });

        this.props.globalState
            .showCountDialog(this.props.globalState.translate('NumberOfSpecialFoils'), maxCount)
            .then((value) => {
                cards.forEach((c, i) => {
                    c.specialFoilCount = value;
                    c.count += value - currentSpecialFoilCounts[i];

                    this.updateDependentCards(c);
                });

                this.onCollectionDataUpdated();

                Collection.RegisterSave();
            });
    }

    onMoveInsideDeck(cards: ICard[]) {
        const deck = this.props.deck!;

        let entries: DeckEntry[] = [];

        switch (this.deckPartIndex) {
            case 0:
                entries = deck.Cards.filter((e) => cards.some((c) => c.id === e.CardID));
                break;
            case 1:
                entries = deck.Sideboard.filter((e) => cards.some((c) => c.id === e.CardID));
                break;
            case 2:
                entries = deck.Reserve.filter((e) => cards.some((c) => c.id === e.CardID));
                break;
        }

        this.props.globalState
            .showMoveInsideDeckDialog(this.props.globalState.translate('MoveTo'), entries, deck)
            .then((value) => {
                if (!value) {
                    return;
                }

                this.prepareLists(this.props, this.state);
                this.selectedCards = [];
                this.props.globalState.onCollectionCommandRequired.notifyObservers(false);
                this.props.globalState.onDeckCommandRequired.notifyObservers(false);

                this.forceUpdate();

                if (this.dataGridRender.current) {
                    this.dataGridRender.current.forceUpdate();
                }

                Collection.RegisterSave();
            });
    }

    onRemoveFromDeck(cards: ICard[]) {
        const deck = this.props.deck!;
        cards.forEach((c) => {
            const cardId = c.id;

            c.entryCount = 0;

            Deck.Update(deck, this.deckPartIndex, cardId, 0);

            const index = this.cards.indexOf(c);
            if (index > -1) {
                this.cards.splice(index, 1);
            }
            this.props.globalState.onCardUpdated.notifyObservers(c);
            if (c.otherCard) {
                this.props.globalState.onCardUpdated.notifyObservers(c.otherCard);
            }
        });

        this.prepareLists(this.props, this.state);
        this.selectedCards = this.selectedCards.filter((c) => c.entryCount && c.entryCount > 0);
        if (this.selectedCards.length === 0) {
            this.props.globalState.onCollectionCommandRequired.notifyObservers(false);
            this.props.globalState.onDeckCommandRequired.notifyObservers(false);
        } else {
            this.localChange = true;
        }
        this.forceUpdate();

        if (this.dataGridRender.current) {
            this.dataGridRender.current.forceUpdate();
        }

        Collection.RegisterSave();
    }

    onDeck(cards: ICard[]) {
        this.sliderChange = true;

        const deck = this.props.deck;

        const card = cards[0];

        const filter = (c: DeckEntry) => {
            return c.CardID === card.id || c.CardID === Collection.LinkedCardsIndex[card.id];
        };
        const deckCount = deck ? CountTools.Sum(deck.Cards.filter(filter).map((c) => c.EntryCount)) : 0;
        const sideboardCount = deck ? CountTools.Sum(deck.Sideboard.filter(filter).map((c) => c.EntryCount)) : 0;
        const reserveCount = deck ? CountTools.Sum(deck.Reserve.filter(filter).map((c) => c.EntryCount)) : 0;

        this.props.globalState
            .showDeckCountDialog(
                this.props.globalState.translate('NumberOfCopies'),
                card,
                deck || null,
                deckCount,
                sideboardCount,
                reserveCount
            )
            .then((value) => {
                cards.forEach((c) => {
                    const cardId = c.id;

                    Deck.Update(value.deck, 0, cardId, value.deckValue);
                    Deck.Update(value.deck, 1, cardId, value.sideboardValue);
                    Deck.Update(value.deck, 2, cardId, value.reserveValue);

                    if (value.deck === deck) {
                        switch (this.deckPartIndex) {
                            case 0:
                                c.entryCount = value.deckValue;
                                break;
                            case 1:
                                c.entryCount = value.sideboardValue;
                                break;
                            case 2:
                                c.entryCount = value.reserveValue;
                                break;
                        }

                        if (c.entryCount === 0) {
                            const index = this.cards.indexOf(c);
                            if (index > -1) {
                                this.cards.splice(index, 1);
                            }
                        }
                        this.props.globalState.onCardUpdated.notifyObservers(c);
                        if (c.otherCard) {
                            this.props.globalState.onCardUpdated.notifyObservers(c.otherCard);
                        }
                    } else if (!this.props.deck) {
                        this.props.globalState.onCardUpdated.notifyObservers(c);
                        if (c.otherCard) {
                            this.props.globalState.onCardUpdated.notifyObservers(c.otherCard);
                        }
                    }
                });

                if (value.deck === deck && this.selectedCards) {
                    this.prepareLists(this.props, this.state);
                    this.selectedCards = this.selectedCards.filter((c) => c.entryCount && c.entryCount > 0);
                    if (this.selectedCards.length === 0) {
                        this.props.globalState.onCollectionCommandRequired.notifyObservers(false);
                        this.props.globalState.onDeckCommandRequired.notifyObservers(false);
                    } else {
                        this.localChange = true;
                    }
                    this.forceUpdate();

                    if (this.dataGridRender.current) {
                        this.dataGridRender.current.forceUpdate();
                    }
                } else {
                    this.props.globalState.onMessage.notifyObservers(
                        this.props.globalState.translate('DeckSuccessful')
                    );
                }

                Collection.RegisterSave();
            });
    }

    unSelectAll(signal = true) {
        this.selectedCards.slice(0).forEach((c) => {
            c.isSelected = false;
            this.props.globalState.onSelectedCardChanged.notifyObservers(c);
        });

        this.selectedCards = [];

        if (signal) {
            this.props.globalState.onCollectionCommandRequired.notifyObservers(false);
            this.props.globalState.onDeckCommandRequired.notifyObservers(false);
        }

        if (this.dataGridRender.current) {
            this.dataGridRender.current.unselect(false);
        }

        this.buildCustomActions();
    }

    UNSAFE_componentWillMount() {
        this.onSelectedMenuIndexChangedObserver = this.props.globalState.onSelectedMenuIndexChanged.add(() => {
            this.selectionMode = false;
        });

        this.onImageWidthChangedObserver = this.props.globalState.onImageWidthChanged.add((value) => {
            this.localChange = false;
            this.sliderChange = true;
            this.setState({ imageWidth: value });
        });

        this.onContextMenuCommandObserver = this.props.globalState.onContextMenuCommand.add((command) => {
            if (this.state.datagridMode) {
                return;
            }

            switch (command.command) {
                case 'selectAll':
                    this.selectAll();
                    break;
            }
        });

        this.onCollectionModeObserver = this.props.globalState.onCollectionMode.add((value) => {
            this.collectionMode = value;
            this.props.globalState.storeBoolSettings('CollectionMode', this.collectionMode);
            if (this.state.datagridMode) {
                this.forceUpdate();
            }
        });

        this.onSelectModeObserver = this.props.globalState.onSelectMode.add((value) => {
            this.selectionMode = value;

            if (!value) {
                this.unSelectAll();
            }
        });

        this.onSelectedCardChangedObserver = this.props.globalState.onSelectedCardChanged.add((card) => {
            if (card.isSelected) {
                if (this.selectedCards.indexOf(card) === -1) {
                    this.selectedCards.push(card);
                }
            } else {
                const index = this.selectedCards.indexOf(card);

                if (index > -1) {
                    this.selectedCards.splice(index, 1);
                }
            }
        });

        this.onCollectionCommandActivatedObserver = this.props.globalState.onCollectionCommandActivated.add(() => {
            this.onCollection(this.selectedCards);
        });

        this.onStatisticsObserver = this.props.globalState.onStatistics.add(() => {
            const deck = this.props.deck;

            this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
                index: -7,
                uniqueString: deck!.UniqueID
            });
        });

        this.onDeckCommandActivatedObserver = this.props.globalState.onDeckCommandActivated.add(() => {
            this.onDeck(this.selectedCards);
        });

        this.onSimulatorObserver = this.props.globalState.onSimulator.add(() => {
            const deck = this.props.deck;

            this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
                index: -8,
                uniqueString: deck!.UniqueID
            });
        });

        this.onForcePageRefreshObserver = this.props.globalState.onForcePageRefresh.add(() => {
            this.forceUpdate();
        });
    }

    shouldComponentUpdate(nextProps: ICardsPageProps, nextState: ICardsPageState) {
        if (this.props.deck !== nextProps.deck) {
            this.sliderChange = false;
            return true;
        }
        if (this.props.searchData !== nextProps.searchData) {
            this.sliderChange = false;
            return true;
        }
        if (this.props.parentExpansion !== nextProps.parentExpansion) {
            this.sliderChange = false;
            return true;
        }

        if (JSON.stringify(this.state) === JSON.stringify(nextState)) {
            if (
                this.props.deck === nextProps.deck &&
                this.props.parentExpansion === nextProps.parentExpansion &&
                this.props.searchData === nextProps.searchData
            ) {
                return false;
            }
        }
        return true;
    }

    componentWillUnmount() {
        this.props.globalState.onSelectedMenuIndexChanged.remove(this.onSelectedMenuIndexChangedObserver);
        this.props.globalState.onContextMenuCommand.remove(this.onContextMenuCommandObserver);
        this.props.globalState.onImageWidthChanged.remove(this.onImageWidthChangedObserver);
        this.props.globalState.onSelectMode.remove(this.onSelectModeObserver);
        this.props.globalState.onSelectedCardChanged.remove(this.onSelectedCardChangedObserver);
        this.props.globalState.onCollectionMode.remove(this.onCollectionModeObserver);
        this.props.globalState.onCollectionCommandActivated.remove(this.onCollectionCommandActivatedObserver);
        this.props.globalState.onDeckCommandActivated.remove(this.onDeckCommandActivatedObserver);
        this.props.globalState.onStatistics.remove(this.onStatisticsObserver);
        this.props.globalState.onSimulator.remove(this.onSimulatorObserver);
        this.props.globalState.onForcePageRefresh.remove(this.onForcePageRefreshObserver);

        window.removeEventListener('message', this.listener);
    }

    componentDidUpdate() {
        this.updateHeaderText();
        this.restoreScroll();

        this.buildCustomActions();
    }

    restoreScroll() {
        setTimeout(() => {
            const customData = this.props.globalState.pageCustomData;

            if (!customData || customData.scroll === undefined) {
                return;
            }

            const scrollPosition = customData && customData.scroll ? customData.scroll : 0;

            const scroller = document.getElementById('scrollElement')!;
            scroller.scrollTop = scrollPosition;
        }, 15);
    }

    updateHeaderText() {
        const translateWithPlural = this.props.globalState.translateWithPlural.bind(this.props.globalState);
        const translate = this.props.globalState.translate.bind(this.props.globalState);
        let headerText = '';

        if (this.props.parentExpansion) {
            let collectionCount = 0;
            let totalCardCount = 0;
            let totalPrice = 0;
            let collectionPrice = 0;
            let userCount = 0;

            this.cards.forEach((c) => {
                if (c.isBackFace && this.cards.indexOf(c.otherCard) !== -1) {
                    return;
                }
                totalPrice += c.price;
                totalCardCount += 1;
                userCount += c.count;

                if (c.count > 0) {
                    collectionCount += 1;
                    collectionPrice += c.collectionValue;
                }
            });

            const value = PriceTools.Format(totalPrice, this.props.globalState);
            const collectionValue = PriceTools.Format(collectionPrice, this.props.globalState);

            if (collectionCount === totalCardCount) {
                headerText = `${totalCardCount} (${userCount}) ${translateWithPlural(
                    'cards',
                    totalCardCount
                )} - ${collectionValue}`;
            } else {
                headerText = `${collectionCount} (${userCount}) / ${totalCardCount} ${translateWithPlural(
                    'cards',
                    totalCardCount
                )} - ${collectionValue} / ${value}`;
            }
        } else if (this.props.deck) {
            let totalCardCount = 0;
            let totalPrice = 0;
            let entryPrice = 0;
            let entryCount = 0;

            this.cards.forEach((c) => {
                if (c.isBackFace && this.cards.indexOf(c.otherCard) !== -1) {
                    return;
                }
                totalCardCount += c.count;
                totalPrice += this.props.searchData ? c.price : c.collectionValue;
                entryCount += (c as DeckCard).entryCount;
                entryPrice += (c as DeckCard).entryCount * c.price;
            });

            const value = PriceTools.Format(totalPrice, this.props.globalState);
            const entryValue = PriceTools.Format(entryPrice, this.props.globalState);

            headerText = `${translate('Deck_')} ${entryCount} ${translateWithPlural(
                'cards',
                totalCardCount
            )} - ${entryValue} / ${translate('Collection')} ${totalCardCount} ${translateWithPlural(
                'cards',
                totalCardCount
            )} - ${value}`;
        } else {
            let totalCardCount = 0;
            let totalPrice = 0;
            let total = 0;
            let userCount = 0;
            this.cards.forEach((c) => {
                if (c.isBackFace && this.cards.indexOf(c.otherCard) !== -1) {
                    return;
                }
                totalPrice += this.props.searchData ? c.price : c.collectionValue;
                totalCardCount += c.count;
                userCount += c.count ? 1 : 0;
                total++;
            });

            const value = PriceTools.Format(totalPrice, this.props.globalState);

            if (this.props.searchData) {
                headerText = `${userCount} (${totalCardCount}) / ${total} ${translateWithPlural(
                    'cards',
                    totalCardCount
                )} - ${value}`;
            } else {
                headerText = `${totalCardCount} ${translateWithPlural('cards', totalCardCount)} - ${value}`;
            }
        }

        this.props.globalState.onHeaderTextChanged.notifyObservers(headerText);
    }

    navigateToCard(card: ICard) {
        if (this.selectionMode) {
            card.isSelected = !card.isSelected;
            this.props.globalState.onSelectedCardChanged.notifyObservers(card);

            this.buildCustomActions();

            if (!this.props.deck) {
                this.props.globalState.onCollectionCommandRequired.notifyObservers(this.selectedCards.length > 0);
            }
            this.props.globalState.onDeckCommandRequired.notifyObservers(this.selectedCards.length > 0);
            return;
        }
        this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
            index: -4,
            uniqueID: this.cards.indexOf(card),
            cardIds: this.cards.map((c) => c.id)
        });
    }

    onOrdered(cards: ICard[]) {
        cards.forEach((card) => {
            if (!card.isBackFace || (card.isBackFace && cards.indexOf(card.otherCard) === -1)) {
                card.isOrdered = !card.isOrdered;
                this.props.globalState.onSelectedCardChanged.notifyObservers(card);
                if (card.otherCard) {
                    this.props.globalState.onSelectedCardChanged.notifyObservers(card.otherCard);
                }
            }
        });
        Collection.RegisterSave();

        if (this.dataGridRender.current) {
            this.dataGridRender.current.forceUpdate();
        }
    }

    onTags(cards: ICard[]) {
        this.sliderChange = true;

        this.props.globalState.onTagUpdated.addOnce((data) => {
            if (data.cancelled) {
                return;
            }
            this.prepareLists(this.props, this.state);
            if (this.state.tagIndex) {
                this.setState({ tagIndex: 0 });
            } else {
                this.forceUpdate();
            }
            this.localChange = true;

            if (this.dataGridRender.current) {
                this.dataGridRender.current.forceUpdate();
            }
        });
        this.props.globalState.onTagRequired.notifyObservers(cards);
    }

    renderElement(card: ICard, width?: number, height?: number): React.ReactElement<any> {
        const style: any = {};

        if (width && height) {
            style.width = (width * 0.91) + 'px';
            style.height = (height * 0.91) + 'px';
        }

        return (
            <CardCard
                key={card.id}
                forcedStyle={style}
                collectionMode={this.collectionMode}
                deck={this.props.deck}
                deckInfoMode={this.state.stateIndex}
                onSelect={(card) => {
                    if (!this.selectionMode) {
                        this.props.globalState.onSelectMode.notifyObservers(true);
                        this.props.globalState.onSelectModeActivationRequired.notifyObservers();
                    }

                    if (!this.selectedCards) {
                        this.selectedCards = [];
                    }

                    card.isSelected = true;
                    this.props.globalState.onSelectedCardChanged.notifyObservers(card);

                    if (!this.props.deck) {
                        this.props.globalState.onCollectionCommandRequired.notifyObservers(
                            this.selectedCards.length > 0
                        );
                    }
                    this.props.globalState.onDeckCommandRequired.notifyObservers(this.selectedCards.length > 0);
                    this.buildCustomActions();
                }}
                onCollectionStateUpdated={() => {
                    this.updateHeaderText();
                    this.onCollectionDataUpdated();
                }}
                onCollection={(card) => {
                    if (!this.selectionMode) {
                        this.onCollection([card]);
                        return;
                    }

                    card.isSelected = true;
                    this.props.globalState.onSelectedCardChanged.notifyObservers(card);

                    this.onCollection(this.selectedCards);
                }}
                onAddToCollection={(card) => {
                    if (!this.selectionMode) {
                        card.count = Math.min(GlobalState.MaxCardCount, card.count + 1);
                        this.onAddToCollection([card]);
                        return;
                    }

                    if (!card.isSelected) {
                        card.isSelected = true;
                        this.props.globalState.onSelectedCardChanged.notifyObservers(card);
                    }

                    this.selectedCards.forEach((c) => {
                        c.count = Math.min(GlobalState.MaxCardCount, c.count + 1);
                    });

                    this.onAddToCollection(this.selectedCards);
                }}
                onRemoveOneFromCollection={(card) => {
                    if (!this.selectionMode) {
                        this.onRemoveOneFromCollection([card]);
                        return;
                    }

                    if (!card.isSelected) {
                        card.isSelected = true;
                        this.props.globalState.onSelectedCardChanged.notifyObservers(card);
                    }

                    this.onRemoveOneFromCollection(this.selectedCards);
                }}
                onAddToActiveDeck={(card) => {
                    if (!this.selectionMode) {
                        this.onAddToActiveDeck([card]);
                        return;
                    }

                    if (!card.isSelected) {
                        card.isSelected = true;
                        this.props.globalState.onSelectedCardChanged.notifyObservers(card);
                    }

                    this.onAddToActiveDeck(this.selectedCards);
                }}
                onRemoveFromCollection={(card) => {
                    if (!this.selectionMode) {
                        this.onRemoveFromCollection([card]);
                        return;
                    }

                    if (!card.isSelected) {
                        card.isSelected = true;
                        this.props.globalState.onSelectedCardChanged.notifyObservers(card);
                    }

                    this.onRemoveFromCollection(this.selectedCards);
                }}
                onAddOneFoilToCollection={(card) => {
                    if (!this.selectionMode) {
                        this.props.globalState.addOneFoil(card);
                        this.sliderChange = true;
                        this.onCollectionDataUpdated();
                        Collection.RegisterSave();
                        return;
                    }

                    if (!card.isSelected) {
                        card.isSelected = true;
                        this.props.globalState.onSelectedCardChanged.notifyObservers(card);
                    }

                    this.selectedCards.forEach((c) => {
                        this.props.globalState.addOneFoil(c);
                    });

                    this.sliderChange = true;
                    this.onCollectionDataUpdated();
                    Collection.RegisterSave();
                }}
                onRemoveOneFoilFromCollection={(card) => {
                    if (!this.selectionMode) {
                        this.onRemoveOneFoilFromCollection([card]);
                        return;
                    }

                    if (!card.isSelected) {
                        card.isSelected = true;
                        this.props.globalState.onSelectedCardChanged.notifyObservers(card);
                    }

                    this.onRemoveOneFoilFromCollection(this.selectedCards);
                }}
                onFoil={(card) => {
                    if (!this.selectionMode) {
                        this.onFoil([card]);
                        return;
                    }

                    card.isSelected = true;
                    this.props.globalState.onSelectedCardChanged.notifyObservers(card);

                    this.onFoil(this.selectedCards);
                }}
                onSpecialFoil={(card) => {
                    if (!this.selectionMode) {
                        this.onSpecialFoil([card]);
                        return;
                    }

                    card.isSelected = true;
                    this.props.globalState.onSelectedCardChanged.notifyObservers(card);

                    this.onSpecialFoil(this.selectedCards);
                }}
                onCommander={(card) => {
                    if (!this.selectionMode) {
                        this.swapCommanderState(card);
                        return;
                    }

                    this.unSelectAll();
                    card.isSelected = true;
                    this.selectedCards = [card];
                    this.props.globalState.onSelectedCardChanged.notifyObservers(card);

                    this.swapCommanderState(card);
                }}
                onTags={(card) => {
                    if (!this.selectionMode) {
                        this.onTags([card]);
                        return;
                    }

                    card.isSelected = true;
                    this.props.globalState.onSelectedCardChanged.notifyObservers(card);

                    this.onTags(this.selectedCards);
                }}
                onDeck={(card) => {
                    if (!this.selectionMode) {
                        this.onDeck([card]);
                        return;
                    }

                    card.isSelected = true;
                    this.props.globalState.onSelectedCardChanged.notifyObservers(card);

                    this.onDeck(this.selectedCards);
                }}
                onRemoveFromDeck={(card) => {
                    if (!this.selectionMode) {
                        this.onRemoveFromDeck([card]);
                        return;
                    }

                    card.isSelected = true;
                    this.props.globalState.onSelectedCardChanged.notifyObservers(card);

                    this.onRemoveFromDeck(this.selectedCards);
                }}
                onMoveInsideDeck={(card) => {
                    if (!this.selectionMode) {
                        this.onMoveInsideDeck([card]);
                        return;
                    }

                    card.isSelected = true;
                    this.props.globalState.onSelectedCardChanged.notifyObservers(card);

                    this.onMoveInsideDeck(this.selectedCards);
                }}
                onChangeIcon={(card) => {
                    if (!this.selectionMode) {
                        this.changeIcon([card]);
                        return;
                    }

                    card.isSelected = true;
                    this.props.globalState.onSelectedCardChanged.notifyObservers(card);

                    this.changeIcon(this.selectedCards);
                }}
                onSpecifics={(card) => {
                    this.specifics(card);
                }}
                onChangeProxyState={(card, state) => {
                    if (!this.selectionMode) {
                        this.changeProxyState([card], state);
                        return;
                    }

                    card.isSelected = true;
                    this.props.globalState.onSelectedCardChanged.notifyObservers(card);

                    this.changeProxyState(this.selectedCards, state);
                }}
                onChangeDeckProxyState={(card, state) => {
                    if (!this.selectionMode) {
                        this.changeDeckProxyState([card], state);
                        return;
                    }

                    card.isSelected = true;
                    this.props.globalState.onSelectedCardChanged.notifyObservers(card);

                    this.changeDeckProxyState(this.selectedCards, state);
                }}
                onSwapReprint={(card) => {
                    if (!this.selectionMode) {
                        this.swapReprints(card, this.deckPartIndex);
                        return;
                    }

                    this.unSelectAll();
                    card.isSelected = true;
                    this.selectedCards = [card];
                    this.props.globalState.onSelectedCardChanged.notifyObservers(card);

                    this.swapReprints(card, this.deckPartIndex);
                }}
                onEDHRec={(card) => this.onEDHRec(card)}
                onEbay={(card) => {
                    let query = card.name + '+' + card.set.name;

                    query = query.replace(/\s/g, '+');

                    LinkTools.OpenLink(`https://www.ebay.com/sch/i.html?_nkw=${query}&_sop=15`);
                }}
                onMCM={async (card) => {
                    if (this.selectedCards.length > 1) {
                        this.props.globalState.onShoppingAssistantRequired.notifyObservers({ cards: this.selectedCards, urlGenerator: MagicCardMarket.GetLinkAsync });
                        return;
                    }

                    this.props.globalState.onWaitRingRequired.notifyObservers(true);
                    const link = await MagicCardMarket.GetLinkAsync(card);
                    LinkTools.OpenLink(link);
                    this.props.globalState.onWaitRingRequired.notifyObservers(false);
                }}
                onTCG={(card) => {
                    if (this.selectedCards.length > 1) {
                        this.props.globalState.onShoppingAssistantRequired.notifyObservers({ cards: this.selectedCards, urlGenerator: card => Promise.resolve(TCGPlayer.GetCardLink(card)) });
                        return;
                    }

                    LinkTools.OpenLink(TCGPlayer.GetCardLink(card));
                }}
                onScryfall={(card) => {
                    LinkTools.OpenLink(Scryfall.GetCardLink(card));
                }}
                onOrderedFlag={(card) => {
                    let cards: ICard[];

                    if (!this.selectionMode) {
                        cards = [card];
                    } else {
                        cards = this.selectedCards;
                    }

                    this.onOrdered(cards);
                }}
                selectionMode={this.selectionMode}
                globalState={this.props.globalState}
                card={card}
                onClick={(card) => this.navigateToCard(card)}
            />
        );
    }

    filter() {
        const filter = StringHelpers.NoAccentVersion(this.state.filter.toLowerCase());
        const colorID = this.state.colorIndex;
        const author = this.state.author;
        const rarityID = this.state.rarityIndex;
        const type = this.state.type;
        const format = this.state.format;
        const stateIndex = this.state.stateIndex;
        const expansionIndex = this.state.expansionIndex;
        const deckIndex = this.state.deckIndex;
        const tagIndex = this.state.tagIndex;
        const minPrice = this.state.price[0];
        const maxPrice = this.state.price[1];
        const minManacost = this.state.manacost[0];
        const maxManacost = this.state.manacost[1];
        const minPower = this.state.power[0];
        const maxPower = this.state.power[1];
        const minToughness = this.state.toughness[0];
        const maxToughness = this.state.toughness[1];
        const fullText = this.state.fullText;

        const checkPrice = minPrice !== this.minPrice || maxPrice !== this.maxPrice;
        const checkManacost = minManacost !== this.minManacost || maxManacost !== this.maxManacost;
        const checkPower = minPower !== this.minPower || maxPower !== this.maxPower;
        const checkToughness = minToughness !== this.minToughness || maxToughness !== this.maxToughness;

        if (
            !filter &&
            !colorID &&
            !author &&
            !rarityID &&
            !type &&
            !format &&
            !stateIndex &&
            !expansionIndex &&
            !deckIndex &&
            !tagIndex &&
            !fullText
        ) {
            if (!checkPrice && !checkManacost && !checkPower && !checkToughness) {
                return this.initialCardList.slice(0);
            }
        }

        let cardFormat: CardFormat;
        if (format) {
            cardFormat = CardFormat.GetFormatByName(format);
        }

        let expansions: Nullable<Set[]> = null;
        if (expansionIndex) {
            expansions = Collection.SortedSets;
        }

        let decks: Nullable<Deck[]> = null;
        if (deckIndex) {
            decks = Collection.Decks.sort((a, b) => a.Name.localeCompare(b.Name));
        }

        let tag: Nullable<string> = null;
        if (tagIndex) {
            tag = this.tags[tagIndex - 1];
        }

        return this.initialCardList.filter((c) => {
            if (filter && StringHelpers.NoAccentVersion(c.nameForSearch).indexOf(filter) === -1) {
                return false;
            }

            if (stateIndex) {
                if (this.props.deck) {
                    if (stateIndex === 1) {
                        const deckCard = c as DeckCard;
                        if (deckCard.entryCount <= deckCard.count) {
                            return false;
                        }
                    } else if (stateIndex === 2) {
                        const deckCard = c as DeckCard;
                        if (deckCard.count === 0) {
                            return false;
                        }
                    } else if (stateIndex === 4) {
                        const deckCard = c as DeckCard;
                        if (!deckCard.isOrdered) {
                            return false;
                        }
                    } else if (stateIndex === 5) {
                        const deckCard = c as DeckCard;
                        if (!ReservedList.IsReserved(deckCard)) {
                            return false;
                        }
                    }
                } else if (stateIndex === 1 && c.count > 0) {
                    return false;
                } else if (stateIndex === 2 && c.count === 0) {
                    return false;
                } else if (stateIndex === 3 && (c.count > 0 || c.isOrdered)) {
                    return false;
                } else if (stateIndex === 4 && !c.isOrdered) {
                    return false;
                } else if (stateIndex === 5 && !ReservedList.IsReserved(c)) {
                    return false;
                }
            }

            if (colorID && c.colorId !== colorID) {
                return false;
            }

            if (author && c.author !== author) {
                return false;
            }

            if (rarityID && c.rarityId !== rarityID) {
                return false;
            }

            if (type) {
                let superTypeFound = false;
                for (const superType of CardSuperType.SuperTypes) {
                    if (type === superType.name) {
                        superTypeFound = true;
                        if (!superType.predicate(c)) {
                            return false;
                        }
                        break;
                    }
                }
                if (!superTypeFound && c.type !== type) {
                    return false;
                }
            }

            if (cardFormat) {
                if (!cardFormat.predicate!(c)) {
                    return false;
                }
            }

            if (expansions) {
                if (expansionIndex > 0 && c.set !== this.sets[expansionIndex - 1]) {
                    return false;
                }
            }

            if (deckIndex === '-1') {
                if (c.getDeckString(this.props.globalState) !== '') {
                    return false;
                }
            } else if (decks) {
                const deck = decks.filter(d => d.UniqueID === deckIndex)[0];
                if (deck && !Deck.Some(deck, (entry) => entry.CardID === c.id)) {
                    return false;
                }
            }

            if (checkPrice) {
                const price = c.foilCount > 0 && GlobalState.UseFoilPriceWhenOwned ? c.foilPrice : c.price;
                if (price < minPrice || price > maxPrice) {
                    return false;
                }
            }

            if (checkManacost) {
                if (c.convertedManaCost < minManacost || c.convertedManaCost > maxManacost) {
                    return false;
                }
            }

            if (checkPower && c.force !== null) {
                if (c.force < minPower || c.force > maxPower) {
                    return false;
                }
            }

            if (checkToughness && c.defense !== null) {
                if (c.defense < minToughness || c.defense > maxToughness) {
                    return false;
                }
            }

            if (tag) {
                if (c.tags.indexOf(tag) === -1) {
                    return false;
                }
            }

            if (fullText && fullText.length) {
                const lowerCaseFullText = fullText.toLowerCase();
                if (c.nameForSearch.indexOf(lowerCaseFullText) === -1 &&
                    c.textForSearch.indexOf(lowerCaseFullText) === -1 &&
                    c.typeForSearch.indexOf(lowerCaseFullText) === -1 &&
                    c.flavorForSearch.indexOf(lowerCaseFullText) === -1
                    ) {
                    return false;
                }
            }

            return true;
        });
    }

    blurFilter(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.which === 13) {
            (evt.nativeEvent.srcElement as HTMLInputElement).blur();
        }
    }

    sort() {
        SortManager.Sort(this.cards, this.state.sortIndex, this.props.globalState);
    }

    prepareData() {
        this.unSelectAll(false);
        this.cards = this.filter();

        if (!this.state.datagridMode) {
            this.sort();
        }
    }

    filterUpdated() {
        this.localChange = true;
        this.props.globalState.pageCustomData.scroll = 0;
    }

    pickDeckTab(index: number) {
        if (this.deckPartIndex === index) {
            return;
        }
        this.deckPartIndex = index;
        this.prepareLists(this.props, this.state);

        this.resetState();
        const customData = this.props.globalState.pageCustomData;
        customData.tabIndex = index;
    }

    onContextMenu(evt: React.MouseEvent<HTMLDivElement>, card: ICard) {
        evt.preventDefault();

        let top = evt.pageY;
        let left = evt.pageX;

        left = Math.min(window.innerWidth - 260, left);
        top = Math.min(window.innerHeight - 620, top);

        this.props.globalState.onContextMenuRequired.notifyObservers({
            left: left,
            top: top,
            card: card,
            deck: this.props.deck
        });
    }

    onEDHRec(card: ICard) {
        LinkTools.OpenLink('https://edhrec.com/commanders/' + card.nameEn.toLowerCase().replace(/\s/g, '-').replace(',', ''));
    }

    swapCommanderState(card: ICard) {
        card.isDeckCommander = !card.isDeckCommander;
        Deck.UpdateCommanderStatus(this.props.deck!, this.deckPartIndex, card.id, card.isDeckCommander);
        this.props.globalState.onCardUpdated.notifyObservers(card);
        this.buildCustomActions();

        Collection.RegisterSave();
    }

    killTooltipImage() {
        if (this.cardTooltipImage && this.cardTooltipImage.parentElement) {
            this.cardTooltipImage.parentElement!.removeChild(this.cardTooltipImage);
            this.cardTooltipImage = null;
        }

        if (this.timeoutId === -1) {
            return;
        }

        clearTimeout(this.timeoutId);

        this.timeoutId = -1;
    }

    renderDeckAsGrouped(width: number, height: number) {
        const deck = this.props.deck;

        const groups: GroupData[] = [];
        const types = ['Lands', 'Creatures', 'Instants', 'Enchantments', 'Sorceries', 'Planeswalkers', 'Others'];

        for (const type of types) {
            const group = new GroupData();
            group.host = deck;
            group.getTitle = () => `${type}`;
            group.name = type;

            let predicate: (card: ICard) => boolean;

            switch (types.indexOf(type)) {
                case 0:
                    predicate = (card: ICard) => {
                        return card.isLand;
                    };
                    break;
                case 1:
                    predicate = (card: ICard) => {
                        return card.isCreature;
                    };
                    break;
                case 2:
                    predicate = (card: ICard) => {
                        return card.isInstant;
                    };
                    break;
                case 3:
                    predicate = (card: ICard) => {
                        return card.isEnchantment;
                    };
                    break;
                case 4:
                    predicate = (card: ICard) => {
                        return card.isSorcery;
                    };
                    break;
                case 5:
                    predicate = (card: ICard) => {
                        return card.isPlaneswalker;
                    };
                    break;
                case 6:
                    predicate = (card: ICard) => {
                        return (
                            !card.isLand &&
                            !card.isPlaneswalker &&
                            !card.isCreature &&
                            !card.isInstant &&
                            !card.isEnchantment &&
                            !card.isSorcery
                        );
                    };
                    break;
            }

            const cards = this.cards.filter(c => predicate(c));

            if (cards.length === 0) {
                continue;
            }

            group.items = cards;

            groups.push(group);
        }

        return (
            <div className='cards-grouped-list' id="scrollElement">
                <GroupedList
                    canEditGroupTitles={false}
                    globalState={this.props.globalState}
                    groups={groups}
                    onRenderGroupItem={(group, item) => {
                        const card = item as ICard;
                        return this.renderElement(card, width, height);
                    }}
                    storeKey={'Deck'}
                />
            </div>
        );
    }

    renderCards(width: number, height: number) {
        if (!this.state.datagridMode && this.props.deck && this.props.globalState.deckAsGroupList) {
            return this.renderDeckAsGrouped(width, height);
        }

        if (this.state.datagridMode) {
            const translate = this.props.globalState.translate.bind(this.props.globalState);

            const columns: IColumn[] = [
                { key: 'number', label: translate('Number'), type: DataGridHeaderTypes.int },
                {
                    key: 'name',
                    label: translate('Name_'),
                    type: DataGridHeaderTypes.string,
                    customRender: (row: any, width: string, addClass: string) => {
                        const card = row as ICard;
                        return (
                            <div
                                className={'datagrid-cell ' + addClass}
                                key={addClass}
                                onMouseOut={() => {
                                    this.killTooltipImage();
                                }}
                                onMouseOver={(evt: React.MouseEvent<HTMLDivElement>) => {
                                    if (MobileTools.IsMobile) {
                                        return;
                                    }
                                    if (this.timeoutId > -1) {
                                        return;
                                    }

                                    const tracker = evt.target as HTMLDivElement;

                                    this.timeoutId = window.setTimeout(() => {
                                        this.cardTooltipImage = document.createElement('img');
                                        this.cardTooltipImage.style.position = 'absolute';
                                        this.cardTooltipImage.style.left = '0';
                                        this.cardTooltipImage.style.top = '-300px';
                                        this.cardTooltipImage.style.zIndex = '10';
                                        this.cardTooltipImage.style.height = '300px';

                                        if (tracker.getBoundingClientRect().top < window.innerHeight - 400) {
                                            this.cardTooltipImage.style.top = '34px';
                                        }

                                        this.cardTooltipImage.src =
                                            'https://collectocors.azurewebsites.net/Mid/' + card.picturePath;

                                        if (tracker.parentElement && tracker.parentElement.parentElement) {
                                            tracker.parentElement.parentElement.appendChild(this.cardTooltipImage);
                                        }
                                    }, 500);
                                }}
                                style={{
                                    cursor: 'help',
                                    width: width,
                                    fontWeight: card.isDeckCommander ? 'bold' : 'normal',
                                    textTransform: card.isDeckCommander ? 'uppercase' : 'none'
                                }}
                            >
                                {card.name}
                            </div>
                        );
                    }
                },
                {
                    key: 'color',
                    label: translate('Color_'),
                    type: DataGridHeaderTypes.string,
                    sizeOffset: 35,
                    customRender: (row: any, width: string, addClass: string) => {
                        const card = row as ICard;
                        const color = CardColor.GetColorById(card.colorId);
                        return (
                            <div
                                className={'datagrid-cell-set ' + addClass}
                                key={addClass}
                                style={{
                                    width: width,
                                    cursor: 'default'
                                }}
                            >
                                <div className="datagrid-icon noBackground">
                                    <img src={'/images/symbols/' + color.key + '.png'} />
                                </div>
                                <div className={'datagrid-label'}>{color.name}</div>
                            </div>
                        );
                    }
                },
                { key: 'type', label: translate('Type_'), type: DataGridHeaderTypes.string },
                { key: 'power', label: translate('Power_'), type: DataGridHeaderTypes.string },
                {
                    key: 'setName',
                    label: translate('Expansion_'),
                    type: DataGridHeaderTypes.string
                },
                {
                    key: 'rarity',
                    label: translate('Rarity_'),
                    type: DataGridHeaderTypes.string
                },
                {
                    key: 'manaCost',
                    label: translate('Manacost_'),
                    type: DataGridHeaderTypes.string,
                    maxWidth: 150,
                    measure: (value: string, len: number) => {
                        const split = value.split('(');

                        if (split.length < 2) {
                            return len;
                        }

                        return (split.length - 1) * 24 + 15;
                    },
                    customRender: (row: any, width: string, addClass: string) => {
                        const card = row as ICard;
                        return (
                            <div
                                className={'datagrid-cell ' + addClass}
                                key={addClass}
                                style={{
                                    width: width
                                }}
                            >
                                {ManaTools.GenerateSymbols(card.manaCost)}
                            </div>
                        );
                    }
                },
                {
                    key: 'setLogo',
                    sortKey: 'setName',
                    label: translate('Expansions'),
                    type: DataGridHeaderTypes.string,
                    maxWidth: 150,
                    measure: (value: string, len: number) => {
                        const split = value.split('(');

                        if (split.length < 2) {
                            return len;
                        }

                        return (split.length - 1) * 24 + 15;
                    },
                    customRender: (row: any, width: string, addClass: string) => {
                        const card = row as ICard;
                        return (
                            <div
                                className={'datagrid-cell ' + addClass}
                                key={addClass}
                                style={{
                                    width: width
                                }}
                            >
                                <div className="card-element-logo-container">
                                    <img src={card.set.icon} className="card-element-logo" />
                                </div>
                            </div>
                        );
                    }
                },
                { key: 'count', label: translate('Collection_'), type: DataGridHeaderTypes.int },
                { key: 'foilCount', label: translate('Foils'), type: DataGridHeaderTypes.int },
                { key: 'specialFoilCount', label: translate('SpecialFoils'), type: DataGridHeaderTypes.int },
                { key: 'price', label: translate('AveragePrice'), type: DataGridHeaderTypes.price },
                { key: 'foilPrice', label: translate('AverageFoilPrice'), type: DataGridHeaderTypes.price },
                { key: 'author', label: translate('Author_'), type: DataGridHeaderTypes.string }
            ];

            if (this.collectionMode) {
                columns[9].minWidth = 80;
                columns[9].customRender = (row: any, width: string, addClass: string) => {
                    const card = row as ICard;
                    return (
                        <div
                            className={'datagrid-cell ' + addClass}
                            key={addClass}
                            style={{
                                width: width
                            }}
                        >
                            <div className="datagrid-cell-collection-mode">
                                <Button className='datagrid-cell-collection-plus' globalState={this.props.globalState} onClicked={() => {
                                    card.count = Math.min(GlobalState.MaxCardCount, card.count + 1);
                                    this.onAddToCollection([card]);
                                }}>+</Button>
                                <Button className='datagrid-cell-collection-minus' globalState={this.props.globalState} onClicked={() => {
                                    this.onRemoveOneFromCollection([card]);
                                } }>-</Button>
                                <div className='datagrid-cell-collection-mode-text'>{card.count}</div>
                            </div>
                        </div>
                    );
                };
                columns[10].minWidth = 80;
                columns[10].customRender = (row: any, width: string, addClass: string) => {
                    const card = row as ICard;
                    return (
                        <div
                            className={'datagrid-cell ' + addClass}
                            key={addClass}
                            style={{
                                width: width
                            }}
                        >
                            <div className="datagrid-cell-collection-mode">
                                {card.canBeFoil &&
                                <>
                                    <Button className='datagrid-cell-collection-plus' globalState={this.props.globalState} onClicked={() => {
                                        this.props.globalState.addOneFoil(card);
                                        this.onCollectionDataUpdated();
                                        Collection.RegisterSave();
                                    }}>+</Button>
                                    <Button className='datagrid-cell-collection-minus' globalState={this.props.globalState} onClicked={() => {
                                        if (card.foilCount > 0) {
                                            this.onRemoveOneFoilFromCollection([card]);
                                        }
                                        }}>-</Button>
                                </>}
                                <div className='datagrid-cell-collection-mode-text'>{card.foilCount}</div>
                            </div>
                        </div>
                    );
                };
                columns[11].minWidth = 80;
                columns[11].customRender = (row: any, width: string, addClass: string) => {
                    const card = row as ICard;
                    return (
                        <div
                            className={'datagrid-cell ' + addClass}
                            key={addClass}
                            style={{
                                width: width
                            }}
                        >
                            <div className="datagrid-cell-collection-mode">
                                {card.canBeSpecialFoil &&
                                <>
                                    <Button className='datagrid-cell-collection-plus' globalState={this.props.globalState} onClicked={() => {
                                        this.props.globalState.addOneSpecialFoil(card);
                                        this.onCollectionDataUpdated();
                                        Collection.RegisterSave();
                                    }}>+</Button>
                                    <Button className='datagrid-cell-collection-minus' globalState={this.props.globalState} onClicked={() => {
                                        this.onRemoveOneSpecialFoilFromCollection([card]);
                                        }}>-</Button>
                                </>}
                                <div className='datagrid-cell-collection-mode-text'>{card.specialFoilCount}</div>
                            </div>
                        </div>
                    );
                };
            } else {
                columns[9].minWidth = 0;
                columns[9].customRender = undefined;
                columns[10].minWidth = 0;
                columns[10].customRender = undefined;
                columns[11].minWidth = 0;
                columns[11].customRender = undefined;
            }

            if (this.props.deck) {
                columns[0].key = 'entryCount';
                columns[0].label = translate('Count');
                columns[0].formatter = (row: DeckCard, value: any) => {
                    if (this.state.stateIndex === 1) {
                        return (row.entryCount - row.count).toString();
                    }

                    if (this.state.stateIndex === 2) {
                        return row.count.toString();
                    }

                    return value.toString();
                };
                columns.push({
                    key: 'isDeckProxy', label: translate('IsProxy'), type: DataGridHeaderTypes.boolean,
                    customRender: (row: any, width: string, addClass: string) => {
                        const card = row as ICard;
                        return (
                            <div
                                className={'datagrid-cell ' + addClass}
                                key={addClass}
                                style={{
                                    width: width,
                                    textAlign: 'center'
                                }}
                            >
                                {(card as DeckCard).isDeckProxy && <FontAwesomeIcon icon={faUnicorn} />}
                            </div>
                        );
                    }
                });
            } else {
                columns.push({ key: 'conditionText', label: translate('Condition'), type: DataGridHeaderTypes.string });
                columns.push({
                    key: 'isOrdered',
                    label: translate('Ordered'),
                    type: DataGridHeaderTypes.boolean,
                    customRender: (row: any, width: string, addClass: string) => {
                        const card = row as ICard;
                        return (
                            <div
                                className={'datagrid-cell ' + addClass}
                                key={addClass}
                                style={{
                                    width: width,
                                    textAlign: 'center'
                                }}
                            >
                                {card.isOrdered && <FontAwesomeIcon icon={faShoppingBasket} />}
                            </div>
                        );
                    }
                });
            }

            return (
                <DataGrid
                    className="cards-datagrid"
                    topParentClassName="cards-page"
                    globalState={this.props.globalState}
                    orderStoreKey={
                        (this.props.deck ? 'Deck' : this.collectionPage ? 'Collection' : 'Search') +
                        'DataGridOrderByIndex'
                    }
                    columns={columns}
                    rows={this.cards}
                    ref={this.dataGridRender}
                    onDoubleClick={(card) => this.navigateToCard(card)}
                    onEDHRec={(card) => this.onEDHRec(card)}
                    onContextMenu={(evt, row) => this.onContextMenu(evt, row as ICard)}
                    onCollection={(cards) => this.onCollection(cards)}
                    onAddToCollection={(cards) => this.onAddToCollection(cards)}
                    onRemoveFromCollection={(cards) => this.onRemoveFromCollection(cards)}
                    onFoil={(cards) => this.onFoil(cards)}
                    onSpecialFoil={(cards) => this.onSpecialFoil(cards)}
                    onDeck={(cards) => this.onDeck(cards)}
                    onRemoveFromDeck={(cards) => this.onRemoveFromDeck(cards)}
                    onMoveInsideDeck={(cards) => this.onMoveInsideDeck(cards)}
                    onOrdered={(cards) => this.onOrdered(cards)}
                    onCommander={(card) => this.swapCommanderState(card)}
                    onSwapReprint={(card) => this.swapReprints(card, this.deckPartIndex)}
                    onChangeIcon={(cards) => this.changeIcon(cards)}
                    onSpecifics={(card) => this.specifics(card)}
                    onChangeDeckProxyState={(cards, state) => this.changeDeckProxyState(cards, state)}
                    onChangeProxyState={(cards, state) => this.changeProxyState(cards, state)}
                    onTags={(cards) => this.onTags(cards)}
                />
            );
        }

        return (
            <VirtualizedList
                diff={104}
                className="cards-list"
                topParentClassName="cards-page"
                cellWidth={width}
                cellHeight={height}
                globalState={this.props.globalState}
                dataSource={this.cards}
                offset={this.props.deck ? 10 : 0}
                renderElement={this.renderElement.bind(this)}
            />
        );
    }

    getCardEntryCount(d: DeckEntry, group: DeckEntry[]) {
        const card = Collection.CardsIndex[d.CardID];

        if (card.isBackFace && group.filter(d => d.CardID === card.otherCard.id).length > 0) {
            return 0;
        }

        return d.EntryCount;
    }

    render() {
        if (!this.initialCardList) {
            return null;
        }
        const translate = this.props.globalState.translate.bind(this.props.globalState);

        if (!this.cards || this.localChange) {
            this.prepareData();
        }

        this.localChange = false;
        this.sliderChange = false;

        const host = document.querySelector('.client')!;
        const width = Math.min(this.state.imageWidth, host.clientWidth - 30);
        const height = Math.floor(width * 1.42);

        return (
            <div className="page">
                <div className="cards-page">
                    {this.props.deck !== undefined && (
                        <div className="cards-deck-tabs">
                            <div
                                className={'cards-deck-tab' + (this.deckPartIndex === 0 ? ' selected' : '')}
                                onClick={() => this.pickDeckTab(0)}
                            >
                                {`${translate('Deck')} (${CountTools.Sum(
                                    this.props.deck.Cards.map((c) => this.getCardEntryCount(c, this.props.deck!.Cards))
                                )})`}
                            </div>
                            <div
                                className={
                                    'cards-deck-tab cards-deck-tab-sideboard' +
                                    (this.deckPartIndex === 1 ? ' selected' : '')
                                }
                                onClick={() => this.pickDeckTab(1)}
                            >
                                {`${translate('sideboard')} (${CountTools.Sum(
                                    this.props.deck.Sideboard.map((c) => this.getCardEntryCount(c, this.props.deck!.Sideboard))
                                )})`}
                            </div>
                            <div
                                className={'cards-deck-tab' + (this.deckPartIndex === 2 ? ' selected' : '')}
                                onClick={() => this.pickDeckTab(2)}
                            >
                                {`${translate('Maybeboard')} (${CountTools.Sum(
                                    this.props.deck.Reserve.map((c) => this.getCardEntryCount(c, this.props.deck!.Reserve))
                                )})`}
                            </div>
                        </div>
                    )}
                    <div className="cards-filters">
                        <div
                            className="cards-filters-expand"
                            onClick={() => {
                                this.sliderChange = true;
                                if (this.props.globalState.pageCustomData) {
                                    this.props.globalState.pageCustomData.isFilterExpanded = !this.state
                                        .isFilterExpanded;
                                }
                                this.setState({ isFilterExpanded: !this.state.isFilterExpanded });
                            }}
                        >
                            <div className="button">
                                <FontAwesomeIcon icon={this.state.isFilterExpanded ? faMinus : faPlus} />
                            </div>
                        </div>
                        <div className="cards-filters-list">
                            <div className="cards-filters-name filter-control">
                                <input
                                    autoComplete='none'
                                    className="cards-filters-text"
                                    type="text"
                                    placeholder={translate('Name...')}
                                    value={this.state.filter}
                                    onKeyPress={(evt) => this.blurFilter(evt)}
                                    onChange={(evt) => {
                                        this.filterUpdated();
                                        const value = evt.target.value;
                                        if (this.props.globalState.pageCustomData) {
                                            this.props.globalState.pageCustomData.filter = value;
                                        }
                                        this.setState({ filter: value });
                                    }}
                                />
                            </div>
                            {!this.state.datagridMode && (
                                <select
                                    className="cards-filters-sort filter-control"
                                    value={this.state.sortIndex}
                                    onChange={(evt) => {
                                        if (evt.target.value === '') {
                                            return;
                                        }

                                        const sortIndex = parseInt(evt.target.value);

                                        if (this.props.parentExpansion) {
                                            this.props.globalState.storeNumberSettings(
                                                'ExpansionDefaultSortIndex',
                                                sortIndex
                                            );
                                        } else {
                                            this.props.globalState.storeNumberSettings('DefaultSortIndex', sortIndex);
                                        }

                                        this.filterUpdated();
                                        if (this.props.globalState.pageCustomData) {
                                            this.props.globalState.pageCustomData.sortIndex = sortIndex;
                                        }
                                        this.setState({ sortIndex: sortIndex });
                                    }}
                                >
                                    <option value="0">{translate('ByNumber')}</option>
                                    <option value="1">{translate('ByName')}</option>
                                    <option value="2">{translate('ByManaCostAsc')}</option>
                                    <option value="3">{translate('ByManaCostDesc')}</option>
                                    <option value="12">{translate('ByTypeAsc')}</option>
                                    <option value="13">{translate('ByTypeDesc')}</option>
                                    <option value="16">{translate('BySuperTypeAsc')}</option>
                                    <option value="17">{translate('BySuperTypeDesc')}</option>
                                    <option value="14">{translate('ByColorAsc')}</option>
                                    <option value="15">{translate('ByColorDesc')}</option>
                                    <option value="4">{translate('ByPriceAsc')}</option>
                                    <option value="5">{translate('ByPriceDesc')}</option>
                                    <option value="7">{translate('ByEdition')}</option>
                                    <option value="8">{translate('ByYearAsc')}</option>
                                    <option value="9">{translate('ByYearDesc')}</option>
                                    <option value="10">{translate('ByCollectionValueAsc')}</option>
                                    <option value="11">{translate('ByCollectionValueDesc')}</option>
                                    <option value="18">{translate('ByCollectionCountAsc')}</option>
                                    <option value="19">{translate('ByCollectionCountDesc')}</option>
                                    <option value="">{menuSeparator}</option>
                                    <option value="20">{translate('Custom')}</option>
                                </select>
                            )}
                            <select
                                className="cards-filters-color filter-control"
                                value={this.state.colorIndex}
                                onChange={(evt) => {
                                    const colorIndex = parseInt(evt.target.value);
                                    this.filterUpdated();
                                    if (this.props.globalState.pageCustomData) {
                                        this.props.globalState.pageCustomData.colorIndex = colorIndex;
                                    }
                                    this.setState({ colorIndex: colorIndex });
                                }}
                            >
                                {this.colorList.map((color) => {
                                    return (
                                        <option key={'color' + color.id} value={color.id}>
                                            {colorDictionary[color.id] ? `${color.name} (${colorDictionary[color.id]})` : color.name}
                                        </option>
                                    );
                                })}
                            </select>
                            <select
                                className="cards-filters-author filter-control"
                                value={this.state.author}
                                onChange={(evt) => {
                                    const author = evt.target.value;
                                    this.filterUpdated();
                                    if (this.props.globalState.pageCustomData) {
                                        this.props.globalState.pageCustomData.author = author;
                                    }
                                    this.setState({ author: author });
                                }}
                            >
                                {this.authors.map((author) => {
                                    if (!author) {
                                        return (
                                            <option key="all" value={''}>
                                                {translate('AllAuthors')}
                                            </option>
                                        );
                                    }
                                    return (
                                        <option key={author} value={author}>
                                            {`${author} (${authorsDictionary[author]})`}
                                        </option>
                                    );
                                })}
                            </select>
                            <select
                                className="cards-filters-rarity filter-control"
                                value={this.state.rarityIndex}
                                onChange={(evt) => {
                                    const rarityIndex = parseInt(evt.target.value);
                                    this.filterUpdated();
                                    if (this.props.globalState.pageCustomData) {
                                        this.props.globalState.pageCustomData.rarityIndex = rarityIndex;
                                    }
                                    this.setState({ rarityIndex: rarityIndex });
                                }}
                            >
                                {this.rarityList.map((rarity) => {
                                    return (
                                        <option key={'rarity' + rarity.id} value={rarity.id}>
                                            {rarityDictionary[rarity.id] ? `${rarity.name} (${rarityDictionary[rarity.id]})` : rarity.name}
                                        </option>
                                    );
                                })}
                            </select>
                            <select
                                className="cards-filters-type filter-control"
                                value={this.state.type}
                                onChange={(evt) => {
                                    let type = evt.target.value;
                                    this.filterUpdated();

                                    if (type === menuSeparator) {
                                        type = '';
                                    }

                                    if (this.props.globalState.pageCustomData) {
                                        this.props.globalState.pageCustomData.type = type;
                                    }
                                    this.setState({ type: type });
                                }}
                            >
                                {this.types.map((type) => {
                                    if (!type) {
                                        return (
                                            <option key="all" value={''}>
                                                {translate('AllTypes')}
                                            </option>
                                        );
                                    }
                                    const lower = type.toLowerCase();
                                    return (
                                        <option key={type} value={type}>
                                            {typesDictionary[lower] ? `${type} (${typesDictionary[lower]})` : type}
                                        </option>
                                    );
                                })}
                            </select>
                            <select
                                className="cards-filters-format filter-control"
                                value={this.state.format}
                                onChange={(evt) => {
                                    const format = evt.target.value;
                                    this.filterUpdated();
                                    if (this.props.globalState.pageCustomData) {
                                        this.props.globalState.pageCustomData.format = format;
                                    }
                                    this.setState({ format: format });
                                }}
                            >
                                {CardFormat.CardFormats.map((format) => {
                                    if (!format.predicate) {
                                        return (
                                            <option key="all" value={''}>
                                                {format.name}
                                            </option>
                                        );
                                    }
                                    return (
                                        <option key={format.name} value={format.name}>
                                            {format.name}
                                        </option>
                                    );
                                })}
                            </select>
                            {(this.props.parentExpansion || this.props.searchData || this.props.deck) && (
                                <select
                                    className={
                                        'cards-filters-state' +
                                        (this.state.datagridMode ? 'dg' : '') +
                                        ' filter-control'
                                    }
                                    value={this.state.stateIndex}
                                    onChange={(evt) => {
                                        const stateIndex = parseInt(evt.target.value);
                                        this.filterUpdated();
                                        if (this.props.globalState.pageCustomData) {
                                            this.props.globalState.pageCustomData.stateIndex = stateIndex;
                                        }
                                        this.setState({ stateIndex: stateIndex });
                                    }}
                                >
                                    <option value="0">{translate('AllCards')}</option>
                                    <option value="1">{translate('OnlyMissing')}</option>
                                    <option value="2">{translate('OnlyInCollection')}</option>
                                    {!this.props.deck && (
                                        <option value="3">{translate('OnlyMissingAndNotOrdered')}</option>
                                    )}
                                    <option value="4">{translate('OnlyOrdered')}</option>
                                    <option value="5">{translate('ReservedList')}</option>
                                </select>
                            )}
                            {this.state.isFilterExpanded && (
                                <select
                                    className="cards-filters-expansions filter-control"
                                    value={this.state.expansionIndex}
                                    onChange={(evt) => {
                                        const expansionIndex = parseInt(evt.target.value);
                                        this.filterUpdated();
                                        if (this.props.globalState.pageCustomData) {
                                            this.props.globalState.pageCustomData.expansionIndex = expansionIndex;
                                        }
                                        this.setState({ expansionIndex: expansionIndex });
                                    }}
                                >
                                    <option value="0">{translate('AllExpansions')}</option>
                                    {this.sets.map((exp, i) => {
                                        return (
                                            <option key={exp.name + i} value={i + 1}>
                                                {exp.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            )}
                            {this.state.isFilterExpanded && (
                                <select
                                    className="cards-filters-decks filter-control"
                                    value={this.state.deckIndex}
                                    onChange={(evt) => {
                                        const deckIndex = evt.target.value;
                                        this.filterUpdated();
                                        if (this.props.globalState.pageCustomData) {
                                            this.props.globalState.pageCustomData.deckIndex = deckIndex;
                                        }
                                        this.setState({ deckIndex: deckIndex });
                                    }}
                                >
                                    <option value="-1">{translate('None')}</option>
                                    <option value="0">{translate('All decks')}</option>
                                    {this.decks.map((deck, i) => {
                                        return (
                                            <option key={deck.Name + i} value={deck.UniqueID}>
                                                {deck.Name}
                                            </option>
                                        );
                                    })}
                                </select>
                            )}
                            {this.state.isFilterExpanded && (
                                <select
                                    className="cards-filters-tags filter-control"
                                    value={this.state.tagIndex}
                                    onChange={(evt) => {
                                        const tagIndex = parseInt(evt.target.value);
                                        this.filterUpdated();
                                        if (this.props.globalState.pageCustomData) {
                                            this.props.globalState.pageCustomData.tagIndex = tagIndex;
                                        }
                                        this.setState({ tagIndex: tagIndex });
                                    }}
                                >
                                    <option value="0">{translate('AllTags')}</option>
                                    {this.tags.map((tag, i) => {
                                        return (
                                            <option key={tag + i} value={i + 1}>
                                                {`${tag} (${this.tagCount[tag]})`}
                                            </option>
                                        );
                                    })}
                                </select>
                            )}
                            {this.state.isFilterExpanded && (
                                <div className="cards-filters-name filter-control">
                                    <input
                                        className="cards-filters-text full-text"
                                        type="text"
                                        autoComplete="none"
                                        placeholder={translate('Fulltext_')}
                                        value={this.state.fullText}
                                        onKeyPress={(evt) => this.blurFilter(evt)}
                                        onChange={(evt) => {
                                            this.filterUpdated();
                                            const value = evt.target.value;
                                            if (this.props.globalState.pageCustomData) {
                                                this.props.globalState.pageCustomData.fullText = value;
                                            }
                                            this.setState({ fullText: value });
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        {this.state.isFilterExpanded && (
                            <div className="cards-filters-list expand filter-plus">
                                <div className="cards-filters-price filter-control filter-control-double">
                                    <RangeSlider
                                        isHorizontal={true}
                                        globalState={this.props.globalState}
                                        label={translate('Price')}
                                        onChange={(vMin, vMax) => {
                                            this.filterUpdated();
                                            const min = PriceTools.FromLocalCurrency(vMin, this.props.globalState);
                                            const max = PriceTools.FromLocalCurrency(vMax, this.props.globalState);
                                            if (this.props.globalState.pageCustomData) {
                                                this.props.globalState.pageCustomData.price = [min, max];
                                            }
                                            this.setState({ price: [min, max] });
                                        }}
                                        min={0}
                                        max={PriceTools.ToLocalCurrency(this.maxPrice, this.props.globalState)}
                                        valueLeft={PriceTools.ToLocalCurrency(
                                            this.state.price[0],
                                            this.props.globalState
                                        )}
                                        valueRight={PriceTools.ToLocalCurrency(
                                            this.state.price[1],
                                            this.props.globalState
                                        )}
                                    />
                                </div>
                                <div className="cards-filters-manacost filter-control filter-control-double">
                                    <RangeSlider
                                        isInteger={true}
                                        isHorizontal={true}
                                        globalState={this.props.globalState}
                                        label={translate('Manacost')}
                                        onChange={(min, max) => {
                                            this.filterUpdated();
                                            if (this.props.globalState.pageCustomData) {
                                                this.props.globalState.pageCustomData.manacost = [min, max];
                                            }
                                            this.setState({ manacost: [min, max] });
                                        }}
                                        min={this.minManacost}
                                        max={this.maxManacost}
                                        valueLeft={this.state.manacost[0]}
                                        valueRight={this.state.manacost[1]}
                                    />
                                </div>
                                <div className="cards-filters-power filter-control filter-control-double">
                                    <RangeSlider
                                        isInteger={true}
                                        isHorizontal={true}
                                        globalState={this.props.globalState}
                                        label={translate('Power')}
                                        onChange={(min, max) => {
                                            this.filterUpdated();
                                            if (this.props.globalState.pageCustomData) {
                                                this.props.globalState.pageCustomData.power = [min, max];
                                            }
                                            this.setState({ power: [min, max] });
                                        }}
                                        min={this.minPower}
                                        max={this.maxPower}
                                        valueLeft={this.state.power[0]}
                                        valueRight={this.state.power[1]}
                                    />
                                </div>
                                <div className="cards-filters-toughness filter-control filter-control-double">
                                    <RangeSlider
                                        isInteger={true}
                                        isHorizontal={true}
                                        globalState={this.props.globalState}
                                        label={translate('Toughness')}
                                        onChange={(min, max) => {
                                            this.filterUpdated();
                                            if (this.props.globalState.pageCustomData) {
                                                this.props.globalState.pageCustomData.toughness = [min, max];
                                            }
                                            this.setState({ toughness: [min, max] });
                                        }}
                                        min={this.minToughness}
                                        max={this.maxToughness}
                                        valueLeft={this.state.toughness[0]}
                                        valueRight={this.state.toughness[1]}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {this.renderCards(width, height)}
                </div>
            </div>
        );
    }
}
