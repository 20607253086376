
export class CardDates {
    public static ToString(dates: Date[], undefinedString: string) {
        if (dates.length > 1) {
            const first = dates[0].toLocaleDateString();
            if (dates.every((x) => x.toLocaleDateString() === first)) {
                return `${dates.length}x${first}`;
            }
        }

        return dates.map(d =>{
            if (d instanceof Date && !isNaN(d.getTime())) {
                return d.toLocaleDateString();
            }
            return undefinedString;
        }).join(', ');
    }

    public static ToDates(dates: string[]) {
        return dates.map(d => this.StringToDate(d));
    }

    public static StringToDate(value: string) {
        const split = value.split('-').map(s => parseInt(s));
        return new Date(split[0], split[1] - 1, split[2]);
    }
}
