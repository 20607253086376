import * as React from 'react';
import { CardCard } from '../controls/cards/cardCard';
import { Card } from '../entities/card';
import { Collection } from '../entities/collection';
import { CardTextLine } from '../controls/cardTextLine';
import { PriceTools } from '../tools/priceTools';
import { CardColor } from '../entities/cardColor';
import { CardRarity } from '../entities/cardRarity';
import { CardTextWithManaLine } from '../controls/cardTextWithManaLine';
import { CardTextMultiLine } from '../controls/cardTextMultiLine';
import { SetCard } from '../controls/cards/setCard';
import { Observer } from '../tools/observable';
import { Nullable } from '../tools/nullable';
import {
    faShoppingCart,
    faFeather,
    faShoppingBasket,
    faPlus,
    faMinus,
    faCropAlt,
    faIcons
} from '@fortawesome/free-solid-svg-icons';
import { LinkTools } from '../tools/linkTool';
import { Deck } from '../entities/deck';
import { faFlagCheckered, faCrown, faInfo, faCashRegister, faTags, faShareSquare, faLink, faGem, faUnicorn, faBan, faInfoSquare, faList } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { TCGPlayer } from '../tools/prices/tcgPlayer';
import { Scryfall } from '../tools/prices/scryfall';
import * as Hammer from 'hammerjs';
import { MagicCardMarket } from '../tools/prices/magicCardMarket';
import { ShareTools } from '../tools/shareTools';
import { Analytics } from '../tools/analytics';
import { GlobalState } from '../globalState';
import { PatreonLevel, PatreonTools } from '../tools/patreonTools';
import { CardDates } from '../entities/cardDates';

require('../scss/cardPage.scss');

interface ICardPageProps {
    globalState: GlobalState;
    cardIds: number[];
    selectedIndex: number;
}

interface ICardPageState {
    selectedIndex: number;
    disableCardAnimation: boolean;
    showRulings: boolean;
    showBackOnly: boolean;
    cropUrl: string;
    cardInFullScreenMode: boolean;
}

export class CardPage extends React.Component<ICardPageProps, ICardPageState> {
    private cards: Card[];
    private scroller: HTMLElement;
    private onPrevObserver: Nullable<Observer<void>>;
    private onNextObserver: Nullable<Observer<void>>;
    private onFlipObserver: Nullable<Observer<Record<string, unknown>>>;
    private onCollectionCommandActivatedObserver: Nullable<Observer<void>>;
    private onFoilsCommandActivatedObserver: Nullable<Observer<void>>;
    private onCustomButtonObserver: Nullable<Observer<void>>;
    private onDeckCommandActivatedObserver: Nullable<Observer<void>>;
    private onKeyUpObserver: Nullable<Observer<KeyboardEvent>>;
    private capturedX: number;
    private capturedY: number;
    private capturedPointerId: number;
    private captureTime: Date;
    private timeoutId?: number;
    private currentScale = 1;
    private adjustScale = 1;
    private adjustDeltaX = 0;
    private adjustDeltaY = 0;

    constructor(props: ICardPageProps) {
        super(props);

        const selectedIndex = this.props.selectedIndex !== undefined ? this.props.selectedIndex : 0;

        this.state = {
            selectedIndex: selectedIndex,
            disableCardAnimation: false,
            showRulings: false,
            showBackOnly: false,
            cropUrl: '',
            cardInFullScreenMode: false
        };
        this.cards = this.props.cardIds.map((id) => Collection.CardsIndex[id]);

        if (this.cards.length === 1 && this.cards[0] === undefined) {
            this.cards =[Collection.Cards[(Math.random() * Collection.Cards.length) | 0]];
        }

        if (this.cards.length > 0) {
            this.requestNavigationButtons();
        }

        this.showCustomMenu();
    }

    showCustomMenu() {
        const menus = [
            {
                label: this.props.globalState.translate('DeepLink'),
                action: () => {
                    this.displayDeepLink();
                },
                icon: faLink
            },
            {
                label: this.props.globalState.translate(this.state.cropUrl ? 'NormalCard' : 'CroppedCard'),
                action: () => {
                    const card = this.cards[this.state.selectedIndex];
                    if (this.state.cropUrl) {
                        this.setState({ cropUrl: '' });
                        setTimeout(() => {
                            this.showCustomMenu();
                        });
                        return;
                    }

                    this.props.globalState.onWaitRingRequired.notifyObservers(true);
                    this.setState({ cropUrl: card.cropUrl });
                    setTimeout(() => {
                        this.showCustomMenu();
                    });
                },
                icon: faCropAlt as Icon
            },
            {
                label: ''
            },
            {
                label: 'Ebay',
                action: () => {
                    const card = this.cards[this.state.selectedIndex];
                    let query = card.name + '+' + card.set.name;

                    query = query.replace(/\s/g, '+');

                    LinkTools.OpenLink(`https://www.ebay.com/sch/i.html?_nkw=${query}&_sop=15`);
                },
                icon: faShoppingCart
            },
            {
                label: 'CardMarket',
                action: async () => {
                    this.props.globalState.onWaitRingRequired.notifyObservers(true);
                    const card = this.cards[this.state.selectedIndex];
                    const link = await MagicCardMarket.GetLinkAsync(card);
                    LinkTools.OpenLink(link);
                    this.props.globalState.onWaitRingRequired.notifyObservers(false);
                },
                icon: faShoppingCart
            },
            {
                label: 'TCGPlayer',
                action: () => {
                    const card = this.cards[this.state.selectedIndex];

                    LinkTools.OpenLink(TCGPlayer.GetCardLink(card));
                },
                icon: faShoppingCart
            },
            {
                label: 'Scryfall',
                action: () => {
                    const card = this.cards[this.state.selectedIndex];

                    LinkTools.OpenLink(Scryfall.GetCardLink(card));
                },
                icon: faCrown as Icon
            },
            {
                label: ''
            },
            {
                label: this.props.globalState.translate('AddToCollection'),
                action: () => {
                    const card = this.cards[this.state.selectedIndex];
                    card.count = Math.min(GlobalState.MaxCardCount, card.count + 1);
                    Collection.RegisterSave();

                    this.props.globalState.OnOrderedChanged.notifyObservers(card.isOrdered);

                    this.forceUpdate();
                },
                icon: faPlus
            },
            {
                label: this.props.globalState.translate('RemoveFromCollection'),
                action: () => {
                    if (GlobalState.LoadBoolSettings('WhenRemovingFromCollection', false)) {
                        this.props.globalState.showConfirmDialog(this.props.globalState.translate('RemoveFromCollectionConfirm'), 'Yes', 'No').then(value => {
                            if (!value) {
                                return;
                            }
                            const card = this.cards[this.state.selectedIndex];
                            card.count = 0;
                            Collection.RegisterSave();

                            this.forceUpdate();
                        });
                        return;
                    }

                    const card = this.cards[this.state.selectedIndex];
                    card.count = 0;
                    Collection.RegisterSave();

                    this.forceUpdate();
                },
                icon: faMinus
            },
            {
                label: ''
            },
            {
                label: this.props.globalState.translate('Characteristics'),
                action: () => {
                    if (!this.cards[this.state.selectedIndex].count) {
                        this.props.globalState.onError.notifyObservers(this.props.globalState.translate('NeedToAddCardToCollectionFirst'));
                        return;
                    }
                    this.props.globalState.onCardDataRequired.notifyObservers(this.cards[this.state.selectedIndex]);
                },
                icon: faInfoSquare
            },
            {
                label: this.props.globalState.translate('Comments'),
                action: () => {
                    this.props.globalState
                        .showQuestionDialog(
                            this.props.globalState.translate('Comments'),
                            this.cards[this.state.selectedIndex].comments,
                            true
                        )
                        .then((value) => {
                            if (value !== undefined) {
                                const card = this.cards[this.state.selectedIndex];
                                card.comments = value;
                                Collection.RegisterSave();

                                this.forceUpdate();
                            }
                        });
                },
                icon: faFeather
            },
            {
                label: this.props.globalState.translate('Tags'),
                action: () => {
                    this.props.globalState.onTagUpdated.addOnce(() => {
                        this.forceUpdate();
                    });
                    this.props.globalState.onTagRequired.notifyObservers([this.cards[this.state.selectedIndex]]);
                },
                icon: faTags as Icon
            },
            {
                label: this.props.globalState.translate(this.cards[this.state.selectedIndex].isProxy ? 'UnflagAsProxy' : 'FlagAsProxy'),
                action: () => {
                    const card = this.cards[this.state.selectedIndex];
                    card.isProxy = !card.isProxy;
                    Collection.RegisterSave();
                    this.props.globalState.OnProxyChanged.notifyObservers(card.isProxy);
                    setTimeout(() => {
                        this.showCustomMenu();
                    });
                    this.forceUpdate();
                },
                icon: faUnicorn
            },
            {
                label: this.props.globalState.translate('CustomIcon'),
                action: async () => {
                    if (
                        PatreonTools.PledgeLevel === PatreonLevel.None
                    ) {
                        this.props.globalState.onError.notifyObservers(
                            this.props.globalState.translate('OnlyForPatreon')
                        );
                        return;
                    }
                    const card = this.cards[this.state.selectedIndex];
                    const newIcon = await this.props.globalState.showCardIconPicker(card.icon);
                    if (newIcon.icon !== undefined) {
                        card.icon = newIcon.icon;
                        Collection.RegisterSave();
                    }
                },
                icon: faIcons
            },
            {
                label: this.props.globalState.translate('HideCard'),
                action: () => {
                    this.props.globalState.showConfirmDialog(this.props.globalState.translate('HideCardConfirm'))
                        .then((value) => {
                            if (!value) {
                                return;
                            }
                            const card = this.cards[this.state.selectedIndex];
                            Collection.ExcludedCardIds.push(card.id);
                            Collection.ExcludedCardNames[card.id] = `${card.name} (${card.set.name})`;
                            Collection.StoreExcludedCardIds();

                            this.forceUpdate();
                        });
                },
                icon: faBan as Icon
            },
            {
                label: ''
            },
            {
                label: this.props.globalState.translate('SwitchOrderedFlag'),
                action: () => {
                    const card = this.cards[this.state.selectedIndex];
                    card.isOrdered = !card.isOrdered;
                    this.props.globalState.OnOrderedChanged.notifyObservers(card.isOrdered);
                    Collection.RegisterSave();

                    this.forceUpdate();
                },
                icon: faShoppingBasket
            },
            {
                label: this.props.globalState.translate('SetToTrade'),
                action: () => {
                    const card = this.cards[this.state.selectedIndex];
                    this.props.globalState
                        .showCountDialog(this.props.globalState.translate('SetToTrade'), card.toTrade, card.count)
                        .then((value) => {
                            if (value !== undefined) {
                                card.toTrade = value;
                                Collection.RegisterSave();

                                this.forceUpdate();
                            }
                        });
                },
                icon: faCashRegister as Icon
            },            {
                label: this.props.globalState.translate('SetWanted'),
                action: () => {
                    const card = this.cards[this.state.selectedIndex];
                    this.props.globalState
                        .showCountDialog(this.props.globalState.translate('SetWanted'), card.wanted)
                        .then((value) => {
                            if (value !== undefined) {
                                card.wanted = value;
                                Collection.RegisterSave();

                                this.forceUpdate();
                            }
                        });
                },
                icon: faList as Icon
            },
            {
                label: ''
            },
            {
                label: this.props.globalState.translate('ReportIssue'),
                action: () => {
                    const card = this.cards[this.state.selectedIndex];

                    LinkTools.OpenLink(
                        `mailto:david@catuhe.com?subject=${this.props.globalState.translate('IssueWith')} ${card.name
                        } / ${card.set.name}`
                    );
                },
                icon: faFlagCheckered as Icon
            }
        ];

        const currentCard = this.cards[this.state.selectedIndex];

        if (currentCard.isLegendary && currentCard.isCreature) {
            menus.splice(2, 0, {
                label: ''
            });
            menus.splice(3, 0, {
                label: 'EDHRec',
                action: () => {
                    const card = this.cards[this.state.selectedIndex];

                    LinkTools.OpenLink('https://edhrec.com/commanders/' +
                        card.nameEn.toLowerCase().replace(/\s/g, '-').replace(',', ''));
                },
                icon: faInfo as Icon
            });
        }

        if (ShareTools.CanShare) {
            menus.splice(0, 0, {
                label: this.props.globalState.translate('Share'),
                action: () => {
                    const card = this.cards[this.state.selectedIndex];
                    ShareTools.ShareAsync('https://collectocors.azurewebsites.net/Mid/' + card.picturePath.replace('\\', '/'), card.name);
                },
                icon: faShareSquare
            });
        }

        const target = this.props.globalState.translate('RemoveFromCollection');
        let removeFromCollectionIndex = 0;
        for (; removeFromCollectionIndex < menus.length; removeFromCollectionIndex++) {
            if (menus[removeFromCollectionIndex].label === target) {
                break;
            }
        }

        if (currentCard.canBeSpecialFoil) {
            menus.splice(removeFromCollectionIndex + 1, 0, {
                label: this.props.globalState.translate('SpecialFoils'),
                action: () => {
                    const card = this.cards[this.state.selectedIndex];
                    const currentSpecialFoilCount = card.specialFoilCount;

                    this.props.globalState
                        .showCountDialog(this.props.globalState.translate('NumberOfSpecialFoils'), card.specialFoilCount)
                        .then((value) => {
                            card.specialFoilCount = value;
                            card.count += value - currentSpecialFoilCount;

                            Collection.RegisterSave();

                            this.props.globalState.OnOrderedChanged.notifyObservers(card.isOrdered);

                            this.forceUpdate();
                        });
                    Collection.RegisterSave();

                    this.forceUpdate();
                },
                icon: faGem
            });
        }

        this.props.globalState.onCustomMenuRequired.notifyObservers(menus);
    }

    onPointerMove(evt: React.PointerEvent<HTMLDivElement>) {
        if (evt.pointerId !== this.capturedPointerId || evt.pointerType !== 'touch') {
            return;
        }

        if (new Date().getTime() - this.captureTime.getTime() > 500) {
            this.capturedPointerId = -1;
            return;
        }

        const yDist = Math.abs(evt.clientY - this.capturedY);

        if (yDist > 15) {
            this.capturedPointerId = -1;
            return;
        }

        if (evt.pageX > this.capturedX + 30) {
            this.movePrev();
            this.capturedPointerId = -1;
        }

        if (evt.pageX < this.capturedX - 30) {
            this.moveNext();
            this.capturedPointerId = -1;
        }
    }

    onTouchMove(evt: React.TouchEvent<HTMLDivElement>) {
        if (evt.touches[0].identifier !== this.capturedPointerId || evt.type !== 'touchmove') {
            return;
        }

        if (new Date().getTime() - this.captureTime.getTime() > 500) {
            this.capturedPointerId = -1;
            return;
        }

        const yDist = Math.abs(evt.touches[0].clientY - this.capturedY);

        if (yDist > 15) {
            this.capturedPointerId = -1;
            return;
        }

        if (evt.touches[0].pageX > this.capturedX + 30) {
            this.movePrev();
            this.capturedPointerId = -1;
        }

        if (evt.touches[0].pageX < this.capturedX - 30) {
            this.moveNext();
            this.capturedPointerId = -1;
        }
    }

    displayDeepLink() {
        LinkTools.OpenLink(location.toString());
    }

    processNewCard(newIndex: number) {
        const nextCard = this.cards[newIndex];
        this.props.globalState.onHeaderTitleChanged.notifyObservers({
            title: nextCard.name,
            isFlip: nextCard.tag !== '' && !!nextCard.otherCard
        });

        this.props.globalState.OnOrderedChanged.notifyObservers(nextCard.isOrdered);

        this.props.globalState.onCustomButtonRequired.notifyObservers(
            nextCard.rulings.length > 0 ? this.props.globalState.translate('Rulings') : ''
        );
        this.props.globalState.getPreviousNavigationData().uniqueID = newIndex;
        this.setState({ selectedIndex: newIndex, disableCardAnimation: true, showRulings: false, cropUrl: '' });
        this.showCustomMenu();
        this.resetTransform();
    }

    checkTimeout() {
        if (this.timeoutId) {
            window.clearTimeout(this.timeoutId);
            this.timeoutId = undefined;
        }
    }

    movePrev() {
        if (this.state.selectedIndex > 0) {
            if (Analytics.IsIOs()) {
                this.processNewCard(this.state.selectedIndex - 1);
                return;
            }

            const page = document.querySelector('.card-page')!;

            if (page) {
                page.classList.add('float-out-right');
                page.classList.remove('float-in-left');
                page.classList.remove('float-in-right');
                page.classList.remove('float-out-left');
            }

            this.checkTimeout();
            this.timeoutId = window.setTimeout(() => {
                this.processNewCard(this.state.selectedIndex - 1);
                this.checkTimeout();
                this.timeoutId = window.setTimeout(() => {
                    if (page) {
                        page.classList.add('float-in-left');
                        page.classList.remove('float-out-right');
                    }
                    this.checkTimeout();
                    this.timeoutId = window.setTimeout(() => {
                        if (page) {
                            page.classList.remove('float-in-left');
                            page.classList.remove('float-in-right');
                        }
                    }, 200);
                });
            }, 150);
        }
    }

    moveNext() {
        if (this.state.selectedIndex < this.cards.length - 1) {
            if (Analytics.IsIOs()) {
                this.processNewCard(this.state.selectedIndex + 1);
                return;
            }

            const page = document.querySelector('.card-page')!;

            if (page) {
                page.classList.add('float-out-left');
                page.classList.remove('float-in-left');
                page.classList.remove('float-in-right');
                page.classList.remove('float-out-right');
            }

            this.checkTimeout();
            this.timeoutId = window.setTimeout(() => {
                this.processNewCard(this.state.selectedIndex + 1);
                this.checkTimeout();
                this.timeoutId = window.setTimeout(() => {
                    if (page) {
                        page.classList.add('float-in-right');
                        page.classList.remove('float-out-left');
                    }
                    this.checkTimeout();
                    this.timeoutId = window.setTimeout(() => {
                        if (page) {
                            page.classList.remove('float-in-left');
                            page.classList.remove('float-in-right');
                        }
                    }, 200);
                });
            }, 150);
        }
    }

    onPointerDown(evt: React.PointerEvent<HTMLDivElement>) {
        this.capturedX = evt.pageX;
        this.capturedY = evt.pageY;
        this.capturedPointerId = evt.pointerId;
        this.captureTime = new Date();
    }

    onTouchDown(evt: React.TouchEvent<HTMLDivElement>) {
        this.capturedX = evt.touches[0].pageX;
        this.capturedY = evt.touches[0].pageY;
        this.capturedPointerId = evt.touches[0].identifier;
        this.captureTime = new Date();
    }

    requestNavigationButtons() {
        this.props.globalState.onPrevButtonRequired.notifyObservers(this.state.selectedIndex > 0);
        this.props.globalState.onNextButtonRequired.notifyObservers(this.state.selectedIndex < this.cards.length - 1);
    }

    onScroll() {
        this.props.globalState.pageCustomData.scroll = this.scroller.scrollTop;
    }

    UNSAFE_componentWillMount() {
        this.onCustomButtonObserver = this.props.globalState.onCustomButtonClicked.add(() => {
            if (this.state.showRulings) {
                this.props.globalState.onCustomButtonRequired.notifyObservers(
                    this.props.globalState.translate('Rulings')
                );
            } else {
                this.props.globalState.onCustomButtonRequired.notifyObservers(
                    this.props.globalState.translate('Details')
                );
            }
            this.setState({ showRulings: !this.state.showRulings });
        });

        this.onNextObserver = this.props.globalState.onNext.add(() => {
            this.moveNext();
        });

        this.onPrevObserver = this.props.globalState.onPrev.add(() => {
            this.movePrev();
        });

        this.onFlipObserver = this.props.globalState.onFlipRequired.add(() => {
            const currentCard = this.cards[this.state.selectedIndex];
            const otherCard = currentCard.otherCard;

            this.setState({ disableCardAnimation: false, showBackOnly: true });

            setTimeout(() => {
                this.cards[this.state.selectedIndex] = otherCard;
                this.props.globalState.onHeaderTitleChanged.notifyObservers({
                    title: otherCard.name,
                    isFlip: true
                });
                this.setState({ disableCardAnimation: false, showBackOnly: false });
            }, 150);
        });

        this.onCollectionCommandActivatedObserver = this.props.globalState.onCollectionCommandActivated.add(() => {
            const card = this.cards[this.state.selectedIndex];

            this.props.globalState
                .showCountDialog(this.props.globalState.translate('Collection_'), card.count)
                .then((value) => {
                    card.count = value;
                    Collection.RegisterSave();

                    this.props.globalState.OnOrderedChanged.notifyObservers(card.isOrdered);

                    this.forceUpdate();
                });
        });

        this.onFoilsCommandActivatedObserver = this.props.globalState.onFoilsCommandActivated.add(() => {
            const card = this.cards[this.state.selectedIndex];

            const currentFoilCount = card.foilCount;

            this.props.globalState
                .showCountDialog(this.props.globalState.translate('NumberOfFoils'), card.foilCount)
                .then((value) => {
                    card.foilCount = value;
                    card.count += value - currentFoilCount;

                    Collection.RegisterSave();

                    this.props.globalState.OnOrderedChanged.notifyObservers(card.isOrdered);

                    this.forceUpdate();
                });
        });

        this.onDeckCommandActivatedObserver = this.props.globalState.onDeckCommandActivated.add(() => {
            const card = this.cards[this.state.selectedIndex];
            this.props.globalState
                .showDeckCountDialog(this.props.globalState.translate('NumberOfCopies'), card, null, 0, 0, 0)
                .then((value) => {
                    Deck.Update(value.deck, 0, card.id, value.deckValue);
                    Deck.Update(value.deck, 1, card.id, value.sideboardValue);
                    Deck.Update(value.deck, 2, card.id, value.reserveValue);

                    this.forceUpdate();

                    Collection.RegisterSave();

                    this.props.globalState.onMessage.notifyObservers(
                        this.props.globalState.translate('DeckSuccessful')
                    );
                });
        });

        this.onKeyUpObserver = this.props.globalState.onKeyUp.add(evt => {
            if (evt.target instanceof HTMLInputElement) {
                return;
            }

            switch (evt.keyCode) {
                case 39:
                    this.moveNext();
                    break;
                case 37:
                    this.movePrev();
                    break;
            }
        });
    }

    componentWillUnmount() {
        this.props.globalState.onCollectionCommandActivated.remove(this.onCollectionCommandActivatedObserver);
        this.props.globalState.onFoilsCommandActivated.remove(this.onFoilsCommandActivatedObserver);
        this.props.globalState.onNext.remove(this.onNextObserver);
        this.props.globalState.onPrev.remove(this.onPrevObserver);
        this.props.globalState.onFlipRequired.remove(this.onFlipObserver);
        this.props.globalState.onCustomButtonClicked.remove(this.onCustomButtonObserver);
        this.props.globalState.onDeckCommandActivated.remove(this.onDeckCommandActivatedObserver);
        this.props.globalState.onKeyUp.remove(this.onKeyUpObserver);
    }

    shouldComponentUpdate(nextProps: ICardPageProps, nextState: ICardPageState) {
        if (nextProps.cardIds !== this.props.cardIds || nextProps.selectedIndex !== this.props.selectedIndex) {
            this.cards = nextProps.cardIds.map((id) => Collection.CardsIndex[id]);
            nextState.selectedIndex = nextProps.selectedIndex;
            nextState.disableCardAnimation = false;
            nextState.cropUrl = '';
            this.showCustomMenu();
            this.resetTransform();
        }

        return true;
    }

    processWheel(delta: number) {
        const image = document.getElementById('imgCrop')!;

        this.currentScale = Math.min(5, Math.max(1, this.adjustScale + delta));

        // Concatinating and applying parameters.
        const transforms = [];
        transforms.push(`scale(${this.currentScale})`);
        transforms.push(`translate(${this.adjustDeltaX}px,${this.adjustDeltaY}px)`);
        image.style.transform = transforms.join(' ');

        this.adjustScale = this.currentScale;
    }

    resetTransform() {
        const image = document.getElementById('imgCrop');

        if (image) {
            image.style.transform = '';
        }
        this.adjustScale = 1;
        this.adjustDeltaX = 0;
        this.adjustDeltaY = 0;
    }

    prepareHammer() {
        // Hammer
        const image = document.getElementById('imgCrop')!;

        this.props.globalState.onWaitRingRequired.notifyObservers(false);

        const mc = new Hammer.Manager(image);

        const pinch = new Hammer.Pinch();
        const pan = new Hammer.Pan();
        const tap = new Hammer.Tap();

        // pinch.recognizeWith(pan);

        mc.add([pinch, pan, tap]);

        let currentDeltaX = 0;
        let currentDeltaY = 0;

        // Prevent long press saving on mobiles.
        image.addEventListener('touchstart', (e) => {
            e.preventDefault();
        });

        mc.on('tap', (ev: any) => {
            if (ev.tapCount > 1) {
                this.resetTransform();
            }
        });

        mc.on('pinch', (ev) => {
            this.currentScale = Math.min(5, Math.max(1, this.adjustScale * ev.scale));
            const transforms = [];
            transforms.push(`scale(${this.currentScale})`);
            transforms.push(`translate(${this.adjustDeltaX}px,${this.adjustDeltaY}px)`);
            image.style.transform = transforms.join(' ');
        });

        mc.on('pan', (ev) => {
            currentDeltaX = this.adjustDeltaX + ev.deltaX / this.adjustScale;
            currentDeltaY = this.adjustDeltaY + ev.deltaY / this.adjustScale;

            // Concatinating and applying parameters.
            const transforms = [];
            transforms.push(`scale(${this.adjustScale})`);
            transforms.push(`translate(${currentDeltaX}px,${currentDeltaY}px)`);
            image.style.transform = transforms.join(' ');
        });

        mc.on('panend pinchend', (_ev) => {
            // Saving the final transforms for adjustment next time the user interacts.
            this.adjustScale = this.currentScale;
            this.adjustDeltaX = currentDeltaX;
            this.adjustDeltaY = currentDeltaY;
        });
    }

    componentDidMount() {
        this.scroller = document.getElementById('scrollElement')!;

        const customData = this.props.globalState.pageCustomData;
        const scrollPosition = customData && customData.scroll ? customData.scroll : 0;
        this.scroller.scrollTop = scrollPosition;
        this.props.globalState.onHighlightRequired.notifyObservers(this.cards[this.state.selectedIndex].count > 0);
    }

    componentDidUpdate() {
        this.requestNavigationButtons();
        this.props.globalState.onHighlightRequired.notifyObservers(this.cards[this.state.selectedIndex].count > 0);
    }

    scrollHorizontally(evt: React.WheelEvent<HTMLDivElement>) {
        const delta = Math.max(-1, Math.min(1, evt.deltaY));
        const page = document.querySelector('.card-page')! as HTMLDivElement;

        if (page.scrollHeight !== page.clientHeight) {
            return;
        }

        if (delta > 0) {
            this.moveNext();
        } else {
            this.movePrev();
        }
    }

    onFullscreenMode(enabled: boolean) {
        this.setState({
            cardInFullScreenMode: enabled
        });
    }

    render() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const card = this.cards[this.state.selectedIndex];
        const gs = this.props.globalState;
        const color = CardColor.GetColorById(card.colorId);
        const decks = card.getDeckString(this.props.globalState);
        const dates = CardDates.ToString(card.dates, translate('Undefined'));

        setTimeout(() => {
            this.props.globalState.showFoilMenu.notifyObservers(card.canBeFoil);
            this.props.globalState.replaceUrl('\\?card=' + card.id);
        });

        return (
            <div className="page">
                {this.state.cropUrl && (
                    <div className="crop">
                        <img
                            id="imgCrop"
                            src={this.state.cropUrl}
                            onLoad={() => this.prepareHammer()}
                            onError={() => this.props.globalState.onWaitRingRequired.notifyObservers(false)}
                            onWheel={(evt) => this.processWheel(evt.deltaY * -0.001)}
                        />
                    </div>
                )}
                {!this.state.cropUrl && (
                    <div
                        className={'card-page'
                            + (Collection.ExcludedCardIds.indexOf(card.id) !== -1 ? ' removed' : '')
                            + (this.state.cardInFullScreenMode ? ' fullscreen' : '')}
                        id="scrollElement"
                        onScroll={() => this.onScroll()}
                        onWheel={(evt) => this.scrollHorizontally(evt)}
                        onTouchMove={(evt) => this.onTouchMove(evt)}
                        onTouchStart={(evt) => this.onTouchDown(evt)}
                        onPointerDown={(evt) => this.onPointerDown(evt)}
                        onPointerMove={(evt) => this.onPointerMove(evt)}
                    >
                        <CardCard
                            globalState={this.props.globalState}
                            cardPageMode={true}
                            showBackOnly={this.state.showBackOnly}
                            card={card}
                            width={280}
                            disableAnimation={this.state.disableCardAnimation}
                            onFullscreenMode={(enabled) => this.onFullscreenMode(enabled)}
                        />
                        <div className={'card-page-elements' + (this.state.cardInFullScreenMode ? ' fullscreen' : '')}>
                            {this.state.showRulings &&
                                card.rulings.map((r, i) => {
                                    return (
                                        <div key={i} className="card-element card-ruling">
                                            {r.text}
                                        </div>
                                    );
                                })}
                            {!this.state.showRulings && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('Count')}
                                    value={card.count.toString()}
                                />
                            )}
                            {!this.state.showRulings && card.canBeFoil && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('Foils')}
                                    value={card.foilCount.toString()}
                                />
                            )}
                            {!this.state.showRulings && card.canBeSpecialFoil && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('SpecialFoils')}
                                    value={card.specialFoilCount.toString()}
                                />
                            )}
                            {!this.state.showRulings && card.toTrade > 0 && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('ToTrade')}
                                    value={card.toTrade.toString()}
                                />
                            )}
                            {!this.state.showRulings && card.wanted > 0 && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('Wanted')}
                                    value={card.wanted.toString()}
                                />
                            )}
                            {!this.state.showRulings && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('FoilState')}
                                    value={card.getFoilState(this.props.globalState)}
                                />
                            )}
                            {!this.state.showRulings && card.canBeStandard && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('AveragePrice')}
                                    value={PriceTools.Format(card.price, this.props.globalState)}
                                />
                            )}
                            {!this.state.showRulings && card.canBeFoil && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('AverageFoilPrice')}
                                    value={PriceTools.Format(card.foilPrice, this.props.globalState)}
                                />
                            )}
                            {!this.state.showRulings && card.count > 0 && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('CollectionValue')}
                                    value={PriceTools.Format(card.collectionValue, this.props.globalState)}
                                />
                            )}
                            {!this.state.showRulings && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('Number')}
                                    value={card.number.toString()}
                                />
                            )}
                            {!this.state.showRulings && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('DatabaseID')}
                                    value={card.id.toString()}
                                />
                            )}
                            {!this.state.showRulings && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('Color_')}
                                    value={color.name}
                                    searchProperty="colors"
                                    searchValue={[card.colorId]}
                                    image={'/images/symbols/' + color.key + '.png'}
                                />
                            )}
                            {!this.state.showRulings && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('Author_')}
                                    value={card.author.toString()}
                                    searchProperty="author"
                                    searchValue={card.author}
                                />
                            )}
                            {!this.state.showRulings && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('Rarity_')}
                                    value={CardRarity.GetRarityById(card.rarityId).name}
                                    searchProperty="rarities"
                                    searchValue={[card.rarityId]}
                                />
                            )}
                            {!this.state.showRulings && card.manaCost && (
                                <CardTextWithManaLine
                                    globalState={gs}
                                    label={translate('Manacost_')}
                                    value={card.manaCost}
                                />
                            )}
                            {!this.state.showRulings && card.manaCost && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('ConvertedManaCost')}
                                    value={card.convertedManaCost.toString()}
                                />
                            )}
                            {!this.state.showRulings && card.power && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('Power_')}
                                    value={card.power.toString()}
                                />
                            )}
                            {!this.state.showRulings && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('Reprints')}
                                    value={translate('SellAll') + ` (${card.reprints.length})`}
                                    searchProperty="cardIds"
                                    searchValue={card.reprints.map((c) => c.id)}
                                />
                            )}
                            {!this.state.showRulings && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('Link')}
                                    value="Gatherer"
                                    externalLink={card.gathererLink}
                                />
                            )}
                            {!this.state.showRulings && card.languages && card.count > 0 && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('Language')}
                                    value={card.languages}
                                />
                            )}
                            {!this.state.showRulings && card.condition && card.count > 0 && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('Condition')}
                                    value={card.condition}
                                />
                            )}
                            {!this.state.showRulings && dates && card.count > 0 && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('Added')}
                                    value={dates}
                                />
                            )}
                            {!this.state.showRulings && card.grading && card.count > 0 && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('Grading')}
                                    value={card.grading}
                                />
                            )}
                            {!this.state.showRulings && card.comments && (
                                <CardTextMultiLine
                                    globalState={gs}
                                    label={translate('Comments')}
                                    value={card.comments}
                                />
                            )}
                            {!this.state.showRulings && card.tags && card.tags.length > 0 && (
                                <CardTextMultiLine
                                    globalState={gs}
                                    label={translate('Tags')}
                                    value={card.tags.join(', ')}
                                />
                            )}
                            {!this.state.showRulings && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('Type_')}
                                    value={card.type}
                                    searchProperty="types"
                                    searchValue={[card.type.toLowerCase()]}
                                />
                            )}
                            {!this.state.showRulings && (
                                <CardTextMultiLine globalState={gs} label={translate('Text_')} value={card.text} />
                            )}
                            {!this.state.showRulings && card.flavor && (
                                <CardTextMultiLine
                                    globalState={gs}
                                    label={translate('Flavor_')}
                                    value={card.flavor}
                                    italics={true}
                                />
                            )}
                            {!this.state.showRulings && decks && (
                                <CardTextMultiLine globalState={gs} label={translate('Decks_')} value={decks} />
                            )}
                            {!this.state.showRulings && (
                                <CardTextMultiLine
                                    globalState={gs}
                                    label={translate('ValidFor')}
                                    value={card.validFor}
                                />
                            )}
                            {!this.state.showRulings && (
                                <CardTextLine
                                    globalState={gs}
                                    label={translate('Block')}
                                    value={card.set.block.name}
                                    searchProperty="block"
                                    searchValue={card.set.block.id}
                                />
                            )}
                            {!this.state.showRulings && (
                                <div className="card-element-multiline">
                                    <div className="card-element-multiline-label">{translate('Expansion_')}</div>
                                    <div className={'card-element-multiline-value'}>
                                        <SetCard globalState={gs} set={card.set} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
