import { ICard } from '../entities/ICard';
import { CardColor } from '../entities/cardColor';
import { CardSuperType } from '../entities/cardSuperType';
import { GlobalState } from '../globalState';
import { SecureJSON } from './secureJSON';

class SortDefinition {
    func: (a: ICard, b: ICard) => number;
    order?: number;
}

export class CustomSortDefinition {
    sortIndex: number;
    asc: boolean;
}

export class SortManager {
    private static SortByYear(a: ICard, b: ICard) {
        if (a.set === b.set) {
            return 0;
        }

        if (a.set.year === b.set.year) {
            if (a.set.month === b.set.month) {
                if (a.set.block === b.set.block) {
                    if (a.set.orderInBlock > b.set.orderInBlock) {
                        return 1;
                    }
                    return -1;
                }

                if (a.set.block.index > b.set.block.index) {
                    return 1;
                }

                return -1;
            }

            if (a.set.month > b.set.month) {
                return 1;
            }

            return -1;
        }

        if (a.set.year > b.set.year) {
            return 1;
        }

        return -1;
    }

    private static SortByNumber(a: ICard, b: ICard) {
        if (a.number === b.number) {
            if (a.set !== b.set) {
                return 0;
            }

            if (a.tag === b.tag) {
                return a.name.localeCompare(b.name);
            }

            return a.tag.localeCompare(b.tag);
        }

        if (a.number > b.number) {
            return 1;
        }

        return -1;
    }

    private static SortByName(a: ICard, b: ICard) {
        return a.name.localeCompare(b.name);
    }

    private static SortByDate(a: ICard, b: ICard) {
        if (a.dates.length === 0 && b.dates.length === 0) {
            return 0;
        }

        if (a.dates.length === 0) {
            return 1;
        }

        if (b.dates.length === 0) {
            return -1;
        }

        const dateA = a.dates.reduce((prev, current) => prev < current ? prev : current);
        const dateB = b.dates.reduce((prev, current) => prev < current ? prev : current);

        if (dateA === dateB) {
            return 0;
        }

        if (dateA > dateB) {
            return 1;
        }

        return -1;
    }

    private static SortByManaCost(a: ICard, b: ICard) {
        if (a.convertedManaCost === b.convertedManaCost) {
            return 0;
        }

        if (a.convertedManaCost > b.convertedManaCost) {
            return 1;
        }

        return -1;
    }

    private static SortByPrice(a: ICard, b: ICard) {
        const valueA = (a.foilCount > 0 || a.specialFoilCount > 0) && GlobalState.UseFoilPriceWhenOwned ? a.foilPrice : a.price;
        const valueB = (b.foilCount > 0 || b.specialFoilCount > 0) && GlobalState.UseFoilPriceWhenOwned ? b.foilPrice : b.price;

        if (valueA === valueB) {
            return 0;
        }

        if (valueA > valueB) {
            return 1;
        }

        return -1;
    }

    private static SortByType(a: ICard, b: ICard) {
        return a.type.localeCompare(b.type);
    }

    private static SortBySuperType(a: ICard, b: ICard) {
        let superTypeA = '';
        let superTypeB = '';

        for (const superType of CardSuperType.SuperTypes) {
            if (!superTypeA && superType.predicate(a)) {
                superTypeA = superType.name;
            }
            if (!superTypeB && superType.predicate(b)) {
                superTypeB = superType.name;
            }
        }

        return superTypeA.localeCompare(superTypeB);
    }

    private static SortByColor(a: ICard, b: ICard) {
        const colorA = CardColor.GetColorById(a.colorId);
        const colorB = CardColor.GetColorById(b.colorId);

        if (colorA.sortOrder === colorB.sortOrder) {
            return 0;
        }
        if (colorA.sortOrder > colorB.sortOrder) {
            return 1;
        }
        return -1;
    }

    private static SortByCount(a: ICard, b: ICard) {
        const valueA = a.count;
        const valueB = b.count;
        if (valueA === valueB) {
            return 0;
        }

        if (valueA > valueB) {
            return 1;
        }

        return -1;
    }

    private static SortByRarity(a: ICard, b: ICard) {
        const valueA = a.rarityId;
        const valueB = b.rarityId;
        if (valueA === valueB) {
            return 0;
        }

        if (valueA > valueB) {
            return 1;
        }

        return -1;
    }

    private static SortByEdition(a: ICard, b: ICard) {
        return a.set.name.localeCompare(b.set.name);
    }

    private static SortByCollectionValue(a: ICard, b: ICard) {
        const valueA = a.price * (a.count - a.foilCount) + a.foilPrice * a.foilCount;
        const valueB = b.price * (b.count - b.foilCount) + b.foilPrice * b.foilCount;
        if (valueA === valueB) {
            return 0;
        }

        if (valueA > valueB) {
            return 1;
        }

        return -1;
    }

    private static Compare(a: ICard, b: ICard, sortFunctions: SortDefinition[], sortIndex: number = 0): number {
        const compareValue = sortFunctions[sortIndex].func(a, b);

        if (compareValue === 0 && sortIndex < sortFunctions.length - 1) {
            return this.Compare(a, b, sortFunctions, sortIndex + 1);
        }

        return compareValue * (sortFunctions[sortIndex].order || 1);
    }

    public static Sort(cards: ICard[], sortIndex: number, globalState: GlobalState) {
        const sortPriorities: SortDefinition[] = [];

        switch (sortIndex) {
            case 0:
                sortPriorities.push({ func: this.SortByNumber });
                break;
            case 1:
            case 6:
                sortPriorities.push({ func: this.SortByName });
                break;
            case 2:
                sortPriorities.push({ func: this.SortByManaCost });
                sortPriorities.push({ func: this.SortByName });
                break;
            case 3:
                sortPriorities.push({ func: this.SortByManaCost, order: -1 });
                sortPriorities.push({ func: this.SortByName });
                break;
            case 4:
                sortPriorities.push({ func: this.SortByPrice });
                sortPriorities.push({ func: this.SortByName });
                break;
            case 5:
                sortPriorities.push({ func: this.SortByPrice, order: -1 });
                sortPriorities.push({ func: this.SortByName });
                break;
            case 7:
                sortPriorities.push({ func: this.SortByEdition });
                sortPriorities.push({ func: this.SortByNumber });
                break;
            case 8:
                sortPriorities.push({ func: this.SortByYear });
                sortPriorities.push({ func: this.SortByNumber });
                break;
            case 9:
                sortPriorities.push({ func: this.SortByYear, order: -1 });
                sortPriorities.push({ func: this.SortByNumber });
                break;
            case 10:
                sortPriorities.push({ func: this.SortByCollectionValue });
                sortPriorities.push({ func: this.SortByName });
                break;
            case 11:
                sortPriorities.push({ func: this.SortByCollectionValue, order: -1 });
                sortPriorities.push({ func: this.SortByName });
                break;
            case 12:
                sortPriorities.push({ func: this.SortByType });
                sortPriorities.push({ func: this.SortByName });
                break;
            case 13:
                sortPriorities.push({ func: this.SortByType, order: -1 });
                sortPriorities.push({ func: this.SortByName });
                break;
            case 14:
                sortPriorities.push({ func: this.SortByColor });
                sortPriorities.push({ func: this.SortBySuperType });
                sortPriorities.push({ func: this.SortByName });
                break;
            case 15:
                sortPriorities.push({ func: this.SortByColor, order: -1 });
                sortPriorities.push({ func: this.SortBySuperType, order: -1 });
                sortPriorities.push({ func: this.SortByName });
                break;
            case 16:
                sortPriorities.push({ func: this.SortBySuperType });
                sortPriorities.push({ func: this.SortByName });
                break;
            case 17:
                sortPriorities.push({ func: this.SortBySuperType, order: -1 });
                sortPriorities.push({ func: this.SortByName });
                break;
            case 18:
                sortPriorities.push({ func: this.SortByCount });
                sortPriorities.push({ func: this.SortByName });
                break;
            case 19:
                sortPriorities.push({ func: this.SortByCount, order: -1 });
                sortPriorities.push({ func: this.SortByName });
                break;
            case 21:
                sortPriorities.push({ func: this.SortByDate });
                sortPriorities.push({ func: this.SortByName });
                break;
            case 22:
                sortPriorities.push({ func: this.SortByDate, order: -1 });
                sortPriorities.push({ func: this.SortByName });
                break;
            case 20:
                if (!GlobalState.DoesSettingsExist('CustomSort')) {
                    globalState.onError.notifyObservers(globalState.translate('NeedToConfigureCustomSort'));
                    sortPriorities.push({ func: this.SortByName });
                } else {
                    const sorts = SecureJSON.Parse<CustomSortDefinition[]>(GlobalState.LoadSettings('CustomSort'));

                    if (!sorts) {
                        sortPriorities.push({ func: this.SortByName });
                    } else {
                        for (const sort of sorts) {
                            let func: (a: ICard, b: ICard) => number;

                            switch (sort.sortIndex) {
                                case 0:
                                    func = this.SortByName;
                                    break;
                                case 1:
                                    func = this.SortByNumber;
                                    break;
                                case 2:
                                    func = this.SortByManaCost;
                                    break;
                                case 3:
                                    func = this.SortByColor;
                                    break;
                                case 4:
                                    func = this.SortByType;
                                    break;
                                case 5:
                                    func = this.SortBySuperType;
                                    break;
                                case 6:
                                    func = this.SortByPrice;
                                    break;
                                case 7:
                                    func = this.SortByEdition;
                                    break;
                                case 8:
                                    func = this.SortByYear;
                                    break;
                                case 9:
                                    func = this.SortByCollectionValue;
                                    break;
                                case 10:
                                    func = this.SortByCount;
                                    break;
                                case 11:
                                    func = this.SortByRarity;
                                    break;
                                default:
                                    func = this.SortByName;
                                    break;
                            }

                            sortPriorities.push({ func: func, order: sort.asc ? 1 : -1 });
                        }
                    }
                }
                break;
            default:
                sortPriorities.push({ func: this.SortByName });
        }

        cards.sort((a, b) => this.Compare(a, b, sortPriorities));

        return;
    }
}
