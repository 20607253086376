import * as React from 'react';

export class ManaTools {
    public static GenerateSymbols(value: string) {
        const images: string[] = [];
        const multiregex = /\({\)\((.)\)\(\/\)\((.)\)\(}\)/g;

        let matches = multiregex.exec(value);

        if (matches) {
            while (matches) {
                const symbol = matches[1] + matches[2];

                images.push(`/images/symbols/${symbol}.png`);

                matches = multiregex.exec(value);
            }
        } else {
            const regex = /\((.+?)\)/g;

            matches = regex.exec(value);
            while (matches) {
                let symbol = matches[1];

                switch (symbol) {
                    case 'B':
                        symbol = 'Black';
                        break;
                    case 'U':
                        symbol = 'Blue';
                        break;
                    case 'G':
                        symbol = 'Green';
                        break;
                    case 'W':
                        symbol = 'White';
                        break;
                    case 'R':
                        symbol = 'Red';
                        break;
                }

                images.push(`/images/symbols/${symbol}.png`);
                matches = regex.exec(value);
            }
        }

        if (!images.length) {
            return null;
        }

        return images.map((i, index) => {
            return <img key={index} src={i} className="card-element-mana" />;
        });
    }
}
