import * as React from 'react';
import { GlobalState, SearchData } from '../globalState';
import { Nullable } from '../tools/nullable';
import { Observer } from '../tools/observable';
import { Collection } from '../entities/collection';
import { RangeSlider } from '../controls/rangeSlider';
import { LabelledTexbox } from '../controls/labelledTextbox';
import { LabelledListbox } from '../controls/labelledListbox';
import { CardFormat } from '../entities/cardFormat';
import { MultiSelect } from '../controls/multiSelect';
import { CardColor } from '../entities/cardColor';
import { CardRarity } from '../entities/cardRarity';
import { PriceTools } from '../tools/priceTools';
import { CardSuperType } from '../entities/cardSuperType';
import { LabelledCheckbox } from '../controls/labelledCheckbox';
import { SecureJSON } from '../tools/secureJSON';
import { LabelledTagPicker } from '../controls/labelledTagPicker';
import { CustomIcon } from '../entities/customIcon';
import { CardLanguage } from '../entities/cardLanguage';
import { CardGrading } from '../entities/cardGrading';
import { CardCondition } from '../entities/cardCondition';
import { LabelledDatePicker } from '../controls/labelledDatePicker';

require('../scss/searchPage.scss');

interface ISearchPageProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export class SearchPage extends React.Component<
    ISearchPageProps,
    {
        name?: string;
        number?: number;
        nameRegex?: string;
        regex?: string;
        exclude?: string[];
        manaSymbols?: string;
        fulltext?: string;
        flavor?: string;
        comment?: string;
        author?: string;
        types?: string[];
        superTypeIndex: number;
        excludedSuperTypeIndex: number;
        texts?: string[];
        expansions?: string[];
        blocks?: string[];
        price: number[];
        power: number[];
        toughness: number[];
        manacost: number[];
        year: number[];
        format: string;
        stateIndex: number;
        proxiesIndex: number;
        languageIndex: number;
        gradingIndex: number;
        conditionIndex: number;
        addedBefore?: Date;
        addedAfter?: Date;
        customIconIndex: number;
        collectionIndex: number;
        quantityIndex: number;
        reprintIndex: number;
        miscIndex: number;
        dfcIndex: number;
        tags: string[];
        colors: number[];
        multicolors: string[];
        manaSources: string[];
        rarities: number[];
        deckIndex: number;
        quantity: number[];
        quantityToTrade: number[];
        wantedQuantity: number[];
        mergeReprints: boolean;
        mergeReprintsForQuantity: boolean;
        hideReprints: boolean;
        colorIdentities: string[];
        setCode: string;
    }
> {
    private onSearchObserver: Nullable<Observer<void>>;
    private onResetObserver: Nullable<Observer<void>>;
    private onSaveObserver: Nullable<Observer<void>>;
    private onSaveSearchSelectedObserver: Nullable<Observer<string>>;
    private authors: string[];
    private scroller: HTMLElement;

    constructor(props: ISearchPageProps) {
        super(props);

        const searchData = this.props.globalState.pageCustomData.searchData;

        this.state = {
            name: searchData && searchData.name ? searchData.name : '',
            number: searchData && searchData.number !== undefined ? searchData.number : undefined,
            regex: searchData && searchData.regex ? searchData.regex : '',
            nameRegex: searchData && searchData.nameRegex ? searchData.nameRegex : '',
            exclude: searchData && searchData.exclude ? searchData.exclude : [],
            manaSymbols: searchData && searchData.manaSymbols ? searchData.manaSymbols : '',
            fulltext: searchData && searchData.fulltext ? searchData.fulltext : '',
            flavor: searchData && searchData.flavor ? searchData.flavor : '',
            author: searchData && searchData.author ? searchData.author : '',
            comment: searchData && searchData.comment ? searchData.comment : '',
            types: searchData && searchData.types ? searchData.types : [],
            texts: searchData && searchData.texts ? searchData.texts : [],
            blocks: searchData && searchData.blocks ? searchData.blocks : [],
            expansions: searchData && searchData.expansions ? searchData.expansions : [],
            price: searchData && searchData.price ? searchData.price : [],
            power: searchData && searchData.power ? searchData.power : [],
            toughness: searchData && searchData.toughness ? searchData.toughness : [],
            manacost: searchData && searchData.manacost ? searchData.manacost : [],
            year: searchData && searchData.year ? searchData.year : [],
            format: searchData && searchData.format ? searchData.format : '',
            stateIndex: searchData && searchData.stateIndex ? searchData.stateIndex : 0,
            proxiesIndex: searchData && searchData.proxiesIndex ? searchData.proxiesIndex : 0,
            languageIndex: searchData && searchData.languageIndex ? searchData.languageIndex : 0,
            gradingIndex: searchData && searchData.gradingIndex ? searchData.gradingIndex : 0,
            conditionIndex: searchData && searchData.conditionIndex ? searchData.conditionIndex : 0,
            addedBefore: searchData && searchData.addedBefore ? searchData.addedBefore : undefined,
            addedAfter: searchData && searchData.addedAfter ? searchData.addedAfter : undefined,
            customIconIndex: searchData && searchData.customIconIndex ? searchData.customIconIndex : 0,
            collectionIndex: searchData && searchData.collectionIndex ? searchData.collectionIndex : 0,
            quantityIndex: searchData && searchData.quantityIndex ? searchData.quantityIndex : 0,
            reprintIndex: searchData && searchData.reprintIndex ? searchData.reprintIndex : 0,
            miscIndex: searchData && searchData.miscIndex ? searchData.miscIndex : 0,
            dfcIndex: searchData && searchData.dfcIndex ? searchData.dfcIndex : 0,
            tags: searchData && searchData.tags ? searchData.tags : [],
            colors: searchData && searchData.colors ? searchData.colors : [],
            colorIdentities: searchData && searchData.colorIdentities ? searchData.colorIdentities : [],
            multicolors: searchData && searchData.multicolors ? searchData.multicolors : [],
            manaSources: searchData && searchData.manaSources ? searchData.manaSources : [],
            rarities: searchData && searchData.rarities ? searchData.rarities : [],
            deckIndex: searchData && searchData.deckIndex ? searchData.deckIndex : 0,
            superTypeIndex: searchData && searchData.superTypeIndex ? searchData.superTypeIndex : 0,
            excludedSuperTypeIndex:
                searchData && searchData.excludedSuperTypeIndex ? searchData.excludedSuperTypeIndex : 0,
            quantity: searchData && searchData.quantity ? searchData.quantity : [],
            quantityToTrade: searchData && searchData.quantityToTrade ? searchData.quantityToTrade : [],
            wantedQuantity: searchData && searchData.wantedQuantity ? searchData.wantedQuantity : [],
            mergeReprints: searchData && searchData.mergeReprints ? searchData.mergeReprints : false,
            mergeReprintsForQuantity:
                searchData && searchData.mergeReprintsForQuantity ? searchData.mergeReprintsForQuantity : false,
            hideReprints: searchData && searchData.hideReprints ? searchData.hideReprints : false,
            setCode: searchData && searchData.setCode ? searchData.setCode : ''
        };

        this.authors = Collection.Authors.slice();

        if (this.state.price.length === 0) {
            this.state.price.push(0);
            this.state.price.push(Collection.MaxPrice);
        }

        if (this.state.manacost.length === 0) {
            this.state.manacost.push(Collection.MinManacost);
            this.state.manacost.push(Collection.MaxManacost);
        }

        if (this.state.power.length === 0) {
            this.state.power.push(Collection.MinPower);
            this.state.power.push(Collection.MaxPower);
        }

        if (this.state.toughness.length === 0) {
            this.state.toughness.push(Collection.MinToughness);
            this.state.toughness.push(Collection.MaxToughness);
        }

        if (this.state.year.length === 0) {
            this.state.year.push(Collection.MinYear);
            this.state.year.push(Collection.MaxYear);
        }

        if (this.state.quantity.length === 0) {
            this.state.quantity.push(0);
            this.state.quantity.push(GlobalState.MaxCardCount);
        }

        if (this.state.quantityToTrade.length === 0) {
            this.state.quantityToTrade.push(0);
            this.state.quantityToTrade.push(GlobalState.MaxCardCount);
        }

        if (this.state.wantedQuantity.length === 0) {
            this.state.wantedQuantity.push(0);
            this.state.wantedQuantity.push(GlobalState.MaxCardCount);
        }

        if (this.authors[0]) {
            this.authors.splice(0, 0, '');
        }
    }

    onScroll() {
        this.props.globalState.pageCustomData.scroll = this.scroller.scrollTop;
    }

    loadStateFromSearchData(searchData: SearchData) {
        this.setState({
            name: searchData.name ? searchData.name : '',
            number: searchData.number !== undefined ? searchData.number : undefined,
            regex: searchData.regex ? searchData.regex : '',
            exclude: searchData.exclude ? searchData.exclude : [],
            manaSymbols: searchData.manaSymbols ? searchData.manaSymbols : '',
            fulltext: searchData.fulltext ? searchData.fulltext : '',
            comment: searchData.comment ? searchData.comment : '',
            flavor: searchData.flavor ? searchData.flavor : '',
            author: searchData.author ? searchData.author : '',
            types: searchData.types ? searchData.types : [],
            texts: searchData.texts ? searchData.texts : [],
            blocks: searchData.blocks ? searchData.blocks : [],
            expansions: searchData.expansions ? searchData.expansions : [],
            price: searchData.price ? searchData.price : [],
            power: searchData.power ? searchData.power : [],
            toughness: searchData.toughness ? searchData.toughness : [],
            manacost: searchData.manacost ? searchData.manacost : [],
            year: searchData.year ? searchData.year : [],
            format: searchData.format ? searchData.format : '',
            stateIndex: searchData.stateIndex ? searchData.stateIndex : 0,
            proxiesIndex: searchData.proxiesIndex ? searchData.proxiesIndex : 0,
            languageIndex: searchData.languageIndex ? searchData.languageIndex : 0,
            gradingIndex: searchData.gradingIndex ? searchData.gradingIndex : 0,
            conditionIndex: searchData.conditionIndex ? searchData.conditionIndex : 0,
            addedBefore: searchData.addedBefore ? new Date(searchData.addedBefore) : undefined,
            addedAfter: searchData.addedAfter ? new Date(searchData.addedAfter) : undefined,
            customIconIndex: searchData.customIconIndex ? searchData.customIconIndex : 0,
            collectionIndex: searchData.collectionIndex ? searchData.collectionIndex : 0,
            quantityIndex: searchData.quantityIndex ? searchData.quantityIndex : 0,
            reprintIndex: searchData.reprintIndex ? searchData.reprintIndex : 0,
            miscIndex: searchData.miscIndex ? searchData.miscIndex : 0,
            dfcIndex: searchData.dfcIndex ? searchData.dfcIndex : 0,
            tags: searchData.tags ? searchData.tags : [],
            colors: searchData.colors ? searchData.colors : [],
            multicolors: searchData.multicolors ? searchData.multicolors : [],
            manaSources: searchData.manaSources ? searchData.manaSources : [],
            colorIdentities: searchData.colorIdentities ? searchData.colorIdentities : [],
            rarities: searchData.rarities ? searchData.rarities : [],
            deckIndex: searchData.deckIndex ? searchData.deckIndex : 0,
            superTypeIndex: searchData.superTypeIndex ? searchData.superTypeIndex : 0,
            excludedSuperTypeIndex: searchData.excludedSuperTypeIndex ? searchData.excludedSuperTypeIndex : 0,
            quantity: searchData.quantity ? searchData.quantity : [],
            quantityToTrade: searchData.quantityToTrade ? searchData.quantityToTrade : [],
            wantedQuantity: searchData.wantedQuantity ? searchData.wantedQuantity : [],
            mergeReprints: searchData.mergeReprints ? searchData.mergeReprints : false,
            mergeReprintsForQuantity: searchData.mergeReprintsForQuantity ? searchData.mergeReprintsForQuantity : false,
            hideReprints: searchData.hideReprints ? searchData.hideReprints : false,
            setCode: searchData.setCode ? searchData.setCode : ''
        });
    }

    buildSearchData() {
        const searchData = new SearchData();

        searchData.name = this.state.name;
        searchData.number = this.state.number;
        searchData.regex = this.state.regex;
        searchData.nameRegex = this.state.nameRegex;
        searchData.exclude = this.state.exclude;
        searchData.manaSymbols = this.state.manaSymbols;
        searchData.fulltext = this.state.fulltext;
        searchData.comment = this.state.comment;
        searchData.flavor = this.state.flavor;
        searchData.author = this.state.author;
        searchData.types = this.state.types;
        searchData.texts = this.state.texts;
        searchData.types = this.state.types;
        searchData.blocks = this.state.blocks;
        searchData.expansions = this.state.expansions;
        searchData.price = this.state.price;
        searchData.manacost = this.state.manacost;
        searchData.manaCostIsMaxRange =
            this.state.manacost[0] === Collection.MinManacost && this.state.manacost[1] === Collection.MaxManacost;
        searchData.power = this.state.power;
        searchData.checkPower =
            this.state.power[0] !== Collection.MinPower || this.state.power[1] !== Collection.MaxPower;
        searchData.toughness = this.state.toughness;
        searchData.checkToughness =
            this.state.toughness[0] !== Collection.MinToughness || this.state.toughness[1] !== Collection.MaxToughness;
        searchData.year = this.state.year;
        searchData.checkYear = this.state.year[0] !== Collection.MinYear || this.state.year[1] !== Collection.MaxYear;
        searchData.format = this.state.format;
        searchData.setCode = this.state.setCode;
        searchData.stateIndex = this.state.stateIndex;
        searchData.proxiesIndex = this.state.proxiesIndex;
        searchData.languageIndex = this.state.languageIndex;
        searchData.gradingIndex = this.state.gradingIndex;
        searchData.conditionIndex = this.state.conditionIndex;
        searchData.addedBefore = this.state.addedBefore;
        searchData.addedAfter = this.state.addedAfter;
        searchData.customIconIndex = this.state.customIconIndex;
        searchData.collectionIndex = this.state.collectionIndex;
        searchData.quantityIndex = this.state.quantityIndex;
        searchData.reprintIndex = this.state.reprintIndex;
        searchData.miscIndex = this.state.miscIndex;
        searchData.dfcIndex = this.state.dfcIndex;
        searchData.tags = this.state.tags;
        searchData.colors = this.state.colors;
        searchData.multicolors = this.state.multicolors;
        searchData.manaSources = this.state.manaSources;
        searchData.colorIdentities = this.state.colorIdentities;
        searchData.rarities = this.state.rarities;
        searchData.deckIndex = this.state.deckIndex;
        searchData.superTypeIndex = this.state.superTypeIndex;
        searchData.excludedSuperTypeIndex = this.state.excludedSuperTypeIndex;
        searchData.quantity = this.state.quantity;
        searchData.quantityToTrade = this.state.quantityToTrade;
        searchData.checkQuantity = this.state.quantity[0] !== 0 || this.state.quantity[1] !== GlobalState.MaxCardCount;
        searchData.checkQuantityToTrade = this.state.quantityToTrade[0] !== 0 || this.state.quantityToTrade[1] !== GlobalState.MaxCardCount;
        searchData.wantedQuantity = this.state.wantedQuantity;
        searchData.checkWantedQuantity = this.state.wantedQuantity[0] !== 0 || this.state.wantedQuantity[1] !== GlobalState.MaxCardCount;
        searchData.mergeReprints = this.state.mergeReprints;
        searchData.mergeReprintsForQuantity = this.state.mergeReprintsForQuantity;
        searchData.hideReprints = this.state.hideReprints;

        return searchData;
    }

    doSearch() {
        const searchData = this.buildSearchData();

        this.props.globalState.pageCustomData.searchData = searchData;

        this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({ index: -3, searchData: searchData });
    }

    UNSAFE_componentWillMount() {
        this.onSearchObserver = this.props.globalState.onSearch.add(() => {
            this.doSearch();
        });
        this.onResetObserver = this.props.globalState.onReset.add(() => {
            this.reset();
        });
        this.onSaveObserver = this.props.globalState.onSave.add(() => {
            this.save();
        });

        this.onSaveSearchSelectedObserver = this.props.globalState.onSavedSearchesSelected.add((search) => {
            const searchData = SecureJSON.Parse<SearchData>(Collection.UserStoreExt.SavedSearches[search]);

            if (searchData) {
                this.loadStateFromSearchData(searchData);
            }
        });
    }

    componentWillUnmount() {
        this.props.globalState.onSearch.remove(this.onSearchObserver);
        this.props.globalState.onReset.remove(this.onResetObserver);
        this.props.globalState.onSave.remove(this.onSaveObserver);
        this.props.globalState.onSavedSearchesSelected.remove(this.onSaveSearchSelectedObserver);
    }

    componentDidMount() {
        this.scroller = document.getElementById('scrollElement')!;

        const customData = this.props.globalState.pageCustomData;
        const scrollPosition = customData && customData.scroll ? customData.scroll : 0;
        this.scroller.scrollTop = scrollPosition;
    }

    blurTextField(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.which === 13) {
            this.doSearch();
        }
    }

    save() {
        const searchData = this.buildSearchData();

        this.props.globalState.showQuestionDialog(this.props.globalState.translate('Search name'), '').then((value) => {
            if (!value) {
                return;
            }
            Collection.UserStoreExt.SavedSearches[value] = JSON.stringify(searchData);
            Collection.RegisterSaveExt();

            this.props.globalState.onSavedSearchesChanged.notifyObservers();
        });
    }

    reset() {
        this.setState({
            name: '',
            number: undefined,
            regex: '',
            nameRegex: '',
            exclude: [],
            manaSymbols: '',
            fulltext: '',
            comment: '',
            flavor: '',
            author: '',
            types: [],
            texts: [],
            blocks: [],
            expansions: [],
            price: [0, Collection.MaxPrice],
            power: [Collection.MinPower, Collection.MaxPower],
            toughness: [Collection.MinToughness, Collection.MaxToughness],
            manacost: [Collection.MinManacost, Collection.MaxManacost],
            year: [Collection.MinYear, Collection.MaxYear],
            format: '',
            stateIndex: 0,
            proxiesIndex: 0,
            languageIndex: 0,
            gradingIndex: 0,
            conditionIndex: 0,
            addedBefore: undefined,
            addedAfter: undefined,
            customIconIndex: 0,
            collectionIndex: 0,
            quantityIndex: 0,
            colors: [],
            multicolors: [],
            manaSources: [],
            colorIdentities: [],
            rarities: [],
            deckIndex: 0,
            superTypeIndex: 0,
            excludedSuperTypeIndex: 0,
            quantity: [0, GlobalState.MaxCardCount],
            quantityToTrade: [0, GlobalState.MaxCardCount],
            wantedQuantity: [0, GlobalState.MaxCardCount],
            reprintIndex: 0,
            miscIndex: 0,
            dfcIndex: 0,
            tags: [],
            mergeReprints: false,
            mergeReprintsForQuantity: false,
            hideReprints: false,
            setCode: ''
        });
    }

    render() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);

        const customIconOptions = [
            { value: '0', label: translate('Undefined') }
        ];

        let customIconIndex = 1;
        for (const customIcon of CustomIcon.CustomIcons) {
            customIconOptions.push({ value: customIconIndex.toString(), label: customIcon.label });
            customIconIndex++;
        }

        const deckOptions = [
            { value: '0', label: translate('In a deck or not') },
            { value: '1', label: translate('Not used in a deck') },
            { value: '2', label: translate('All decks') },
            { value: '3', label: translate('All active decks') }
        ];

        let deckOptionsIndex = 4;
        for (const deck of Collection.Decks.sort((a, b) => a.Name.localeCompare(b.Name))) {
            deckOptions.push({
                value: deckOptionsIndex.toString(),
                label: deck.Name
            });

            deckOptionsIndex += 1;
        }
        const suggestionForNames = Collection.Cards.map((c) => c.nameEn);

        if (Collection.UseFrenchForTexts) {
            for (const cardName of Collection.Cards.map((c) => c.nameFr)) {
                if (!cardName) {
                    continue;
                }
                suggestionForNames.push(cardName);
            }
        }

        const superTypes = [{ value: '0', label: translate('AllCards') }];

        superTypes.push(
            ...CardSuperType.SuperTypes.map((st) => {
                return { value: st.id.toString(), label: st.name };
            })
        );

        const excludedSuperTypes = [{ value: '0', label: translate('None') }];

        excludedSuperTypes.push(
            ...CardSuperType.SuperTypes.map((st) => {
                return { value: st.id.toString(), label: st.name };
            })
        );

        const formats = CardFormat.CardFormats.map((format) => {
            if (!format.predicate) {
                return (
                    <option key="all" value={''}>
                        {translate('AllFormats')}
                    </option>
                );
            }
            return (
                <option key={format.name} value={format.name}>
                    {format.name}
                </option>
            );
        });

        formats.push(
            ...CardFormat.CardFormats.filter((format) => !!format.predicate).map((format) => {
                return (
                    <option key={'!' + format.name} value={'!' + format.name}>
                        {translate('Exclude') + format.name}
                    </option>
                );
            })
        );

        const uniqueSetCodes = new Set(
            Collection.Sets.sort((a, b) => a.code.localeCompare(b.code)).map((set) => set.code)
        );
        const setCodes = [...uniqueSetCodes].map((code) => {
            return (
                <option key={code} value={code}>
                    {code}
                </option>
            );
        });

        setCodes.splice(
            0,
            0,
            <option key="all" value={''}>
                {translate('AllSetCode')}
            </option>
        );

        const languageOptions = CardLanguage.LanguageKeys.map((language, i) => {
            return (
                { value: (i + 1).toString(), label: translate(language) }
            );
        });

        languageOptions.splice(
            0,
            0,
            { value: '0', label: translate('AllLanguages') }
        );

        const gradingOptions = CardGrading.GradingKeys.map((grading, i) => {
            return (
                { value: (i + 1).toString(), label: grading }
            );
        });

        gradingOptions.splice(
            0,
            0,
            { value: '0', label: translate('AllGradings') }
        );

        const conditionOptions = CardCondition.ConditionKeys.map((grading, i) => {
            return (
                { value: (i + 1).toString(), label: translate(grading) }
            );
        });

        conditionOptions.splice(
            0,
            0,
            { value: '0', label: translate('AllConditions') }
        );

        return (
            <div className="page">
                <div className="search-page" id="scrollElement" onScroll={() => this.onScroll()}>
                    <div className="search-element">
                        <LabelledTexbox
                            label={translate('Name')}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurTextField(evt)}
                            onChange={(value) => {
                                this.setState({ name: value });
                            }}
                            suggestions={suggestionForNames}
                            value={this.state.name}
                            placeholder={translate('AnyWord')}
                        />
                        <div className="sticky"></div>
                        <LabelledTexbox
                            label=""
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurTextField(evt)}
                            onChange={(value) => {
                                this.setState({ nameRegex: value });
                            }}
                            value={this.state.nameRegex}
                            placeholder={translate('AnyWordRegex')}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledTexbox
                            label={translate('Number:')}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurTextField(evt)}
                            onChange={(value) => {
                                const num = parseInt(value);
                                if (isNaN(num)) {
                                    this.setState({ number: undefined });
                                    return;
                                }
                                this.setState({ number: num });
                            }}
                            value={this.state.number ? this.state.number.toString() : ''}
                            placeholder={translate('AnyNumber')}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledTexbox
                            label={translate('Type')}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurTextField(evt)}
                            onChange={(value) => {
                                try {
                                    const split = value.split(',');
                                    this.setState({ types: split });
                                } catch {
                                    this.setState({ types: [] });
                                }
                            }}
                            value={this.state.types ? this.state.types.join(',') : ''}
                            placeholder={translate('ValuesWithComma')}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledListbox
                            value={this.state.superTypeIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('SuperType')}
                            options={superTypes}
                            onChange={(value) => {
                                this.setState({ superTypeIndex: parseInt(value) });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledListbox
                            value={this.state.excludedSuperTypeIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('Exclude_')}
                            options={excludedSuperTypes}
                            onChange={(value) => {
                                this.setState({ excludedSuperTypeIndex: parseInt(value) });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledTexbox
                            label={translate('Text')}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurTextField(evt)}
                            onChange={(value) => {
                                try {
                                    const split = value.split(',');
                                    this.setState({ texts: split });
                                } catch {
                                    this.setState({ texts: [] });
                                }
                            }}
                            value={this.state.texts ? this.state.texts.join(',') : ''}
                            placeholder={translate('ValuesWithComma')}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledTexbox
                            label={translate('Flavor')}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurTextField(evt)}
                            onChange={(value) => {
                                this.setState({ flavor: value });
                            }}
                            value={this.state.flavor}
                            placeholder={translate('AnyWord')}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledTexbox
                            label={translate('Block')}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurTextField(evt)}
                            onChange={(value) => {
                                try {
                                    const split = value.split(',');
                                    this.setState({ blocks: split });
                                } catch {
                                    this.setState({ blocks: [] });
                                }
                            }}
                            suggestions={Collection.Blocks.map((b) => b.name)}
                            value={this.state.blocks ? this.state.blocks.join(',') : ''}
                            placeholder={translate('ValuesWithComma')}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledTexbox
                            label={translate('Set')}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurTextField(evt)}
                            onChange={(value) => {
                                try {
                                    let split = value.replace(/40,000/g, '40000').split(',');
                                    split = split.map(s => {
                                        return s.replace('40000', '40,000');
                                    });
                                    this.setState({ expansions: split });
                                } catch {
                                    this.setState({ expansions: [] });
                                }
                            }}
                            suggestions={Collection.Sets.map((e) => e.name)}
                            value={this.state.expansions ? this.state.expansions.join(',') : ''}
                            placeholder={translate('ValuesWithComma')}
                        />
                    </div>

                    <div className="search-element">
                        <LabelledListbox
                            value={this.state.setCode}
                            globalState={this.props.globalState}
                            label={translate('SetCode')}
                            mapFunction={() => setCodes}
                            onChange={(value) => {
                                this.setState({ setCode: value });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledTexbox
                            label={translate('Comments_')}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurTextField(evt)}
                            onChange={(value) => {
                                this.setState({ comment: value });
                            }}
                            value={this.state.comment}
                            placeholder={translate('AnyWord')}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledTexbox
                            label={translate('Fulltext')}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurTextField(evt)}
                            onChange={(value) => {
                                this.setState({ fulltext: value });
                            }}
                            value={this.state.fulltext}
                            placeholder={translate('AnyWord')}
                        />
                        <div className="sticky"></div>
                        <LabelledTexbox
                            label=""
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurTextField(evt)}
                            onChange={(value) => {
                                this.setState({ regex: value });
                            }}
                            value={this.state.regex}
                            placeholder={translate('AnyWordRegex')}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledTexbox
                            label={translate('Exclude_')}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurTextField(evt)}
                            onChange={(value) => {
                                try {
                                    const split = value.split(',');
                                    this.setState({ exclude: split });
                                } catch {
                                    this.setState({ exclude: [] });
                                }
                            }}
                            value={this.state.exclude ? this.state.exclude.join(',') : ''}
                            placeholder={translate('ValuesWithComma')}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledListbox
                            value={this.state.author}
                            globalState={this.props.globalState}
                            label={translate('Author')}
                            mapFunction={() => {
                                return this.authors.map((author) => {
                                    if (!author) {
                                        return (
                                            <option key="all" value={''}>
                                                {translate('AllAuthors')}
                                            </option>
                                        );
                                    }
                                    return (
                                        <option key={author} value={author}>
                                            {author}
                                        </option>
                                    );
                                });
                            }}
                            onChange={(value) => {
                                this.setState({ author: value });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledTexbox
                            label={translate('ManaSymbols')}
                            globalState={this.props.globalState}
                            onKeyPress={(evt) => this.blurTextField(evt)}
                            onChange={(value) => {
                                this.setState({ manaSymbols: value });
                            }}
                            value={this.state.manaSymbols}
                            placeholder={translate('AnyManaSymbols')}
                        />
                    </div>
                    <div className="search-element">
                        <RangeSlider
                            globalState={this.props.globalState}
                            label={translate('Price')}
                            onChange={(vMin, vMax) => {
                                const min = PriceTools.FromLocalCurrency(vMin, this.props.globalState);
                                const max = PriceTools.FromLocalCurrency(vMax, this.props.globalState);
                                this.setState({ price: [min, max] });
                            }}
                            min={0}
                            max={PriceTools.ToLocalCurrency(Collection.MaxPrice, this.props.globalState)}
                            valueLeft={PriceTools.ToLocalCurrency(this.state.price[0], this.props.globalState)}
                            valueRight={PriceTools.ToLocalCurrency(this.state.price[1], this.props.globalState)}
                        />
                    </div>
                    <div className="search-element">
                        <RangeSlider
                            globalState={this.props.globalState}
                            label={translate('Manacost')}
                            onChange={(min, max) => {
                                this.setState({ manacost: [min, max] });
                            }}
                            isInteger={true}
                            min={Collection.MinManacost}
                            max={Collection.MaxManacost}
                            valueLeft={this.state.manacost[0]}
                            valueRight={this.state.manacost[1]}
                        />
                    </div>
                    <div className="search-element">
                        <RangeSlider
                            globalState={this.props.globalState}
                            label={translate('Power')}
                            onChange={(min, max) => {
                                this.setState({ power: [min, max] });
                            }}
                            isInteger={true}
                            min={Collection.MinPower}
                            max={Collection.MaxPower}
                            valueLeft={this.state.power[0]}
                            valueRight={this.state.power[1]}
                        />
                    </div>
                    <div className="search-element">
                        <RangeSlider
                            globalState={this.props.globalState}
                            label={translate('Toughness')}
                            onChange={(min, max) => {
                                this.setState({ toughness: [min, max] });
                            }}
                            isInteger={true}
                            min={Collection.MinToughness}
                            max={Collection.MaxToughness}
                            valueLeft={this.state.toughness[0]}
                            valueRight={this.state.toughness[1]}
                        />
                    </div>
                    <div className="search-element">
                        <RangeSlider
                            globalState={this.props.globalState}
                            label={translate('Year')}
                            onChange={(min, max) => {
                                this.setState({ year: [min, max] });
                            }}
                            isInteger={true}
                            min={Collection.MinYear}
                            max={Collection.MaxYear}
                            valueLeft={this.state.year[0]}
                            valueRight={this.state.year[1]}
                        />
                    </div>
                    <div className="search-element">
                        <RangeSlider
                            globalState={this.props.globalState}
                            label={translate('Quantity')}
                            onChange={(min, max) => {
                                this.setState({ quantity: [min, max] });
                            }}
                            isInteger={true}
                            min={0}
                            max={GlobalState.MaxCardCount}
                            valueLeft={this.state.quantity[0]}
                            valueRight={this.state.quantity[1]}
                        />
                    </div>
                    <div className="search-element" style={{
                        marginTop: 0
                    }}>
                        <LabelledListbox
                            value={this.state.quantityIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('QuantityOptions')}
                            options={[
                                { value: '0', label: translate('AllCards') },
                                { value: '1', label: translate('OnlyRegular') },
                                { value: '2', label: translate('OnlyFoil') },
                                { value: '3', label: translate('OnlySpecialFoil') }
                            ]}
                            onChange={(value) => {
                                this.setState({ quantityIndex: parseInt(value) });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <RangeSlider
                            globalState={this.props.globalState}
                            label={translate('ToTrade_')}
                            onChange={(min, max) => {
                                this.setState({ quantityToTrade: [min, max] });
                            }}
                            isInteger={true}
                            min={0}
                            max={GlobalState.MaxCardCount}
                            valueLeft={this.state.quantityToTrade[0]}
                            valueRight={this.state.quantityToTrade[1]}
                        />
                    </div>
                    <div className="search-element">
                        <RangeSlider
                            globalState={this.props.globalState}
                            label={translate('Wanted')}
                            onChange={(min, max) => {
                                this.setState({ wantedQuantity: [min, max] });
                            }}
                            isInteger={true}
                            min={0}
                            max={GlobalState.MaxCardCount}
                            valueLeft={this.state.wantedQuantity[0]}
                            valueRight={this.state.wantedQuantity[1]}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledListbox
                            value={this.state.format}
                            globalState={this.props.globalState}
                            label={translate('Format')}
                            mapFunction={() => formats}
                            onChange={(value) => {
                                this.setState({ format: value });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledListbox
                            value={this.state.collectionIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('Collection')}
                            options={[
                                { value: '0', label: translate('AllCards') },
                                { value: '1', label: translate('OnlyMissing') },
                                { value: '2', label: translate('OnlyInCollection') },
                                { value: '4', label: translate('OnlyInCollectionNonFoil') },
                                { value: '3', label: translate('OnlyInCollectionFoil') },
                                { value: '5', label: translate('OnlyInCollectionSpecialFoil') },
                                { value: '6', label: translate('OnlyMissingExcludeReprints') }
                            ]}
                            onChange={(value) => {
                                this.setState({ collectionIndex: parseInt(value) });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledListbox
                            value={this.state.stateIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('State')}
                            options={[
                                { value: '0', label: translate('AllCardStates') },
                                { value: '6', label: translate('OnlyRegular') },
                                { value: '7', label: translate('FoilNonFoil') },
                                { value: '1', label: translate('OnlyFoil') },
                                { value: '8', label: translate('CanBeSpecialFoil') },
                                { value: '9', label: translate('OnlySpecialFoil') },
                                { value: '10', label: translate('FoilSpecialFoil') },
                                { value: '4', label: translate('ReservedList') },
                                { value: '5', label: translate('OnlyOrdered') }
                            ]}
                            onChange={(value) => {
                                this.setState({ stateIndex: parseInt(value) });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledListbox
                            value={this.state.deckIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('Decks')}
                            options={deckOptions}
                            onChange={(value) => {
                                this.setState({ deckIndex: parseInt(value) });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledListbox
                            value={this.state.reprintIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('Reprints_')}
                            options={[
                                { value: '0', label: translate('AllCards') },
                                { value: '4', label: translate('UniqueOnly') },
                                { value: '1', label: translate('OnlyRecents') },
                                { value: '2', label: translate('OnlyTokens') },
                                { value: '3', label: translate('OnlyPromos') }
                            ]}
                            onChange={(value) => {
                                this.setState({ reprintIndex: parseInt(value) });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledListbox
                            value={this.state.miscIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('Misc_')}
                            options={[
                                { value: '0', label: translate('AllCards') },
                                { value: '1', label: translate('OnlyStorySpotlight') }
                            ]}
                            onChange={(value) => {
                                this.setState({ miscIndex: parseInt(value) });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledListbox
                            value={this.state.dfcIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('DFC_')}
                            options={[
                                { value: '0', label: translate('Include') },
                                { value: '1', label: translate('Exclude') },
                                { value: '2', label: translate('Only') }
                            ]}
                            onChange={(value) => {
                                this.setState({ dfcIndex: parseInt(value) });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledListbox
                            value={this.state.proxiesIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('Proxies')}
                            options={[
                                { value: '0', label: translate('AllCards') },
                                { value: '1', label: translate('OnlyCardProxies') },
                                { value: '2', label: translate('ExcludeCardProxies') },
                                { value: '3', label: translate('OnlyDeckProxies') },
                                { value: '4', label: translate('ExcludeDeckProxies') },
                                { value: '5', label: translate('OnlyProxies') },
                                { value: '6', label: translate('ExcludeProxies') }
                            ]}
                            onChange={(value) => {
                                this.setState({ proxiesIndex: parseInt(value) });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledListbox
                            value={this.state.customIconIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('CustomIconSearch')}
                            options={customIconOptions}
                            onChange={(value) => {
                                this.setState({ customIconIndex: parseInt(value) });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledTagPicker
                            darkMode={this.props.darkMode}
                            value={this.state.tags}
                            globalState={this.props.globalState}
                            label={translate('Tags_')}
                            onChange={(value) => {
                                this.setState({ tags: value });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledListbox
                            value={this.state.languageIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('Language_')}
                            options={languageOptions}
                            onChange={(value) => {
                                this.setState({ languageIndex: parseInt(value) });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledListbox
                            value={this.state.gradingIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('Grading_')}
                            options={gradingOptions}
                            onChange={(value) => {
                                this.setState({ gradingIndex: parseInt(value) });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledListbox
                            value={this.state.conditionIndex.toString()}
                            globalState={this.props.globalState}
                            label={translate('Condition_')}
                            options={conditionOptions}
                            onChange={(value) => {
                                this.setState({ conditionIndex: parseInt(value) });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledDatePicker
                            value={this.state.addedBefore}
                            globalState={this.props.globalState}
                            label={translate('AddedBefore')}
                            onChange={(value) => {
                                this.setState({ addedBefore: value });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <LabelledDatePicker
                            value={this.state.addedAfter}
                            globalState={this.props.globalState}
                            label={translate('AddedAfter')}
                            onChange={(value) => {
                                this.setState({ addedAfter: value });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <MultiSelect
                            values={this.state.colors}
                            globalState={this.props.globalState}
                            label={translate('Color')}
                            elements={CardColor.SortedColors.filter((c) => c.id > 0).map((c) => {
                                return {
                                    label: c.name,
                                    image: '/images/symbols/' + c.key + '.png',
                                    id: c.id
                                };
                            })}
                            onChange={(values) => {
                                this.setState({ colors: values });
                                if (values.indexOf(8) === -1) {
                                    this.setState({ multicolors: [] });
                                }
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <MultiSelect
                            values={this.state.multicolors}
                            globalState={this.props.globalState}
                            label={translate('MultiColor')}
                            elements={CardColor.SortedColors.filter((c) => c.id > 0 && c.id < 6).map((c) => {
                                return {
                                    label: c.name,
                                    image: '/images/symbols/' + c.key + '.png',
                                    id: c.code
                                };
                            })}
                            onChange={(values) => {
                                if (values.length > 0) {
                                    const colors = this.state.colors;

                                    if (colors.indexOf(8) === -1) {
                                        colors.push(8);
                                    }

                                    this.setState({ multicolors: values, colors: colors });
                                } else {
                                    this.setState({ multicolors: values });
                                }
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <MultiSelect
                            values={this.state.rarities}
                            globalState={this.props.globalState}
                            label={translate('Rarity')}
                            elements={CardRarity.Rarities.filter((r) => r.id > 0).map((r) => {
                                return {
                                    label: r.name,
                                    image: '/images/symbols/' + r.key + '.png',
                                    id: r.id
                                };
                            })}
                            onChange={(values) => {
                                this.setState({ rarities: values });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <MultiSelect
                            values={this.state.manaSources}
                            globalState={this.props.globalState}
                            label={translate('ManaSource')}
                            elements={CardColor.ManaSources.map((c) => {
                                return {
                                    label: c.name,
                                    image: '/images/symbols/' + c.key + '.png',
                                    id: c.code
                                };
                            })}
                            onChange={(values) => {
                                this.setState({ manaSources: values });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <MultiSelect
                            values={this.state.colorIdentities}
                            globalState={this.props.globalState}
                            label={translate('ColorIdentity')}
                            elements={CardColor.ColorIdentities.map((c) => {
                                return {
                                    label: c.name,
                                    image: '/images/symbols/' + c.key + '.png',
                                    id: c.code
                                };
                            })}
                            onChange={(values) => {
                                this.setState({ colorIdentities: values });
                            }}
                        />
                    </div>
                    <div className="search-element">
                        <div className="options-label">{translate('Options')}</div>
                        <LabelledCheckbox
                            label={translate('MergeReprintsForQuantity')}
                            globalState={this.props.globalState}
                            value={this.state.mergeReprintsForQuantity}
                            onChange={(value) => {
                                this.setState({ mergeReprintsForQuantity: value });
                            }}
                        ></LabelledCheckbox>
                        <LabelledCheckbox
                            label={translate('HideReprints')}
                            globalState={this.props.globalState}
                            value={this.state.hideReprints}
                            onChange={(value) => {
                                this.setState({ hideReprints: value });
                            }}
                        ></LabelledCheckbox>
                        <LabelledCheckbox
                            label={translate('MergeReprints')}
                            globalState={this.props.globalState}
                            value={this.state.mergeReprints}
                            onChange={(value) => {
                                this.setState({ mergeReprints: value });
                            }}
                        ></LabelledCheckbox>
                    </div>
                </div>
            </div>
        );
    }
}
